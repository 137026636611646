import React, { Component } from 'react';
import './day-button.css';

class DayButton extends Component {
  render() {
    return (
      <div
        className={'dayButton ' + (this.props.isSelected ? 'dayButtonActive' : 'dayButtonNotActive')}
        onClick={this.props.onClick}
      >
        <span className='dayButton-day'>{this.props.day}</span>
      </div>
    );
  }
}

export default DayButton;
