import React, { Component } from "react";
import Collapse from "../../collapse/collapse";
import Checkbox from "./checkbox";

class CheckboxGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  toggleGroup(kvps) {
    let kvp = kvps[0];
    let childVals = this.props.children.map((child) => ({
      key: child.props.id,
      val: kvp.val,
    }));

    this.props.onToggle(childVals);
  }

  toggleCollapse() {
    this.setState((state) => ({
      collapsed: !state.collapsed,
    }));
  }

  render() {
    let children = this.props.children;
    let empty = children.length === 0;
    let anyChecked =
      children.some((child) => child.props.val === true) ||
      children.length === 0;
    let anyUnchecked = children.some((child) => child.props.val === false);
    let val = anyChecked && anyUnchecked ? "mixed" : anyChecked;

    return (
      <div className="checkboxGroupContainer">
        <Collapse
          open={!this.state.collapsed}
          onClick={this.toggleCollapse.bind(this)}
          empty={empty}
          label={
            <Checkbox
              id={this.props.title}
              label={this.props.title}
              onToggle={this.toggleGroup.bind(this)}
              onClick={this.toggleCollapse.bind(this)}
              val={!empty && val}
            />
          }
        >
          {children}
        </Collapse>
      </div>
    );
  }
}

export default CheckboxGroup;
