import React from "react";
import BaseComponent from "../base-component";
import "./pagination.css";

const DOTS = '…';

class Pagination extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  range(start, end) {
    let length = end - start + 1;
    return Array.from({length}, (_, index) => {
      return index + start;
    });
  }

  getPages() {
    let range = (iMin, iMax) => [...Array(iMax-iMin+1)].map((_, i) => iMin + i);

    let firstPage = 1;
    let lastPage = this.props.pageCount;
    let currentPage = this.props.currentPage;
    let siblingCount = this.props.siblingCount || 2;

    let leftSiblingRemainder = siblingCount + firstPage - currentPage;
    let rightSiblingRemainder = currentPage + siblingCount - lastPage;

    var leftSiblingCount = siblingCount;
    leftSiblingCount -= Math.max(leftSiblingRemainder, 0);
    leftSiblingCount += Math.max(rightSiblingRemainder, 0);

    var rightSiblingCount = siblingCount;
    rightSiblingCount -= Math.max(rightSiblingRemainder, 0);
    rightSiblingCount += Math.max(leftSiblingRemainder, 0);

    let minPage = Math.max(currentPage - leftSiblingCount, firstPage);
    let maxPage = Math.min(currentPage + rightSiblingCount, lastPage);

    var pages = [];

    if(firstPage < minPage) pages.push(firstPage);
    if(firstPage < minPage - 1) pages.push(DOTS);

    pages.push(...range(minPage, maxPage));
    
    if(lastPage > maxPage + 1) pages.push(DOTS); 
    if(lastPage > maxPage) pages.push(lastPage);

    return pages;
  }

  utilityButton(props) {
    let disabled = props.targetPage < 1 
      || props.targetPage > props.pageCount;

    return (
      <button
        disabled={disabled}
        onClick={() => props.onPageChange(props.targetPage)}
        className={`${props.className} ${disabled && 'disabled'}`}
      >
        {props.label}
      </button>);
  }

  pageButton(props) {
    if(props.targetPage === DOTS) {
      return <div className="paginationItem-dots">{DOTS}</div>
    }

    let isActive = props.currentPage === props.targetPage;

    let onClick = () => {
      var targetPage = props.targetPage;

      if(isActive) {
        targetPage = window.prompt('Welke pagina wil je zien?');
        targetPage = parseInt(targetPage) || 0;
        targetPage = Math.max(targetPage, 1);
        targetPage = Math.min(targetPage, props.pageCount);
      }
      
      props.onPageChange(targetPage);
    };

    return (
      <button
        onClick={onClick}
        className={`paginationItem ${isActive && 'active'}`}
      >
          <span>{props.targetPage}</span>
      </button>
    )
  }

  render() {
    let {currentPage, pageSize, onPageChange} = this.props;
    if(!currentPage) return null;
    const pagination = this.getPages();

    let setPageSize = () => {
      pageSize = window.prompt('Hoeveel items wil je per pagina zien? (10-100)');
      pageSize = parseInt(pageSize) || 0;
      pageSize = Math.max(pageSize, 10);
      pageSize = Math.min(pageSize, 100);

      onPageChange(currentPage, pageSize);
    };
    
    return (
      <>
        <div className="pagination">
          <div className="pagination-buttonContainer">
            <this.utilityButton {...this.props} targetPage={this.props.currentPage-1} className="prev" label="vorige"/>
          </div>

          {pagination.map(targetPage => <this.pageButton {...this.props} key={targetPage} targetPage={targetPage}/>)}

          <div className="pagination-buttonContainer">
            <this.utilityButton {...this.props} targetPage={this.props.currentPage+1} className="next" label="volgende"/>
          </div>
        </div>
        <div className="pageSizeContainer">Laat <span className="pageSize" onClick={setPageSize}>{pageSize}</span> items per pagina zien.</div>
      </>
    );
  }
}

export default Pagination;
