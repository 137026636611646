import React, { Component } from 'react';

class RadioInput extends Component {
  toggleRadio(val) {
    this.props.toggleRadio(val);
  }

  render() {
    return (
      <input
        className="radioInput"
        type='radio'
        checked={this.props.isChecked}
        onChange={this.toggleRadio.bind(this)}
      />
    );
  }
}

export default RadioInput;
