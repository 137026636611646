import React from 'react';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import FilterbarRange from "../../app/filterbar/filtebar-range";
import FilterBar from '../../app/filterbar/filterbar';
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";

class ReviewFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      date: {},
      groupedTrainers: [],
    };
  }

  componentDidMount() {
    this.getGroupedTrainers()
      .then(() => {
        this.mapInitialRangeFilter('date');
        this.mapInitialGroupedCheckboxFilter('trainers', 'groupedTrainers');
      });
  }

  render() {
    return (
      <div className='reviewFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup
            title="Gym's en trainers"
            optionGroups={this.state.groupedTrainers}
            onUpdate={this.updateGroupedCheckboxFilter.bind(this, 'groupedTrainers', 'trainers')}
          />
           <FilterbarRange 
            date={true}
            title="Periode"
            className="dateFilter"
            resetFilter={this.resetFilter.bind(this, 'date')}
            values={this.state.date}
            onChange={this.onInputChange.bind(this, 'date')}
            onCommit={this.commitFilter.bind(this, 'date')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default ReviewFilter;
