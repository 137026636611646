import React, { Component } from 'react';
import './label-input.css';

class InputLabel extends Component {
  render() {
    return <label className='inputLabel'>{this.props.content}</label>;
  }
}

export default InputLabel;
