import React, { Component } from 'react';
import MenuItemHash from '../sidebar/edit-sidebar/menu-item-hash';
import SliderPopupCard from './slider-popup-card';
import './slider-popup.css';

class SliderPopup extends Component {
  renderSideBarItems() {
    let items = this.props.items;
    return items.map(item => (
      <div className='sliderPopup-sideBarLink' key={item.target}>
        <MenuItemHash target={item.target} icon={item.icon}>
          {item.title}
        </MenuItemHash>
      </div>
    ));
  }

  renderCards() {
    let cards = this.props.items;
    return cards.map(card => (
      <SliderPopupCard id={card.target} key={card.target}>
        <div className='sliderPopup-cardHeaderContainer'>
          <h1 className='sliderPopup-cardTitle'>{card.title}</h1>
          <div className='sliderPopup-cardHorizontalLine'></div>
        </div>
        {card.content}
      </SliderPopupCard>
    ));
  }
  render() {
    return (
      <div className='sliderPopup'>
        <button
          className='sliderPopup-backgroundContainer'
          onClick={this.props.close}
        ></button>
        <div className='sliderPopup-container'>
          <div className='sliderPopup-header'>
            <div className='sliderPopup-headerLeft'>{this.props.title}</div>
            <div className='sliderPopup-headerRight'>
              <button
                className='sliderPopup-button button-outline'
                onClick={this.props.close}
              >
                {this.props.closeButton}
              </button>
              <button className='sliderPopup-button' onClick={this.props.save}>
                {this.props.saveButton}
              </button>
            </div>
          </div>

          <div className='sliderPopup-mainContainer'>
            <div className='sliderPopup-sidebar'>
              {this.renderSideBarItems()}
            </div>
            <div className='sliderPopup-main'>{this.renderCards()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SliderPopup;
