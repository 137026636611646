import React, { Component } from 'react'
import MFLogo from "../app/logo/logo";
import './authentication-page.css'

 class AuthenticationPage extends Component {
    render() {
        return (
            <div className='authenticationPage'>
                <MFLogo fill={'var(--color-white)'} width='69' heigt='72' />
                {this.props.children}
            </div>
        )
    }
}

export default AuthenticationPage;