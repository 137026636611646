import React, { Component } from 'react';

class MFLogo extends Component {
  render() {
    return (
      <svg
        id='MFLogo'
        width={this.props.width}
        height={this.props.height}
        viewBox='0 0 89 92'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M88 48.2457V71.0176C88 74.899 87.4617 78.1882 86.3724 80.8724C85.2957 83.5566 83.6932 85.7116 81.5648 87.3373C79.4365 88.9755 76.8324 90.1475 73.74 90.891C70.6476 91.6346 67.1045 92 63.0856 92C58.9165 92 55.2232 91.4203 52.0181 90.2483C48.8131 89.0889 46.3216 87.4129 44.5438 85.2327C42.766 87.4129 40.2621 89.0763 37.0194 90.2483C33.7768 91.4077 30.0334 92 25.7767 92C21.8329 92 18.3274 91.6346 15.235 90.891C12.1426 90.1475 9.53851 88.9629 7.41015 87.3373C5.28178 85.699 3.67925 83.544 2.60255 80.8724C1.52585 78.1882 0.974976 74.899 0.974976 71.0176V48.2457H16.0363V68.9131C16.0363 70.7026 16.1364 72.2526 16.3242 73.5758C16.512 74.899 16.9627 76.008 17.6513 76.9028C18.3399 77.7975 19.4041 78.4528 20.8314 78.8813C22.2586 79.3098 24.1741 79.524 26.5654 79.524C28.8065 79.524 30.5843 79.3224 31.8988 78.9443C33.2134 78.5536 34.2526 77.9739 35.0288 77.1926C35.805 76.4113 36.3058 75.4409 36.5312 74.2815C36.769 73.1096 36.8817 71.7485 36.8817 70.1985V48.2457H51.943V68.9131C51.943 70.7026 52.0432 72.2526 52.231 73.5758C52.4188 74.899 52.882 76.008 53.6207 76.9028C54.3593 77.7975 55.411 78.4528 56.8007 78.8813C58.1904 79.3098 60.0809 79.524 62.4722 79.524C64.8634 79.524 66.7539 79.3098 68.1436 78.8813C69.5333 78.4528 70.5725 77.7975 71.2736 76.9028C71.9622 76.008 72.4129 74.899 72.6007 73.5758C72.7885 72.2526 72.8886 70.7026 72.8886 68.9131V48.2457H88Z'
          fill={this.props.fill}
        />
        <path
          d='M1 42.7513V21.4538C1 17.5724 1.53835 14.2833 2.62757 11.599C3.70427 8.9274 5.30681 6.77245 7.42265 5.13419C9.55101 3.49592 12.1551 2.32393 15.2475 1.58041C18.3399 0.836895 21.883 0.471436 25.9019 0.471436C30.0709 0.471436 33.7643 1.05113 36.9693 2.22312C40.1744 3.38251 42.6659 5.05858 44.4437 7.23873C46.2215 5.05858 48.7254 3.39511 51.9681 2.22312C55.2107 1.05113 58.9666 0.471436 63.2108 0.471436C67.1546 0.471436 70.6601 0.836895 73.7525 1.58041C76.8449 2.32393 79.449 3.50853 81.5773 5.13419C83.7057 6.77245 85.3083 8.9274 86.3849 11.599C87.4616 14.2833 88.0125 17.5724 88.0125 21.4538V42.7387H72.9387V23.5584C72.9387 21.7689 72.8385 20.2188 72.6507 18.8956C72.4629 17.5724 72.0122 16.4634 71.3236 15.5687C70.6351 14.6739 69.5709 14.0186 68.1436 13.5902C66.7164 13.1617 64.8008 12.9474 62.4096 12.9474C60.1685 12.9474 58.3907 13.1491 57.0761 13.5271C55.7615 13.9178 54.7224 14.4975 53.9462 15.2788C53.17 16.0602 52.5565 17.0305 52.3311 18.1899C52.0933 19.3619 51.9806 20.7229 51.9806 22.273V42.7387H36.9193V23.5458C36.9193 21.7563 36.9318 20.2062 36.744 18.883C36.5562 17.5598 35.9678 16.4508 35.2416 15.5561C34.503 14.6613 33.564 14.006 32.1743 13.5776C30.7846 13.1491 28.8941 12.9348 26.5028 12.9348C24.1115 12.9348 22.221 13.1491 20.8313 13.5776C19.4416 14.006 18.4025 14.6613 17.7014 15.5561C17.0128 16.4508 16.5621 17.5598 16.3743 18.883C16.1865 20.2062 16.0863 21.7563 16.0863 23.5458V42.7387H1V42.7513Z'
          fill={this.props.fill}
        />
      </svg>
    );
  }
}

export default MFLogo;
