import React from "react";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import CalendarFilter from "./calendar-filter";
import Sidebar from "../../app/sidebar/sidebar";
import AppContext from "../../app/app-context";
import Topbar from "../../app/topbar/topbar";
import AddButton from "../../app/add-button/add-button";
import DayCalendar from "./day-calendar/day-calendar";
import CalendarControls from "./calendar-controls/calendar-controls";
import CalendarPopup from './calendar-popup/calendar-popup';
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";
import SearchPopup from "./search-popup/search-popup";
import "./calendar-page.css";

class CalendarPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      currentDate: moment(),
      gyms: [],
      trainers: [],
      selectedTrainers: [],
      products: [],
      appointments: [],
      selectedAppointment: {},
      newSlotEvent: {},
      agendaPopupShown: false,
      agendaSearchPopupShown: false,
      filters: {
        trainers: context.user && [context.user.trainerId],
        products: null,
      },
    };
  }

  componentDidMount() {
    Promise.all([
      this.getGyms(),
      this.getTrainers(),
      this.getProducts(),
    ])
      .then(() => {
        let selfTrainerId = this.context.user.trainerId;
        let selfTrainer = this.state.trainers.find(t => t.id === selfTrainerId);

        return this.setStateAsync((state) => ({
          selectedTrainers: [selfTrainer],
        }));
      })
      .then(() => {
        return this.getTodaysAppointments();
      });
  }

  toggleAgendaPopup() {
    this.setState((state) => ({
      agendaPopupShown: !state.agendaPopupShown,
      selectedAppointment: null,
      newSlotEvent: null,
    }));
  }

  toggleAgendaSearchPopup() {
    this.setState((state) => ({
      agendaSearchPopupShown: !state.agendaSearchPopupShown,
    }));
  }

  toggleSlotEvent(event) {
    let trainer = this.state.trainers
      .filter((t) => t.id === event.resourceId)
      .map((t) => ({
        id: t.id,
        gym: t.gym.id,
      }));

    let slotEvent = {
      ...event,
      trainerId: trainer[0].id,
      gymId: trainer[0].gym,
    };

    this.setState((state) => ({
      agendaPopupShown: !state.agendaPopupShown,
      selectedAppointment: null,
      newSlotEvent: slotEvent,
    }));
  }

  toggleEditAgendaPopup(event) {
    this.setState((state) => ({
      agendaPopupShown: !state.agendaPopupShown,
      selectedAppointment: event,
      newSlotEvent: null,
    }));
  }

  getTrainers() {
    return new ApiClient(this.context)
      .call("GET", "/v1/Trainer")
      .then((response) => {
        let data = response.json;

        return this.setStateAsync((state) => ({
          trainers: data.results,
        }));
      });
  }

  getProducts() {
    return new ApiClient(this.context)
      .call("GET", "/v1/Product", null, false, {}, false, {
        'Filters': "Active==true",
      })
      .then((response) => {
        let data = response.json;

        return this.setStateAsync((state) => ({
          products: data.results,
        }));
      });
  }

  getGyms() {
    return new ApiClient(this.context)
      .call("GET", "/v1/Gym/")
      .then((response) => {
        let data = response.json;

        return this.setStateAsync((state) => ({
          gyms: data.results,
        }));
      });
  }

  getTodaysAppointments() {
    var filterQuery = this.buildFilterQuery({
      products: {
        filterKey: "Product",
        type: "select",
      },
      trainers: {
        filterKey: "Trainer",
        type: "select",
      },
    });
  
    new ApiClient(this.context)
      .call("GET", "/v1/Appointment", null, false, {}, false, {
        Start: moment(this.state.currentDate).format("YYYY-MM-DD"),
        End: moment(this.state.currentDate).add(1, "days").format("YYYY-MM-DD"),
        Filters: `Active==true,${filterQuery}`,
      })
      .then((response) => {
        let data = response.json;
        this.setState((state) => ({
          appointments: data,
          currentDate: state.currentDate,
        }));
      });
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => {
      var selectedTrainers = state.selectedTrainers;

      if (key === 'trainers') {
        selectedTrainers = state.trainers.filter((trainer) => {
          return value.includes(trainer.id);
        });
      }

      return {
        filters: {
          ...state.filters,
          [key]: value,
        },
        selectedTrainers: selectedTrainers,
      };
    }).then(() => {
      this.getTodaysAppointments();
    });
  }

  setDate(date) {
    this.setStateAsync((state) => ({
      currentDate: date,
    })).then(() => {
      this.getTodaysAppointments();
    });
  }

  render() {
    return (
      <div className="agendaPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="agendaPage-container">
          <div className="topbar">
            <Topbar
              title="Agenda"
              button={
                <AddButton
                  actions={[
                    {
                      label: "Add",
                      handler: this.toggleAgendaPopup.bind(this),
                    },
                  ]}
                />
              }
            >
              <div className="agendaControls">
                <CalendarControls
                  currentDate={this.state.currentDate}
                  setDate={this.setDate.bind(this)}
                  toggleSearchModal={this.toggleAgendaSearchPopup.bind(this)}
                />
              </div>
            </Topbar>
          </div>
          <div className="agendaPage-mainContent">
            {this.state.agendaSearchPopupShown && (
              <SearchPopup
                onDismiss={this.toggleAgendaSearchPopup.bind(this)}
                toggleEditAgendaPopup={this.toggleEditAgendaPopup.bind(this)}
              />
            )}
            {this.state.agendaPopupShown && (
              <CalendarPopup
                newSlotEvent={this.state.newSlotEvent}
                appointment={this.state.selectedAppointment}
                products={this.state.products}
                trainers={this.state.trainers}
                gyms={this.state.gyms}
                refreshList={this.getTodaysAppointments.bind(this)}
                onDismiss={this.toggleAgendaPopup.bind(this)}
              />
            )}

            <div className="filterbar">
              <CalendarFilter
                initialFilters={this.state.filters}
                products={this.state.products}
                gyms={this.state.gyms}
                trainers={this.state.trainers}
                updateFilter={this.updateFilter.bind(this)}
              />
            </div>
            <DayCalendar
              selectedTrainers={this.state.selectedTrainers}
              calendarDate={this.state.currentDate}
              calendarResources={this.state.appointments}
              toggleSlotEvent={this.toggleSlotEvent.bind(this)}
              toggleEditAgendaPopup={this.toggleEditAgendaPopup.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CalendarPage;
