import React, { Fragment } from "react";
import { Redirect } from "react-router";
import moment from "moment";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Sidebar from "../../app/sidebar/sidebar";
import Topbar from "../../app/topbar/topbar";
import NotificationFilter from "./notification-filter";
import NotificationItem from "./notification-item";
import Loader from "../../app/loader/loader";
import "./notification-page.css";

class NotificationPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      notifications: null,
      sortQuery: null,
      filterQuery: null,
      filters: {},
      userRole: context.user.role
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    if(this.context.user && this.state.userRole === 'Trainer') {
      return this.setState({
        notifications: [],
      }); 
    }

    this.setState({
      notifications: null,
    });

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      statuses: {
        filterKey: "Processed",
        type: "select",
        noneFilter: "Type==-1",
      },
      types: {
        filterKey: "Type",
        type: "select",
      },
      gyms: {
        filterKey: "Gym",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Notifications", null, false, {}, false, {
        Sorts: sortQuery,
        Filters: filterQuery,
      })
      .then((response) => {
        if (!response.ok) return;

        let data = response.json;

        return this.setStateAsync((state) => ({
          notifications: data.results,
        }));
      });
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getNotifications.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getNotifications.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getNotifications();
    });
  }

  updateNotification(id) {
    new ApiClient(this.context)
      .call("PATCH", `/${id}/Process/`)
      .then((result) => {
        if (result.ok) {
          this.context.addNotification("success", "Notificatie is verwerkt");
          this.getNotifications();
        } else {
          throw result;
        }
      });
  }

  renderNotifications() {
    return (
      this.state.notifications &&
        this.state.notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            gym={notification.gym.name}
            trainer={
              notification.trainer.firstName + " " + notification.trainer.lastName
            }
            client={
              notification.client.firstName + " " + notification.client.lastName
            }
            type={notification.type}
            oldGoal={notification.oldGoal && notification.oldGoal.weeklyTarget}
            goal={notification.goal && notification.goal.weeklyTarget}
            date={moment(notification.date).format("LL")}
            processed={notification.processed}
            updateNotification={this.updateNotification.bind(
              this,
              notification.id
            )}
          />
        ))
    );
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    return (
      <div className="notificationPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="notificationPage-container">
          <Topbar title="Notificaties" />

          <div className="notificationPage-mainContent">
            {!admin
              ? <div className="notificationPage-trainerRole">Notificaties zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  <NotificationFilter updateFilter={this.updateFilter.bind(this)} />

                  <div className="notificationPage-itemsContainer">
                    <div className="notificationPage-notifications">
                      
                      {this.state.notifications === null && (
                        <div className="notificationPage-loader">
                          <Loader/>
                        </div>)
                      }
                      {this.renderNotifications()}
                    </div>
                  </div>
                </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}
export default NotificationPage;
