import { XSquare } from "react-feather";
import BaseComponent from "../base-component";
import InputWithLabel from "../input/input-with-label";

export default class FilterbarRange extends BaseComponent {
  constructor(props, context) {
    super(props);
    this.state = {
      open: context.mobile ? false : true,
    };
  }
  toggleFilter() {
    this.setState((state) => ({
      open: !state.open,
    }));
  }
  render() {
    let filterActive = Object.keys(this.props.values || {}).length !== 0;

    return (
      <div className="filter-section">
        <div className="filter-sectionContainer">
          <div className={this.props.className}>
            <div className={`${this.props.className}-header`}>
              <h1>{this.props.title}</h1>

              {filterActive ? (
                <span className="resetFilter" onClick={this.props.resetFilter}>
                  <XSquare size={17} />
                </span>
              ) : (
                this.context.mobile && (
                  <button
                    className="filter-filterOpenButton"
                    onClick={this.toggleFilter.bind(this)}
                  >
                    {this.state.open ? "filter sluiten" : "filter openen"}
                  </button>
                )
              )}
            </div>
            {this.state.open && (
              <div className={`${this.props.className}-main`}>
                <InputWithLabel
                  text={this.props.text}
                  date={this.props.date}
                  title="van"
                  name="from"
                  value={this.props.values.from || ""}
                  onInputChange={(...args) =>
                    this.props.onChange("from", ...args)
                  }
                  onBlur={this.props.onCommit}
                />
                <InputWithLabel
                  text={this.props.text}
                  date={this.props.date}
                  title="t/m"
                  name="until"
                  value={this.props.values.until || ""}
                  onInputChange={(...args) =>
                    this.props.onChange("until", ...args)
                  }
                  onBlur={this.props.onCommit}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
