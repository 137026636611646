import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import moment from "moment-timezone";
import AppContext from "./app-context";
import LoginPage from "../authentication/login-page/login-page";
import DashboardPage from "../pages/dashboard/dashboard-page";
import CalendarPage from "../pages/calendar/calendar-page";
import NotificationPage from "../pages/notification/notification-page";
import ClientsPage from "../pages/clients/clients-page";
import TrainersPage from "../pages/trainers/trainers-page";
import InvoicesPage from "../pages/invoices/invoices-page";
import LogoutPage from "../authentication/logout-page/logout-page";
import ClubsPage from "../pages/clubs/clubs-page";
import ProductsPage from "../pages/products/products-page";
import ActivitiesPage from "../pages/activities/activities-page";
import HoursPage from "../pages/hours/hours-page";
import ReviewsPage from "../pages/reviews/reviews-page";
import SettingsPage from "../pages/settings/settings-page";
import BaseComponent from "./base-component";
import CheckImportedClients from "../pages/settings/check-imported-clients/check-imported-clients";
import PasswordRequestPage from "../authentication/password-request-page/password-request-page";
import PasswordResetPage from "../authentication/password-reset-page/password-reset-page";
import NotificationContainer from "./notification/notification-container";
import appSettings from "./app-settings";
import PasswordFirstChangePage from "../authentication/password-first-change-page/password-first-change-page";
import "moment/min/locales";
import "./app.css";

moment.locale("nl");

class App extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    var user = null;

    try {
      user = JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      console.log(error);
      localStorage.setItem("user", null);
    }

    this.state = {
      settings: appSettings,
      loading: false,
      setLoading: this.setLoading.bind(this),
      addNotification: this.addNotification.bind(this),
      user: user,
      setUser: this.setUser.bind(this),
      setTimezone: this.setTimezone.bind(this),
      signIn: this.signIn.bind(this),
      logOut: this.logOut.bind(this),
      mobile: window.innerWidth < 600,
      hamburgerOpen: false,
      toggleHamburgerMenu: this.toggleHamburgerMenu.bind(this),
    };

    this.notificationContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ mobile: window.innerWidth < 600 })
    );
  }

  toggleHamburgerMenu() {
    this.setState((state) => ({
      hamburgerOpen: !state.hamburgerOpen,
    }));
  }
  setLoading(loading) {
    return this.setStateAsync({ loading: loading });
  }

  setTimezone(timezone) {
    moment.tz.setDefault(timezone);
  }

  addNotification(severity, message, time = 3) {
    this.notificationContainer.current.addNotification(severity, message, time);
  }

  signIn(staySignedIn, accessToken, refreshToken) {
    localStorage.setItem("staySignedIn", staySignedIn);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    return Promise.resolve();
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    return this.setStateAsync({ user: user });
  }
  
  logOut() {
    return this.signIn(false, null, null).then(() => this.setUser(null));
  }
  
  render() {
    return (
      <div className="app">
        <AppContext.Provider value={this.state}>
          <Router>
            <div className="app-pageContainer">
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/password-request">
                  <PasswordRequestPage />
                </Route>
                <Route path="/password-reset">
                  <PasswordResetPage />
                </Route>
                <Route path="/password-first-change">
                  <PasswordFirstChangePage />
                </Route>
                <Route path="/dashboard">
                  <DashboardPage />
                </Route>
                <Route path="/agenda">
                  <CalendarPage />
                </Route>
                <Route path="/notification">
                  <NotificationPage />
                </Route>
                <Route path="/clubs">
                  <ClubsPage />
                </Route>
                <Route path="/trainers">
                  <TrainersPage />
                </Route>
                <Route path="/products">
                  <ProductsPage />
                </Route>
                <Route path="/clients">
                  <ClientsPage />
                </Route>
                <Route path="/clients-check">
                  <CheckImportedClients />
                </Route>
                <Route path="/activities">
                  <ActivitiesPage />
                </Route>
                <Route path="/hours">
                  <HoursPage />
                </Route>
                <Route path="/reviews">
                  <ReviewsPage />
                </Route>
                <Route path="/invoices">
                  <InvoicesPage />
                </Route>
                <Route path="/settings">
                  <SettingsPage />
                </Route>
                <Route path="/logout">
                  <LogoutPage />
                </Route>
                <Route path="/">
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </div>
            <NotificationContainer ref={this.notificationContainer} />
          </Router>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
