import React, { Component } from "react";
import { Redirect } from "react-router";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Topbar from "../../app/topbar/topbar";
import Sidebar from "../../app/sidebar/sidebar";
import AddButton from "../../app/add-button/add-button";
import ClubsCard from "./clubs-card/clubs-card";
import ClubsPopup from "./clubs-popup/clubs-popup";
import MoneybirdPopup from "./moneybird-popup/moneybird-popup";
import "./clubs-page.css";

class ClubsPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      gyms: [],
      selectedGym: {},
      clubsPopupShown: false,
      moneybirdPopupShown: false,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    new ApiClient(this.context).call("GET", "/v1/Gym/").then((response) => {
      let gyms = response.json;
      this.setState({
        gyms: gyms.results,
      });
    });
  }

  toggleClubsPopup() {
    this.setState((state) => ({
      clubsPopupShown: !state.clubsPopupShown,
      selectedGym: null,
    }));
  }

  toggleEditClubsPopup(gym) {
    this.setState((state) => ({
      clubsPopupShown: !state.clubsPopupShown,
      selectedGym: gym,
    }));
  }
  
  showMoneybirdPopup(gym) {
    this.setState({
      moneybirdPopupShown: true,
      selectedGym: gym,
    });
  }

  hideMoneybirdPopup() {
    const id = this.state.selectedGym.id;
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/status/gym/" + id)
      .then((response) => {
        if (response.json.isLoggedIn) {
          const getAdministration = new ApiClient(this.context)
            .call("GET", "/v1/MoneyBird/administrations/gym/" + id)
            .then((response) =>
              Array.isArray(response.json)
                ? response.json.filter((a) => a.active).length > 0
                : false
            );
          const getDocumentStyle = new ApiClient(this.context)
            .call("GET", "/v1/MoneyBird/document-styles/gym/" + id)
            .then((response) =>
              Array.isArray(response.json)
                ? response.json.filter((a) => a.active).length > 0
                : false
            );
          const getTaxRate = new ApiClient(this.context)
            .call("GET", "/v1/MoneyBird/tax-rates/gym/" + id)
            .then((response) =>
              Array.isArray(response.json)
                ? response.json.filter((a) => a.active).length > 0
                : false
            );
          const getLedgerAccount = new ApiClient(this.context)
            .call("GET", "/v1/MoneyBird/ledger-accounts/gym/" + id)
            .then((response) =>
              Array.isArray(response.json)
                ? response.json.filter((a) => a.active).length > 0
                : false
            );
          const getWorkflow = new ApiClient(this.context)
            .call("GET", "/v1/MoneyBird/workflows/gym/" + id)
            .then((response) =>
              Array.isArray(response.json)
                ? response.json.filter((a) => a.active).length > 0
                : false
            );
          Promise.all([
            getAdministration,
            getDocumentStyle,
            getTaxRate,
            getLedgerAccount,
            getWorkflow,
          ]).then((values) => {
            if (values.includes(false)) {
              this.context.addNotification(
                "error",
                "Niet alle velden zijn ingevuld"
              );
            } else {
              this.setState({
                moneybirdPopupShown: false,
                selectedGym: null,
              });
            }
          });
        } else {
          this.setState({
            moneybirdPopupShown: false,
            selectedGym: null,
          });
        }
      });
  }

  renderCards() {
    let gyms = this.state.gyms;
    
    return gyms.map((gym) => (
      <ClubsCard
        key={gym.id}
        title={gym.name}
        street={gym.street}
        houseNumber={gym.houseNumber}
        zipCode={gym.zipCode}
        city={gym.city}
        amountTrainers={gym.trainerCount}
        amountClients={gym.clientCount}
        toggleEditClubsPopup={this.toggleEditClubsPopup.bind(this, gym)}
        toggleMoneybirdPopup={this.showMoneybirdPopup.bind(this, gym)}
      />
    ));
  }

  render() {
    const admin = this.context.user && (this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin');

    return (
      <div className="clubsPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="clubsPage-container">
          <div className="topbar">
            <Topbar
              title="Clubs"
              button={ admin && ( 
                <AddButton
                  actions={[
                    { label: "Add", handler: this.toggleClubsPopup.bind(this) },
                  ]}
                />)}
            />
          </div>

          <div className="clubsPage-mainContent">
            {this.state.clubsPopupShown && (
              <ClubsPopup
                gym={this.state.selectedGym}
                onDismiss={this.toggleClubsPopup.bind(this)}
                onRefreshData={this.refreshData.bind(this)}
              />
            )}
            {this.state.moneybirdPopupShown && (
              <MoneybirdPopup
                gym={this.state.selectedGym}
                onDismiss={this.hideMoneybirdPopup.bind(this)}
              />
            )}

            <div className="clubs">
              <div className="clubs-container">
                <div className="clubs-cards">
                  {this.renderCards()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClubsPage;
