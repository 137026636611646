import React from "react";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import BaseComponent from "../../../app/base-component";
import InputWithLabel from "../../../app/input/input-with-label";
import OutlineButton from "../../../app/outline-button/outline-button";
import PopupOverlay from "../../../app/popup/popup-overlay";
import moment from "moment-timezone";
import Loader from "../../../app/loader/loader";
import { InactiveStatusses, Status } from '../status';
import "./search-popup.css";

class SearchPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      clientName: "",
      startDate: moment().format("YYYY-MM-DD"),
      appointments: null,
      noAppointments: false,
      isLoading: false,
      monthCount: 1,
    };
  }

  onInputChange(key, val) {
    this.setState({
      [key]: val,
    });
  }

  onCommit() {
    this.getAppointments();
  }

  componentDidMount() {
    this.getAppointments();
  }

  search() {
    if (this.state.clientName === "") {
      this.context.addNotification("error", "Vul een zoekterm in a.u.b.");
    } else if (this.state.clientName.length < 3) {
      this.context.addNotification(
        "error",
        "Je zoekterm moet uit minimaal 3 tekens bestaan"
      );
    } else {
      this.setStateAsync({
        monthCount: 1,
      }).then(() => this.getAppointments());
    }
  }

  getDateRange() {
    let startDate = this.state.startDate
      ? moment(this.state.startDate)
      : moment();
    let endDate = moment(startDate).add(this.state.monthCount, "months");

    return { startDate, endDate };
  }

  getAppointments() {
    let clientName = this.state.clientName
      ? `ClientName==${this.state.clientName}`
      : "";

    let { startDate, endDate } = this.getDateRange();

    this.setStateAsync({
      appointments: null,
      noAppointments: false,
      isLoading: true,
    }).then(() => {
      new ApiClient(this.context)
        .call("GET", "/v1/Appointment", null, false, {}, false, {
          Start: startDate.toISOString(),
          End: endDate.toISOString(),
          Sorts: "Start",
          Filters: `Active==true,${clientName}`,
        })
        .then((response) => {
          if (response.json.length === 0) {
            this.setState({
              noAppointments: true,
              isLoading: false,
            });
          } else {
            this.setState({
              appointments: response.json,
              isLoading: false,
            });
          }
        });
    });
  }

  fetchMoreAppointments() {
    this.setStateAsync((state) => ({
      monthCount: state.monthCount + 1,
    })).then(() => {
      this.getAppointments();
    });
  }

  render() {
    let { endDate } = this.getDateRange();

    return (
      <div className="search-popup">
        <PopupOverlay
          actions={[]}
          title="Afspraken doorzoeken op klanten"
          onDismiss={this.props.onDismiss}
        >
          <div className="search-input-container">
            <div className="search-inputContainer">
              <InputWithLabel
                text={true}
                title="Vul naam van klant in"
                name="clientName"
                value={this.state.clientName}
                onInputChange={this.onInputChange.bind(this, "clientName")}
              />
            </div>
            <OutlineButton
              actions={[{ label: "zoeken", handler: this.search.bind(this) }]}
            />
          </div>
          <div className="search-inputContainer">
            <InputWithLabel
              date={true}
              title="Zoeken vanaf"
              value={this.state.startDate}
              name="startDate"
              onInputChange={this.onInputChange.bind(this, "startDate")}
              onBlur={this.onCommit.bind(this)}
            />
          </div>

          {this.state.noAppointments && (
            <div className="noAppointments-container">
              <p>
                Geen afspraken gevonden tot en met{" "}
                {endDate.format("dddd DD MMMM")}
              </p>
              <OutlineButton
                actions={[
                  {
                    label: "Laad meer",
                    handler: this.fetchMoreAppointments.bind(this),
                  },
                ]}
              />
            </div>
          )}
          {this.state.isLoading && (
            <div className="loader-container">
              <Loader />
            </div>
          )}
          {this.state.appointments && (
            <div className="appointments-container">
              {this.state.appointments.map(appointment => {

                let isCancelled = InactiveStatusses.includes(appointment.status);
                let isCancelledFree = appointment.status === Status.CancelledFree.name;
                let isDeleted = appointment.status === Status.Deleted.name;

                return (
                  <button
                    key={appointment.id}
                    className={`appointment ${isCancelled ? 'cancelled-appointment' : ''} ${isDeleted ? 'deleted-appointment' : ''}`}
                    onClick={() =>
                      this.props.toggleEditAgendaPopup(appointment)
                    }
                  >
                    <h1
                      className={`${isCancelled ? 'line-through': ''} ${isCancelledFree ? 'italic': ''}`}
                    >
                      {moment(appointment.start).format("dddd DD MMMM")}:{" "}
                      {moment(appointment.start).format("HH:mm")} -{" "}
                      {moment(appointment.end).format("HH:mm")}
                    </h1>
                    <div
                      className={`appointment-info-container ${isCancelledFree ? 'italic' : ''}`}
                    >
                      <div>
                        <p>
                          <b>Trainer: </b>
                          {appointment.trainer.name}
                        </p>
                        <p>
                          <b>{appointment.product.name}</b>
                        </p>
                        <p>
                          <b>{appointment.gym.name}</b>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Klanten:</b>
                        </p>
                        {appointment.clients.map(client => {
                          var style = '';
                          if(client.status === Status.Cancelled.name) {
                            style = 'line-through';
                          } else if (client.status === Status.CancelledFree.name) {
                            style = 'line-through italic'; 
                          } else if(client.status === Status.Deleted.name) {
                            style = 'line-through opacity';
                          } else if(client.status === Status.Rescheduled.name) {
                            style = 'italic';
                          }

                          return <p key={client.id} className={style}>{client.name}</p>
                        })}
                      </div>
                    </div>
                  </button>
                );
              })}
              <p>
                Afspraken geladen tot en met{" "}
                {endDate.add(-1, "days").format("dddd DD MMMM")}
              </p>
              <OutlineButton
                actions={[
                  {
                    label: "Laad meer",
                    handler: this.fetchMoreAppointments.bind(this),
                  },
                ]}
              />
            </div>
          )}
        </PopupOverlay>
      </div>
    );
  }
}

export default SearchPopup;
