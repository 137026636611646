import React from 'react';
import FilterBar from '../../app/filterbar/filterbar';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import FilterbarRange from "../../app/filterbar/filtebar-range";
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import moment from "moment";

class DashboardFilter extends BaseFilterbar {
  constructor(props) {
    super(props);
    
    this.state = {
      date: {},
      groupedTrainers: [],
    };

  }

  componentDidMount() {
    this.getGroupedTrainers()
      .then(() => {
        this.mapInitialRangeFilter('date');
      });
  }

  resetDateFilter() {
    this.setStateAsync(state => ({
      date: {
        from: moment().startOf("year").format('YYYY-MM-DD'),
        until: moment().endOf("month").format('YYYY-MM-DD')
      }
    })).then(() => this.commitFilter('date'));
  }

  render() {
    return (
      <div className="dashboardFilter">
        <FilterBar title='Dashboard'>
          <FilterbarRange 
            date={true}
            title="Periode"
            className="dateFilter"
            resetFilter={this.resetDateFilter.bind(this)}
            values={this.state.date}
            onChange={this.onInputChange.bind(this, 'date')}
            onCommit={this.commitFilter.bind(this, 'date')}
          />
          <FilterbarCheckboxGroup
            title="Gym's en trainers"
            optionGroups={this.state.groupedTrainers}
            onUpdate={this.updateGroupedCheckboxFilter.bind(this, 'groupedTrainers', 'trainers')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default DashboardFilter;
