import React, { Component } from "react";
import "../client-weightlogs-popup.css";
import EditButton from "../../../../app/edit-button/edit-button";
import AddButton from "../../../../app/add-button/add-button";
import moment from "moment-timezone";
import ApiClient from "../../../../api-client/api-client";
import AppContext from "../../../../app/app-context";

class Clientgoal extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    let client = this.props.client.clientGoal;
    this.state = {
      editable: false,
      startWeight: client ? client.startingWeight : this.props.client.weight,
      weeklyTarget: client ? client.weeklyTarget : "",
      targetWeight: client ? client.targetWeight : "",
    };
  }

  toggleEditable() {
    this.setState((state) => ({
      editable: !state.editable,
    }));
  }

  setProp(key, value) {
    this.setState({
      [key]: value,
    });
  }

  cancelClientGoal() {
    const client = this.props.client.clientGoal;
    this.setState({
      editable: false,
      startWeight: client ? client.startingWeight : this.props.client.weight,
      weeklyTarget: client ? client.weeklyTarget : "",
      targetWeight: client ? client.targetWeight : "",
    });
  }

  saveClientGoal() {
    const { refreshList, getClientFromAPI, client } = this.props;
    const { startWeight, weeklyTarget, targetWeight } = this.state;
    if (!weeklyTarget || !targetWeight) {
      this.context.addNotification(
        "error",
        "Vul alle gegevens in voor het afvaltraject"
      );
    } else {
      const gymID = client.gym.id;
      const trainerID = client.trainer.id;
      const date =
        client.clientGoal && client.clientGoal.startTime
          ? moment(client.clientGoal.startTime).format()
          : moment().format();
      let clientGoal = {
        gymId: gymID,
        trainerId: trainerID,
        startTime: date,
        weeklyTarget:
          typeof number === "string"
            ? weeklyTarget.toLocaleString("en-US").replace(/,/g, ".")
            : weeklyTarget,
        startingWeight:
          typeof number === "string"
            ? startWeight.toLocaleString("en-US").replace(/,/g, ".")
            : startWeight,
        targetWeight:
          typeof number === "string"
            ? targetWeight.toLocaleString("en-US").replace(/,/g, ".")
            : targetWeight,
      };

      let weightMetric = {
        gymId: gymID,
        trainerId: trainerID,
        date: date,
        weight:
          typeof number === "string"
            ? startWeight.toLocaleString("en-US").replace(/,/g, ".")
            : startWeight,
      };

      new ApiClient(this.context)
        .call("PUT", "/V1/Client/" + client.id + "/ClientGoal", clientGoal)
        .then((result) => {
          if (result.ok) {
            new ApiClient(this.context)
              .call(
                "PUT",
                "/V1/Client/" + client.id + "/WeightMetric",
                weightMetric
              )
              .then((response) => {
                if (response.ok) {
                  getClientFromAPI();
                  refreshList();
                  this.toggleEditable();
                }
              });
          }
        });
    }
  }
  render() {
    const { startWeight, weeklyTarget, targetWeight } = this.state;
    const data = [
      { key: "startWeight", label: "Start gewicht", value: startWeight },
      { key: "weeklyTarget", label: "Afval percentage", value: weeklyTarget },
      { key: "targetWeight", label: "Doel gewicht", value: targetWeight },
    ];
    return (
      <div className="clientgoalWrapper">
        <div className="clientgoalHeader">
          <div className="title">Afvaltraject</div>
          {!this.props.client.clientGoal ||
            (!this.state.editable && (
              <EditButton onClick={this.toggleEditable.bind(this)} />
            ))}
        </div>
        {this.props.client.clientGoal || this.state.editable ? (
          <div className="clientgoalContainer">
            {data.map((d) => (
              <div key={d.key} className="clientgoalItem">
                {this.state.editable ? (
                  <input
                    value={d.value}
                    type="number"
                    step="0.1"
                    min="0"
                    onChange={(e) => this.setProp(d.key, e.target.value)}
                  ></input>
                ) : (
                  <div className="disabledInput">
                    {d.value.toLocaleString()}
                  </div>
                )}
                <p>{d.label}</p>
              </div>
            ))}
          </div>
        ) : (
          <AddButton
            className="addClientGoalButton"
            actions={[
              {
                label: "Add",
                handler: this.toggleEditable.bind(this),
              },
            ]}
          />
        )}
        {this.state.editable && (
          <div className="buttonContainer">
            <button
              onClick={() => this.cancelClientGoal()}
              className="cancelButton"
            >
              Annuleren
            </button>
            <button
              onClick={() => this.saveClientGoal()}
              className="saveButton"
            >
              Opslaan
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Clientgoal;
