import React, { Fragment } from "react";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import BaseComponent from "../../app/base-component";
import Topbar from "../../app/topbar/topbar";
import Sidebar from "../../app/sidebar/sidebar";
import Table from "../../app/table/table";
import HourFilter from "./hours-filter";
import "./hours-page.css";

class HoursPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      hours: null,
      total: null,
      currentPage: 1,
      pageCount: '',
      pageSize: '',
      totalCount: '',
      sortQuery: null,
      filterQuery: null,
      filters: {},
      userRole: context.user && context.user.role,
      pagination: {},
    };
  }

  componentDidMount() {
    this.getHours();
  }

  getHours(page = 1, pageSize = null) {
    if(this.context.user && this.state.userRole === 'Trainer') {
      return this.setState({
        hours: [],
      }); 
    }

    pageSize = pageSize || this.state.pagination.pageSize || 25;

    this.setState({
      hours: null,
    });

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      date: {
        filterKey: "Month",
        type: "range",
        itemTransform: (v) => moment(v).toISOString(),
      },
      gyms: {
        filterKey: "GymName",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Trainer/Hours", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        if (!response.ok) return;

        let data = response.json;

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        return this.setStateAsync({
          hours: data.results.values,
          total: data.results.total,
          pagination: pagination
        });
      });
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getHours.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getHours.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getHours();
    });
  }

  mapDataToTable() {
    return (
      this.state.hours &&
        this.state.hours.map((hour) => ({
          ...hour.values,
          key: hour.month,
          name: hour.trainer.firstName + " " + hour.trainer.lastName,
          gym: hour.gym.city,
          month: moment(hour.month).format("MMMM"),
          hours: hour.hours,
        }))
    );
  }

  formatTotal() {
    return this.state.total ? this.state.total + " totaal" : "0";
  }

  updatePagination(page, pageSize) {
    this.getHours(page, pageSize);
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    return (
      <div className="hoursPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="hoursPage-container">
          <Topbar title="Urenoverzicht" />

          <div className="hoursPage-mainContent">
            {!admin
              ? <div className="hoursPage-trainerRole">Uren zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  <HourFilter updateFilter={this.updateFilter.bind(this)} />
                  <Table
                    pagination={{
                      ...this.state.pagination, 
                      onPageChange: this.updatePagination.bind(this),
                    }}
                    filterQuery={this.state.filterQuery}
                    updateFilter={this.updateTableFilter.bind(this)}
                    sortQuery={this.state.sortQuery}
                    updateSort={this.updateSort.bind(this)}
                    headers={[
                      {
                        title: "Naam",
                        property: "name",
                        filterKey: "Name",
                      },
                      {
                        title: "Gym",
                        property: "gym",
                        filterKey: "GymName",
                        filterable: false,
                      },
                      {
                        title: "Maand",
                        property: "month",
                        filterKey: "Month",
                        filterable: false,
                      },
                      {
                        title: "Uren",
                        property: "hours",
                        filterKey: "Hours",
                        filterable: false,
                      },
                    ]}
                    data={this.mapDataToTable()}
                    footers={[
                      {
                        property: "name",
                      },
                      {
                        property: "gym",
                      },
                      {
                        property: "month",
                      },
                      {
                        property: "hours",
                        title: this.formatTotal(),
                      },
                    ]}
                  />
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HoursPage;
