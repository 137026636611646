import { RRule } from 'rrule';

var generateOptions = (n, l) => [...Array(n).keys()]
  .map(i => ({ label: (i+2) + ' ' + l, value: i+2 }));

export const recurrenceFrequencies = [
  {
    label: 'Dagelijks',
    value: RRule.DAILY,
    intervals: [
      {
        label: 'Dag',
        value: 1
      },
      ...generateOptions(3, 'dagen')
    ]
  },
  {
    label: 'Wekelijks',
    value: RRule.WEEKLY,
    intervals: [
      {
        label: 'Week',
        value: 1
      },
      ...generateOptions(3, 'weken')
    ]
  },
  {
    label: 'Maandelijks',
    value: RRule.MONTHLY,
    intervals: [
      {
        label: 'Maand',
        value: 1
      },
      ...generateOptions(3, 'maanden')
    ]
  },
  {
    label: 'Jaarlijks',
    value: RRule.YEARLY,
    intervals: [
      {
        label: 'Jaar',
        value: 1
      },
      ...generateOptions(3, 'jaar')
    ]
  },
];

export const recurrenceDays = [
  {
    label: 'Ma',
    value: RRule.MO,
  },
  {
    label: 'Di',
    value: RRule.TU,
  },
  {
    label: 'Wo',
    value: RRule.WE,
  },
  {
    label: 'Do',
    value: RRule.TH,
  },
  {
    label: 'Vr',
    value: RRule.FR,
  },
  {
    label: 'Za',
    value: RRule.SA,
  },
  {
    label: 'Zo',
    value: RRule.SU,
  },
];
