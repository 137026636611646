import React, { Fragment } from 'react';
import Checkbox from "../../../app/input/checkbox/checkbox";
import PopupOverlay from '../../../app/popup/popup-overlay';
import BaseComponent from "../../../app/base-component";
import { Status } from '../status';
import RadioInput from '../../../app/input/radio-input/radio-input';
import './remove-clients-popup.css';

class RemoveClientsPopop extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      cancellations: props.cancellations,
      cancelled: false,
      cancelledFree: false,
      cancelDelete: false,
    };
  }

  selectCancelStatus(selectedValue) {
    this.setState(({
      cancelled: false,
      cancelledFree: false,
      cancelDelete: false,
      [selectedValue]: true,
    }));
  }
  
  setClientsStatus() {
    var value; 
    if(this.state.cancelled) {
      value = Status.Cancelled.name;
    } else if(this.state.cancelledFree) {
      value = Status.CancelledFree.name;
    } else {
      value = Status.Deleted.name;
    }

    this.setStateAsync(state => ({
      cancellations: state.cancellations.map(client => ({
          ...client,
          status: value
        }))
    }))
    .then(() => {
      this.props.onComplete(this.state.cancellations)
    });
  }

  render() {
    return (
      <div className='removeClients-popup'>
        <PopupOverlay
          actions={[
            {
              label: 'Bevestigen',
              handler: this.setClientsStatus.bind(this),
              submit: true
            },
          ]}
          title='Klanten verwijderen'
          onDismiss={this.props.onDismiss}
        >
          <div className='removeClients-popupFormContainer'>
            <div className="cancelStatus">
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelled}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelled')}
                />
                <label>Wel kosten klant, geen inhaal tegoed</label>
              </div>
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelledFree}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelledFree')}
                />
                <label>Wel kosten klant, wel inhaal tegoed</label>
              </div>
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelDelete}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelDelete')}
                />
                <div className="cancelAppointment-delete">
                  <label>Geen kosten klant, geen inhaal tegoed</label>
                  <span>Let op, deze annulering kan niet ongedaan worden gemaakt</span>
                </div>  
              </div>
            </div>
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default RemoveClientsPopop;
