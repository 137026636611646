import React, { Component } from "react";
import "../client-weightlogs-popup.css";
import moment from "moment-timezone";
import WeightlogsRow from "./weightlogs-row";
import ApiClient from "../../../../api-client/api-client";
import AppContext from "../../../../app/app-context";

class Weightlogs extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedLog: {
        id: null,
        date: moment().format("YYYY-MM-DD"),
        note: "",
        weight: 0,
      },
      addWeightlogShown: false,
    };
  }

  getWeeklyWeightLogGrouping(list, dateKey = "date") {
    //1. Sort list on date
    const sortedList = list.sort((a, b) =>
      moment(a[dateKey]).diff(moment(b[dateKey]))
    );

    //2. Find first date and day of week
    const firstDate = moment(sortedList[0][dateKey]);
    const firstDateDayOfWeek = firstDate.get("day");

    //3. Find last date
    const lastDate = moment(sortedList[sortedList.length - 1][dateKey]);

    //4. Find difference in first and last date in terms of period
    const difference = Math.ceil(lastDate.diff(firstDate, "days"));
    const additionalDaysInFirstWeek =
      firstDateDayOfWeek === 0 ? 0 : 7 - firstDateDayOfWeek;
    const amountOfGroups =
      Math.ceil((difference - additionalDaysInFirstWeek) / 7) + 1;

    //5. Create a list of lists (#difference)
    let groups = [];
    for (let i = 0; i < amountOfGroups; i++) {
      groups.push({ week: i + 1, data: [] });
    }

    //6. Assign each object to the correct list
    sortedList.forEach((object) => {
      const index = Math.ceil(
        (moment(object[dateKey]).diff(firstDate, "days") -
          additionalDaysInFirstWeek) /
          7
      );
      const day = moment(object[dateKey]).diff(moment(firstDate), "days") + 1;
      groups[index].data.unshift({ ...object, day: day });
    });

    //7. Fill empty lists
    const labeledGroups = groups.map((group) => ({
      ...group,
      lowest:
        group.data.length === 0
          ? "-"
          : Math.min(...group.data.map((d) => d.weight)),
    }));

    return labeledGroups;
  }

  setLog(log) {
    this.setState({
      selectedLog: log,
      addWeightlogShown: false,
    });
  }
  toggleAddNewWeightlog() {
    const { lastWeight } = this.props;
    this.setState((state) => ({
      selectedLog: {
        id: null,
        weight: lastWeight,
        date: moment().format("YYYY-MM-DD"),
        note: null,
      },
      addWeightlogShown: !state.addWeightlogShown,
    }));
  }
  setProp(key, val) {
    this.setState((state) => ({
      selectedLog: {
        ...state.selectedLog,
        [key]: val,
      },
    }));
  }
  saveLog() {
    const { lastWeight, client, refreshList, getClientFromAPI } = this.props;
    const log = this.state.selectedLog;
    let weightMetric = {
      gymId: client.gym.id,
      trainerId: client.trainer.id,
      // Added 4 hours, because of timezones, otherwise the date would result in yesterday
      date: moment(log.date).add(4, "hours").format(),
      weight:
        typeof number === "string"
          ? log.weight.toLocaleString("en-US").replace(/,/g, ".")
          : log.weight,
      note: log.note,
    };
    new ApiClient(this.context)
      .call("PUT", "/V1/Client/" + client.id + "/WeightMetric", weightMetric)
      .then((response) => {
        if (response.ok) {
          this.setLog({
            id: null,
            weight: lastWeight,
            date: moment().format("YYYY-MM-DD"),
            note: undefined,
          });
          getClientFromAPI();
          refreshList();
        }
      });
  }

  render() {
    const { lastWeight, client } = this.props;

    const weightLogs =
      client.weightMetrics.length > 0
        ? this.getWeeklyWeightLogGrouping(client.weightMetrics)
        : null;

    const defaultLog = {
      id: null,
      weight: lastWeight,
      date: moment().format("YYYY-MM-DD"),
      note: undefined,
    };
    return (
      <>
        {this.state.addWeightlogShown ? (
          <>
            <span className="title">Nieuwe weightlog</span>
            <WeightlogsRow
              isSelected={true}
              setLog={this.setLog.bind(this)}
              setProp={this.setProp.bind(this)}
              saveLog={this.saveLog.bind(this)}
              cancel={this.setLog.bind(this, defaultLog)}
              selectedLog={this.state.selectedLog}
            />
          </>
        ) : (
          <div className="weightlogsHeader">
            Gewichtlogs
            <button
              className="addWeightLogButton"
              onClick={this.toggleAddNewWeightlog.bind(this)}
            >
              Voeg een nieuwe log toe
            </button>
          </div>
        )}
        {weightLogs &&
          weightLogs.reverse().map((week) => (
            <div key={week.week} className="weekContainer">
              <div className="weekHeader">
                <p className="weekText">{`Week ${week.week}`}</p>
                <p className="lowestText">
                  {week.lowest !== "-"
                    ? `laagste gewicht: ${week.lowest.toLocaleString()} kg`
                    : ""}
                </p>
              </div>
              {week.data.length === 0
                ? "Geen gewicht logs"
                : week.data.map((log) => {
                    let isSelected = log.id === this.state.selectedLog.id;
                    return (
                      <WeightlogsRow
                        isSelected={isSelected}
                        setLog={this.setLog.bind(this)}
                        setProp={this.setProp.bind(this)}
                        saveLog={this.saveLog.bind(this)}
                        cancel={this.setLog.bind(this, defaultLog)}
                        log={log}
                        selectedLog={this.state.selectedLog}
                      />
                    );
                  })}
            </div>
          ))}
      </>
    );
  }
}

export default Weightlogs;
