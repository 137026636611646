import React, { Component } from 'react';
import './filterbar.css';

class FilterBar extends Component {
  render() {
    return (
      <div className='filterbar'>
        <div className='filterbar-container'>{this.props.children}</div>
      </div>
    );
  }
}

export default FilterBar;
