import React, { Component } from 'react';
import './time-input.css';

class TimeInput extends Component {
  onInputChange(e) {
    e.preventDefault();
    let val = e.target.value;
    this.props.onInputChange(val);
  }

  render() {
    return (
      <input
        className='timeInput'
        type='time'
        name={this.props.name}
        value={this.props.value}
        onChange={this.onInputChange.bind(this)}
        disabled={this.props.disabled}
      />
    );
  }
}

export default TimeInput;
