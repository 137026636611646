import React, { Component } from "react";
import { Redirect } from "react-router";
import AppContext from "../../app/app-context";

class LogoutPage extends Component {
  static contextType = AppContext;

  componentDidMount() {
    this.context.logOut();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default LogoutPage;
