import React from "react";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import placeholderImg from "../../../app/image/placeholder-image.png";
import BaseComponent from "../../../app/base-component";
import InputWithLabel from "../../../app/input/input-with-label";
import PopupOverlay from "../../../app/popup/popup-overlay";
import MFImage from "../../../app/image/image";
import moment from "moment-timezone";
import "./trainers-popup.css";

class TrainersPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    var defaultTrainer = {
      firstName: "",
      lastName: "",
      birthdate: "",
      phoneNumber: "",
      email: "",
      street: "",
      houseNumber: "",
      zipCode: "",
      city: "",
      iban: "",
      gymId: "",
      roleName: "",
    };

    if (context.user && props.showOnly) {
      let user = context.user.fitUser;

      defaultTrainer = {
        firstName: user.firstName,
        lastName: user.lastName,
        birthdate: moment(user.birthdate).format("YYYY-MM-DD"),
        phoneNumber: user.phoneNumber,
        email: user.email,
        street: user.street,
        houseNumber: user.houseNumber,
        city: user.city,
        zipCode: user.zipCode,
        image: user.image,
        iban: user.billingDetails.iban,
      };
    }

    this.state = {
      trainer: defaultTrainer,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      trainer: { ...state.trainer, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      trainer: { ...state.trainer, [key]: val },
    }));
  }

  onToggle(key) {
    this.setState((state) => ({
      [key]: !state[key],
    }));
  }

  createTrainer() {
    let trainer = this.state.trainer;
    const requiredVals =
      trainer.firstName &&
      trainer.lastName &&
      trainer.roleName &&
      trainer.phoneNumber &&
      trainer.email &&
      trainer.street &&
      trainer.houseNumber &&
      trainer.zipCode &&
      trainer.city &&
      trainer.gymId;

    let minimumDate = moment().subtract("12", "years").toISOString();
    let validBirthdate = moment(trainer.birthdate).isBefore(minimumDate);

    if (!requiredVals) {
      return this.context.addNotification(
        "warning",
        "Zijn alle velden ingevuld?"
      );
    } else if (!validBirthdate) {
      return this.context.addNotification(
        "warning",
        "Ben je ouder dan 12 jaar?"
      );
    } else {
      new ApiClient(this.context)
        .call("POST", "/v1/Trainer", trainer)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.refreshList();
            this.context.addNotification("success", "Trainer is aangemaakt");
          } else {
            this.context.addNotification("error", "Er is een fout opgetreden");
          }
        });
    }
  }

  render() {
    return (
      <div className="trainers-popup">
        <PopupOverlay
          actions={[
            {
              label: !this.props.showOnly ? "Trainer opslaan" : "Sluiten",
              handler: !this.props.showOnly
                ? this.createTrainer.bind(this)
                : this.props.onDismiss,
            },
          ]}
          title={!this.props.showOnly ? "Trainer aanmaken" : "Mijn gegevens"}
          onDismiss={!this.props.showOnly && this.props.onDismiss}
          img={
            <MFImage
              image={this.state.trainer.image}
              alt=""
              placeholder={placeholderImg}
            />
          }
          placeholder
        >
        <div className="trainers-popupFormContainer">
          {!this.props.showOnly && (
            <div className="trainers-popupFormRow">
              <InputWithLabel
                select={true}
                title="Gebruikersniveau"
                value={this.state.trainer.roleName}
                name="roleId"
                options={this.props.roles}
                onSelect={this.onSelect.bind(this, "roleName")}
              />
            </div>
          )}

          <div className="trainers-popupFormRow">
            <InputWithLabel
              text={true}
              title="Voornaam"
              value={this.state.trainer.firstName}
              name="firstName"
              onInputChange={this.onInputChange.bind(this, "firstName")}
            />
            <InputWithLabel
              text={true}
              title="Achternaam"
              value={this.state.trainer.lastName}
              name="lastName"
              onInputChange={this.onInputChange.bind(this, "lastName")}
            />
          </div>

          <div className="trainers-popupFormRow">
            <InputWithLabel
              date={true}
              title="Geboortedatum"
              value={this.state.trainer.birthdate}
              name="birthdate"
              onInputChange={this.onInputChange.bind(this, "birthdate")}
            />
            {!this.props.showOnly && (
              <InputWithLabel
                select={true}
                title="Gym"
                value={this.state.trainer.gymId}
                name="gymId"
                options={this.props.gyms}
                onSelect={this.onSelect.bind(this, "gymId")}
              />
            )}
          </div>

          <div className="trainers-popupFormRow">
            <InputWithLabel
              text={true}
              title="Telefoonnummer"
              value={this.state.trainer.phoneNumber}
              name="phoneNumber"
              onInputChange={this.onInputChange.bind(this, "phoneNumber")}
            />
            <InputWithLabel
              text={true}
              title="E-mailadres"
              value={this.state.trainer.email}
              name="email"
              onInputChange={this.onInputChange.bind(this, "email")}
            />
          </div>

          <div className="trainers-popupFormRow">
            <InputWithLabel
              text={true}
              title="Straatnaam"
              value={this.state.trainer.street}
              name="street"
              onInputChange={this.onInputChange.bind(this, "street")}
            />
            <InputWithLabel
              text={true}
              title="Huisnummer"
              value={this.state.trainer.houseNumber}
              name="houseNumber"
              onInputChange={this.onInputChange.bind(this, "houseNumber")}
            />
          </div>

          <div className="trainers-popupFormRow">
            <InputWithLabel
              text={true}
              title="Stad"
              value={this.state.trainer.city}
              name="city"
              onInputChange={this.onInputChange.bind(this, "city")}
            />
            <InputWithLabel
              text={true}
              title="Postcode"
              value={this.state.trainer.zipCode}
              name="zipCode"
              onInputChange={this.onInputChange.bind(this, "zipCode")}
            />
          </div>

          {!this.props.showOnly && (
            <div className="trainers-popupFormRow">
              <InputWithLabel
                text={true}
                title="IBAN"
                value={this.state.trainer.iban}
                name="iban"
                onInputChange={this.onInputChange.bind(this, "iban")}
              />
            </div>
          )}
          </div>
      </PopupOverlay>
    </div>
    );
  }
}

export default TrainersPopup;
