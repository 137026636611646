import React, { Component } from "react";
import Card from "../../app/card/card";
import StatisticsCharts from "../../app/statistics/statistics-charts";

class DashboardStatistics extends Component {
  render() {
    if (this.props.statistics === null) return false;
    return (
      <div className="dashboardStatistics">
        <StatisticsCharts
          statistics={this.props.statistics}
          filters={this.props.filters}
        />
        <div className="dashboardStatistics-cards">
          <Card
            title="Omzet"
            value={`${this.props.statistics.revenue && parseFloat(this.props.statistics.revenue).toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })}`}
          />
          <Card title="Activiteiten" value={this.props.statistics.activities} />
          <Card
            title="Nieuwe klanten"
            value={this.props.statistics.newClients}
          />
        </div>
      </div>
    );
  }
}
export default DashboardStatistics;
