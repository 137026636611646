import React from 'react';
import { withRouter } from 'react-router-dom';
import Appcontext from '../../app/app-context';
import InputLabel from '../../app/input/label-input/label-input';
import TextInput from '../../app/input/text-input/text-input';
import Popup from '../../app/popup/popup';
import ApiClient from "../../api-client/api-client";
import AuthenticationPage from '../authentication-page';
import BaseComponent from "../../app/base-component";
import './password-request-page.css';


class PasswordRequestPage extends BaseComponent {
  static contextType = Appcontext;
  constructor(props) {
    super(props);
    
    var params = props.location.state || {};

    this.state = {
      email: params.email || '',
      sent: false,
    };
  }

  onInputChange(key, val) {
    this.setState(state => ({
      [key]: val,
    }));
  }

  requestPasswordReset() {
    new ApiClient(this.context)
    .call('POST', '/v1/User/PasswordResetJwtRequest', {
      'email': this.state.email,
    })
    .then(response => {
      if(response.ok) {
        this.setStateAsync(state => ({
          sent: true,
        }))
        .then(() => {
          this.context.addNotification('success', 'Als er een account bestaat met dit e-mailadres is er een e-mail verzonden');
          this.props.history.push('/login');
        });
      }
      else {
        this.context.addNotification('warning', 'Vul een geldig e-mailadres in');
      }
    });

  }
  

  render() {
    return (
      <AuthenticationPage>
        <div className='passwordRequestPage'>
          <Popup title='Wachtwoord vergeten?'>
            <div className='popup-section'>
              <div className='popup-column'>
                <span>
                  Vul hier uw e-mailadres in en u ontvangt instructies 
                  om een nieuw wachtwoord aan te vragen.
                </span>
                <div className='popup-inputContainer'>
                  <InputLabel content='E-mailadres' />
                  <TextInput
                    value={this.state.email}
                    type='text'
                    name='email'
                    onInputChange={this.onInputChange.bind(this, 'email')}
                    disabled={this.state.sent}
                  />
                </div>
              </div>
              <div className='popup-column'>
                <div className='popup-buttonContainer'>
                  <button
                    className={this.state.sent ? 'popup-popupButton disabled' : 'popup-popupButton'}
                    onClick={this.requestPasswordReset.bind(this)}
                    disabled={this.state.sent}
                  >
                    Verstuur
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </AuthenticationPage>
    );
  }
}

export default withRouter(PasswordRequestPage);
