
export class Status {
  static Scheduled = new Status('SCHEDULED');
  static Rescheduled = new Status('RESCHEDULED');
  static Cancelled = new Status('CANCELLED');
  static CancelledFree = new Status('CANCELLED_FREE');
  static RescheduledCancelled = new Status('RESCHEDULED_CANCELLED');
  static RescheduledCompleted = new Status('RESCHEDULED_COMPLETED');
  static Completed = new Status('COMPLETED');
  static Invoiced = new Status('INVOICED');
  static Deleted = new Status('DELETED');
  static InvoicedCredit = new Status('INVOICED_CREDIT');

  constructor(name) {
    this.name = name;
  }
}

export const InactiveStatusses = [Status.Cancelled, Status.CancelledFree, Status.RescheduledCancelled].map(s => s.name);
export const ActiveStatusses = [Status.Scheduled, Status.Rescheduled, Status.RescheduledCompleted, Status.Completed, Status.Invoiced, Status.InvoicedCredit].map(s => s.name);
export const RescheduledStatusses = [Status.Rescheduled, Status.RescheduledCancelled, Status.RescheduledCompleted].map(s => s.name);

export class Type {
  static Single = new Type('SINGLE');
  static Future = new Type('FUTURE');
  static All = new Type('ALL');

  constructor(name) {
    this.name = name;
  }
}