import React, { Component } from 'react';
import { HashRouter, NavLink } from 'react-router-dom';

class MenuItemHash extends Component {
  render() {
    return (
      <HashRouter hashType="noslash" >
        <NavLink to={this.props.target}>
          <div className={'menuItem'}>
            <this.props.icon color={'var(--color-blue-700)'} />
            <div>{this.props.children}</div>
          </div>
        </NavLink>
      </HashRouter>
    );
  }
}
export default MenuItemHash;
