import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import AuthenticationPage from "../authentication-page";
import TextInput from "../../app/input/text-input/text-input";
import InputLabel from "../../app/input/label-input/label-input";
import Checkbox from "../../app/input/checkbox/checkbox";
import Popup from "../../app/popup/popup";
import "./login-page.css";

class LoginPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      stayLoggedIn: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("staySignedIn") === "true") {
      this.authenticateUser(localStorage.getItem("accessToken"));
    }
  }

  login() {
    new ApiClient(this.context)
      .call(
        "POST",
        "/connect/token",
        {
          username: this.state.email,
          password: this.state.password,
          client_id: this.context.settings.apiClient,
          client_secret: this.context.settings.apiSecret,
          grant_type: "password",
          scope: "offline_access",
        },
        true
      )
      .then((response) => {
        if (response.ok) {
          let json = response.json;
          this.context.signIn(
            this.state.stayLoggedIn,
            json["access_token"],
            json["refresh_token"]
          );
          this.authenticateUser(json["access_token"]);
        }
      });
  }

  authenticateUser(bearerToken) {
    new ApiClient(this.context)
      .call("GET", "/v1/User/GetCurrent", null, false, {
        Authorization: "Bearer " + bearerToken,
      })
      .then((result) => {
        if (result.ok) {
          let user = result.json;
          if (user.forceChangePassword) {
            this.props.history.push(
              "/password-first-change?email=" +
                encodeURIComponent(this.state.email)
            );
          }
          this.context.setTimezone(result.json.timeZone);
          this.context.setUser(user);
        }
      });
  }

  onInputChange(key, val) {
    this.setState({
      [key]: val,
    });
  }

  onToggle() {
    this.setState({
      stayLoggedIn: !this.state.stayLoggedIn,
    });
  }

  render() {
    return (
      <AuthenticationPage>
        {this.context.user && <Redirect to="/dashboard" />}
        <div className="loginPage">
          <Popup>
            <div className="popup-section">
              <div className="popup-column">
                <div className="popup-inputContainer">
                  <InputLabel content="E-mailadres" />
                  <TextInput
                    value={this.state.email}
                    type="text"
                    name="email"
                    onInputChange={this.onInputChange.bind(this, "email")}
                  />
                </div>
                <div className="popup-inputContainer">
                  <InputLabel content="Wachtwoord" />
                  <TextInput
                    value={this.state.password}
                    type="password"
                    name="password"
                    onInputChange={this.onInputChange.bind(this, "password")}
                  />
                </div>
                <div className="popup-checkboxContainer">
                  <Checkbox
                    key={"GUID55"}
                    id="stayLoggedIn"
                    label="Ingelogd blijven"
                    val={this.state.stayLoggedIn}
                    onToggle={this.onToggle.bind(this)}
                  />
                </div>
                <div className="popup-buttonContainer">
                  <button
                    className="popup-popupButton"
                    onClick={this.login.bind(this)}
                  >
                    Inloggen
                  </button>
                </div>
                <div className="popup-linkContainer">
                  <Link to="/password-request">Wachtwoord vergeten?</Link>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </AuthenticationPage>
    );
  }
}

export default withRouter(LoginPage);
