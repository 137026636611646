import React from "react";
import { BarChart2, FileText, User, Grid } from "react-feather";
import moment from "moment-timezone";
import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";
import Dumbbell from "../../../app/custom-icons/dumbbell-icon";
import Euro from "../../../app/custom-icons/euro-icon";
import placeholderImg from "../../../app/image/placeholder-image.png";
import InputWithLabel from "../../../app/input/input-with-label";
import MFImage from "../../../app/image/image";
import SliderPopup from "../../../app/slider-popup/slider-popup";
import BaseComponent from "../../../app/base-component";
import { withRouter } from "react-router-dom";
import "./edit-client-popup.css";

const statuses = [
  {
    label: "Actief",
    value: "true",
  },
  {
    label: "Inactief",
    value: "false",
  },
];

class EditClientPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let client = props.client && {
      ...props.client.fitUser,
      id: props.client.id,
      birthdate: props.client.fitUser.birthdate,
      gymId: props.client.gym ? props.client.gym.id : "",
      trainerId: props.client.trainer ? props.client.trainer.id : "",
      active: String(props.client.active),
      image: props.client.fitUser.image,
      iban: props.client.fitUser.billingDetails.iban,
      mandaatId: props.client.fitUser.billingDetails.mandaatId
        ? props.client.fitUser.billingDetails.mandaatId
        : "",
      mandaatDate: props.client.fitUser.billingDetails.mandaatDate
        ? props.client.fitUser.billingDetails.mandaatDate
        : "",
      bic: props.client.fitUser.billingDetails.bic,
      cardName: props.client.fitUser.billingDetails.cardName,
      isActivatedClient: props.client.isActivatedClient,
    };

    let gyms = props.gyms.map((gym) => ({
      ...gym,
      value: gym.id,
      label: gym.name,
    }));

    let trainers = props.trainers.map((trainer) => ({
      ...trainer,
      value: trainer.id,
      label: trainer.fitUser.firstName + " " + trainer.fitUser.lastName,
    }));

    let products = props.products.map((product) => ({
      id: product.id,
      label: product.name,
      price: product.price,
    }));

    this.state = {
      client: client,
      gyms: gyms,
      trainers: trainers,
      products: products,
      statuses: statuses,
      credits: [],
    };
  }
  componentDidMount() {
    new ApiClient(this.context)
      .call("GET", "/v1/Client/" + this.props.client.id + "/credits")
      .then((result) => {
        const mappedAppointments = result.json.map((c) => ({
          start: c.appointment.start,
          end: c.appointment.end,
          id: c.appointment.id,
          productName: c.appointment.product.name,
        }));

        const groupedAppointments = mappedAppointments.reduce(
          (result, appointment) => {
            (result[appointment["productName"]] =
              result[appointment["productName"]] || []).push(appointment);
            return result;
          },
          {}
        );
        const sortedGroupedAppointments = [];
        for (const product in groupedAppointments) {
          sortedGroupedAppointments.push({
            product: product,
            appointments: groupedAppointments[product].sort((a, b) =>
              moment(a["start"]).diff(moment(b["start"]))
            ),
          });
        }
        this.setState({
          credits: sortedGroupedAppointments,
        });
      });
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  updateClient() {
    let minimumDate = moment().subtract("12", "years").toISOString();
    let validBirthdate = moment(this.state.client.birthdate).isBefore(
      minimumDate
    );

    let updatedClient = {
      ...this.state.client,
      active: this.state.client.active === "true",
    };

    if (!validBirthdate) {
      return this.context.addNotification(
        "warning",
        "Ben je ouder dan 12 jaar?"
      );
    } else {
      new ApiClient(this.context)
        .call("PUT", "/v1/Client/" + this.state.client.id, updatedClient)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.refreshList();
            this.context.addNotification("success", "Klant is opgeslagen");
          } else {
            throw result;
          }
        });
    }
  }

  setClientActive() {
    let client = this.state.client;

    this.setStateAsync((state) => ({
      ...state.client,
      active: true,
    })).then(() => {
      new ApiClient(this.context)
        .call("PUT", "/v1/Client/" + client.id + "/ActivateClient")
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.refreshList();
          } else {
            throw result;
          }
        });
    });
  }

  redirectTo(key) {
    this.props.history.push(`/${key}`, {
      client: this.state.client.name,
    });
  }

  render() {
    let gymId = this.state.client.gymId;
    let trainers = this.state.trainers
      .map((t) => ({
        gymId: t.gym.id,
        value: t.id,
        label: t.fitUser.firstName + " " + t.fitUser.lastName,
      }))
      .filter((trainer) => trainer.gymId.includes(gymId));

    return (
      <div className="editClientPopup">
        <SliderPopup
          title={this.state.client.firstName + " " + this.state.client.lastName}
          closeButton="Sluiten zonder opslaan"
          close={this.props.onDismiss}
          saveButton="Klant opslaan"
          save={this.updateClient.bind(this)}
          items={[
            {
              title: "Algemeen",
              target: "algemeen",
              icon: User,
              content: (
                <div>
                  <div className="editClientPopup-image">
                    <MFImage
                      image={this.state.client.image}
                      alt=""
                      placeholder={placeholderImg}
                    />
                    {this.state.client.image === null && (
                      <span>Kan aangepast worden door gebruiker in de app</span>
                    )}
                  </div>
                  <div className="sm-select form-halfWidth">
                    <InputWithLabel
                      select={true}
                      title="Klantstatus"
                      name="active"
                      options={statuses}
                      onSelect={this.onSelect.bind(this, "active")}
                      value={this.state.client.active}
                    />
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Voornaam"
                        name="firstName"
                        value={this.state.client.firstName}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "firstName"
                        )}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Achternaam"
                        name="lastName"
                        value={this.state.client.lastName}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "lastName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm-select form-halfWidth">
                    <InputWithLabel
                      date={true}
                      title="Geboortedatum"
                      value={this.state.client.birthdate}
                      name="birthdate"
                      onInputChange={this.onInputChange.bind(this, "birthdate")}
                    />
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Telefoonnummer"
                        name="phoneNumber"
                        value={this.state.client.phoneNumber}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "phoneNumber"
                        )}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="E-mailadres"
                        name="email"
                        value={this.state.client.email}
                        onInputChange={this.onInputChange.bind(this, "email")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Straatnaam"
                        name="street"
                        value={this.state.client.street}
                        onInputChange={this.onInputChange.bind(this, "street")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Huisnummer"
                        name="houseNumber"
                        value={this.state.client.houseNumber}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "houseNumber"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Plaats"
                        name="city"
                        value={this.state.client.city}
                        onInputChange={this.onInputChange.bind(this, "city")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Postcode"
                        name="zipCode"
                        value={this.state.client.zipCode}
                        onInputChange={this.onInputChange.bind(this, "zipCode")}
                      />
                    </div>
                  </div>
                  <div className="sliderPopup-buttonContainer">
                    {!this.state.client.isActivatedClient && (
                      <button
                        className="sliderPopup-button button-outline"
                        onClick={this.setClientActive.bind(this)}
                      >
                        Activeer account
                      </button>
                    )}
                  </div>
                </div>
              ),
            },
            {
              title: "Credits",
              target: "credits",
              icon: BarChart2,
              content:
                this.state.credits.length > 0
                  ? this.state.credits.map((c) => (
                      <div style={{ marginBottom: "20px" }}>
                        {`${c.product} - ${c.appointments.length} credit${
                          c.appointments.length > 1 ? "s" : ""
                        }`}
                        {c.appointments.map((a) => (
                          <div style={{ fontStyle: "italic" }}>{`${moment(
                            a.start
                          ).format("dd D MMMM - HH:mm")} - ${moment(
                            a.end
                          ).format("HH:mm")}`}</div>
                        ))}
                      </div>
                    ))
                  : "Geen credits",
            },
            {
              title: "Gym en diensten",
              target: "gym",
              icon: Grid,
              content: (
                <div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        select={true}
                        title="Gym"
                        name="gymId"
                        options={this.state.gyms}
                        onSelect={this.onSelect.bind(this, "gymId")}
                        value={this.state.client.gymId}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        select={true}
                        title="Trainer"
                        name="trainerId"
                        options={trainers}
                        onSelect={this.onSelect.bind(this, "trainerId")}
                        value={this.state.client.trainerId}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: "Betaalgegevens",
              target: "payment",
              icon: Euro,
              content: (
                <div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="IBAN"
                        value={this.state.client.iban}
                        name="iban"
                        onInputChange={this.onInputChange.bind(this, "iban")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Naam"
                        value={this.state.client.cardName}
                        name="cardName"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "cardName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="BIC code"
                        value={this.state.client.bic}
                        name="bic"
                        onInputChange={this.onInputChange.bind(this, "bic")}
                      />
                    </div>
                    <div className="form-quarterWidth">
                      <InputWithLabel
                        text={true}
                        title="Mandaat ID"
                        value={this.state.client.mandaatId}
                        name="mandaadId"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "mandaatId"
                        )}
                      />
                    </div>
                    <div className="form-quarterWidth">
                      <InputWithLabel
                        text={true}
                        title="Mandaat datum"
                        value={this.state.client.mandaatDate}
                        name="mandaatDate"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "mandaatDate"
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: "Gevolgde activiteiten",
              target: "activities",
              icon: Dumbbell,
              content: (
                <div className="sliderPopup-buttonContainer">
                  <button
                    className="sliderPopup-button"
                    onClick={this.redirectTo.bind(this, "activities")}
                  >
                    Klik hier om alle activiteiten te bekijken
                  </button>
                </div>
              ),
            },
            {
              title: "Facturen",
              target: "bills",
              icon: FileText,
              content: (
                <div className="sliderPopup-buttonContainer">
                  <button
                    className="sliderPopup-button"
                    onClick={this.redirectTo.bind(this, "invoices")}
                  >
                    Klik hier om alle facturen te bekijken
                  </button>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(EditClientPopup);
