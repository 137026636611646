import React from "react";
import moment from "moment-timezone";
import { RRule } from "rrule";
import BaseComponent from "../../../app/base-component";
import DateInput from "../../../app/input/date-input/date-input";
import InputWithLabel from "../../../app/input/input-with-label";
import InputLabel from "../../../app/input/label-input/label-input";
import TextInput from "../../../app/input/text-input/text-input";
import PopupOverlay from "../../../app/popup/popup-overlay";
import DayButton from './day-button';
import RadioInput from "../../../app/input/radio-input/radio-input";
import { recurrenceFrequencies, recurrenceDays } from "./recurrence-constants";
import './recurrence-popup.css';

class RecurrencePopup extends BaseComponent {
  constructor(props) {
    super(props);

    let rule =
      props.recurrenceRule && props.recurrenceRule.rule
        ? props.recurrenceRule.rule
        : "";
    let options = RRule.parseString(rule);

    this.state = {
      rule: {
        freq: options.freq || RRule.WEEKLY,
        interval: options.interval || 1,
        selectedDays: options.byweekday || [],
        count: options.count || "1",
        until: options.until || moment().format("YYYY-MM-DD"),
      },
      forever: !options.count && !options.until,
      hasCount: !!options.count,
      hasUntil: !!options.until,
    };
  }

  toggleSelectedDay(day) {
    this.setState((state) => {
      let isSelected = state.rule.selectedDays.includes(day);
      var selectedDays = [];

      //Check if the day is already isSelected
      if (isSelected) {
        selectedDays = state.rule.selectedDays.filter((d) => d !== day);
      } else {
        selectedDays = [...state.rule.selectedDays, day];
      }

      return {
        rule: {
          ...state.rule,
          selectedDays: selectedDays,
        },
      };
    });
  }

  renderDays() {
    return recurrenceDays.map((day) => (
      <DayButton
        key={day.label}
        day={day.label}
        isSelected={this.state.rule.selectedDays.includes(day.value)}
        onClick={this.toggleSelectedDay.bind(this, day.value)}
      />
    ));
  }

  handleRecurrenceRule() {
    let rule = this.state.rule;
    var options = {
      freq: rule.freq,
      interval: rule.interval,
    };

    if (rule.freq === RRule.WEEKLY) {
      options.byweekday = rule.selectedDays;
    }

    if (this.state.hasCount) {
      options.count = rule.count;
    }

    if (this.state.hasUntil) {
      options.until = rule.until;
    }

    this.props.onComplete({
      ...this.props.recurrenceRule,
      rule: new RRule(options).toString(),
    });
  }

  onSelect(key, val) {
    this.setState((state) => ({
      rule: {
        ...state.rule,
        [key]: val,
      },
    }));
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      rule: { ...state.rule, [key]: val },
    }));
  }

  selectRadioButton(selectedValue) {
    this.setState((state) => ({
      forever: false,
      hasCount: false,
      hasUntil: false,
      [selectedValue]: true,
    }));
  }

  render() {
    let selectedFrequency = recurrenceFrequencies.filter(
      (f) => f.value === this.state.rule.freq
    )[0];

    let intervals = selectedFrequency ? selectedFrequency.intervals : [];

    return (
      <div className="recurrence-popup">
        <PopupOverlay
          actions={[
            {
              label: "Terugkeerpatroon instellen",
              handler: this.handleRecurrenceRule.bind(this),
              submit: true,
            },
          ]}
          title={"Terugkeerpatroon"}
          onDismiss={this.props.onDismiss}
        >
          <div className="recurrence-popupFormContainer">
            <div className="recurrence-popupFormRow">
              <InputWithLabel
                select={true}
                title="Frequentie"
                value={this.state.rule.freq}
                name="freq"
                options={recurrenceFrequencies}
                onSelect={this.onSelect.bind(this, "freq")}
              />
              <InputWithLabel
                select={true}
                title="Elke"
                name="interval"
                value={this.state.rule.interval}
                options={intervals}
                onSelect={this.onSelect.bind(this, "interval")}
              />
            </div>
            {this.state.rule.freq === RRule.WEEKLY && (
              <div className="recurrence-popupFormRow">
                <div className="recurrence-days">
                  <InputLabel content="Op" />
                  <div className="daysContainer">{this.renderDays()}</div>
                </div>
              </div>
            )}
            <div className="recurrence-popupFormRow">
              <div className="recurrence-endRule">
                <InputLabel content="Einde" />
                <div className="radioButtonContainer">
                  <RadioInput
                    isChecked={this.state.forever}
                    toggleRadio={this.selectRadioButton.bind(this, "forever")}
                  />
                  <label>Nooit</label>
                </div>
                <div className="radioButtonContainer afterManyTimes">
                  <RadioInput
                    isChecked={this.state.hasCount}
                    toggleRadio={this.selectRadioButton.bind(this, "hasCount")}
                  />
                  <label>Na</label>
                  <TextInput
                    type="text"
                    name="count"
                    value={this.state.rule.count}
                    onInputChange={this.onInputChange.bind(this, "count")}
                  />
                  <span>keer</span>
                </div>
                <div className="radioButtonContainer">
                  <RadioInput
                    isChecked={this.state.hasUntil}
                    toggleRadio={this.selectRadioButton.bind(this, "hasUntil")}
                  />
                  <label>Op</label>
                  <DateInput
                    name="until"
                    value={this.state.rule.until}
                    onInputChange={this.onInputChange.bind(this, "until")}
                  />
                </div>
              </div>
            </div>
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default RecurrencePopup;
