import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './doughnut-chart.css';

class DoughnutChart extends Component {
  render() {
    return (
      <div className="doughnutChart">
        <div className='doughnutChart-container'>
          <Doughnut
            data={{
              labels: ['Red'],
              datasets: [
                {
                  data: [this.props.percentage, 1 - this.props.percentage],
                  backgroundColor: [
                    this.props.color,
                    'rgba(229, 240, 249, 1)',
                  ],
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
              circumference: 180,
              rotation: 270,
              hoverBorderColor: '#fff',
              cutout: 80,
            }}
          />
        </div>
        <div className='dougnutChart-label'>
          <span id='label-rating'>{this.props.label}</span>
          { this.props.subLabel.split("\r\n").map(label => 
            <span key={label}>{label}</span>
          )}
        </div>
      </div>
    );
  }
}

export default DoughnutChart;
