import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import BaseComponent from "../../app/base-component";
import Appcontext from "../../app/app-context";
import InputLabel from "../../app/input/label-input/label-input";
import TextInput from "../../app/input/text-input/text-input";
import Popup from "../../app/popup/popup";
import ApiClient from "../../api-client/api-client";
import AuthenticationPage from "../authentication-page";
import "./password-first-change-page.css";

class PasswordFirstChangePage extends BaseComponent {
  static contextType = Appcontext;

  constructor(props) {
    super(props);

    var params = new URLSearchParams(window.location.search);

    this.state = {
      email: params.get("email"),
      password: "",
      confirmPassword: "",
      oldPassword: "",
      requested: false,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      [key]: val,
    }));
  }

  resetPassword() {
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    const requiredPassword =
      /[A-Z]+/.test(password) && /[a-z]/.test(password) && password.length > 10;

    if (password !== confirmPassword) {
      return this.context.addNotification(
        "warning",
        "De ingevulde wachtwoorden komen niet met elkaar overeen"
      );
    } else if (!requiredPassword) {
      return this.context.addNotification(
        "warning",
        "Wachtwoord moet bestaan uit minimaal 10 tekens, één hoofdletter en één kleine letter"
      );
    } else {
      new ApiClient(this.context)
        .call("POST", "/v1/User/PasswordChange", {
          password: this.state.oldPassword,
          newPassword: this.state.password,
        })
        .then((result) => {
          if (result.ok) {
            this.setStateAsync((state) => ({
              requested: true,
            })).then(() =>
              this.context.addNotification(
                "success",
                "Nieuw wachtwoord is aangemaakt"
              )
            );
          } else {
            this.context.addNotification("error", "Er is een fout opgetreden");
          }
        });
    }
  }

  render() {
    return (
      <AuthenticationPage>
        {this.state.requested && <Redirect to="/login" />}
        <div className="passwordFirstChangePage">
          <Popup title="Nieuw wachtwoord">
            <div className="popup-section">
              <div className="popup-column">
                <div className="popup-inputContainer">
                  <InputLabel content="E-mailadres" />
                  <TextInput
                    value={this.state.email}
                    type="text"
                    name="email"
                    onInputChange={this.onInputChange.bind(this, "email")}
                  />
                </div>
                <div className="popup-inputContainer">
                  <InputLabel content="Oud wachtwoord" />
                  <TextInput
                    value={this.state.oldPassword}
                    type="password"
                    name="oldPassword"
                    onInputChange={this.onInputChange.bind(this, "oldPassword")}
                  />
                </div>
                <div className="popup-inputContainer">
                  <InputLabel content="Nieuw wachtwoord" />
                  <TextInput
                    value={this.state.password}
                    type="password"
                    name="password"
                    onInputChange={this.onInputChange.bind(this, "password")}
                  />
                </div>
                <div className="popup-inputContainer">
                  <InputLabel content="Bevestig nieuw wachtwoord" />
                  <TextInput
                    value={this.state.confirmPassword}
                    type="password"
                    name="confirmPassword"
                    onInputChange={this.onInputChange.bind(
                      this,
                      "confirmPassword"
                    )}
                  />
                </div>
              </div>
              <div className="popup-column">
                <div className="popup-buttonContainer">
                  <button
                    className="popup-popupButton"
                    onClick={this.resetPassword.bind(this)}
                  >
                    Verstuur
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </AuthenticationPage>
    );
  }
}

export default PasswordFirstChangePage;
