import JsonBigInt from "json-bigint";

class ApiClient {
  constructor(context) {
    this.context = context;
  }

  async handleUnauthenticated() {
    if (localStorage.getItem("staySignedIn") === "true") {
      let clientData = {
        client_id: this.context.settings.apiClient,
        client_secret: this.context.settings.apiSecret,
        refresh_token: localStorage.getItem("refreshToken"),
        grant_type: "refresh_token",
        scope: "offline_access",
      };

      return await fetch(this.context.settings.apiUrl + "/connect/token", {
        method: "POST",
        body: new URLSearchParams(clientData).toString(),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.json();
          }
          return this.context.logOut().then(() => {
            throw Error("API call aborted");
          });
        })
        .then((json) => {
          localStorage.setItem("accessToken", json["access_token"]);
          localStorage.setItem("refreshToken", json["refresh_token"]);
        });
    } else {
      return await this.context.logOut().then(() => {
        throw Error("API call aborted");
      });
    }
  }

  async call(
    method,
    uri,
    data = null,
    form = false,
    headers = {},
    multipartForm = false,
    queryParams = {}
  ) {
    var settings = {
      method: method,
      headers: {
        Accept: "application/json",
      },
    };

    if (this.context.user) {
      settings["headers"]["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
    }

    if (data) {
      if (form) {
        settings["headers"]["Content-Type"] =
          "application/x-www-form-urlencoded;charset=UTF-8";
        settings["body"] = new URLSearchParams(data).toString();
      } else if (multipartForm) {
        settings["body"] = data;
      } else {
        settings["headers"]["Content-Type"] = "application/json";
        settings["body"] = JSON.stringify(data);
      }
    }

    settings["headers"] = {
      ...settings.headers,
      ...headers,
    };

    return this.context.setLoading(true).then(() =>
      fetch(
        this.getUriWithParam(this.context.settings.apiUrl + uri, queryParams),
        settings
      )
        .then(async (response) => {
          if (response.status === 401 && !form) {
            return this.handleUnauthenticated()
              .then(() => this.call(...arguments))
              .catch((e) => ({
                status: 401,
                ok: false,
                json: {},
              }));
          }

          let json = await response
            .text()
            .then(JsonBigInt.parse)
            .catch((e) => (!response.ok ? e : {}));

          if (json.errors) {
            Object.values(json.errors)
              .flatMap((e) => e)
              .filter((v, i, s) => s.indexOf(v) === i)
              .forEach((e) => {
                console.log(e);
                // this.context.addNotification("error", e)
              });
            }
            
            if (json.error) {
              console.log(json.error);
              if(json.error === 'invalid_request'){
                this.context.addNotification('error', 'Onjuiste gebruikersnaam of wachtwoord')
              } else {
                this.context.addNotification('error', json.error_description)   
              }
          }

          return {
            status: response.status,
            ok: response.ok,
            json: json,
          };
        })
        .finally(() => {
          this.context.setLoading(false);
        })
    );
  }

  getUriWithParam(baseUrl, params) {
    const Url = new URL(baseUrl);
    const urlParams = new URLSearchParams(Url.search);
    for (const key in params) {
      if (params[key] !== undefined) {
        urlParams.set(key, params[key]);
      }
    }
    Url.search = urlParams.toString();
    return Url.toString();
  }
}

export default ApiClient;
