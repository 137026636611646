import React, { Component } from 'react';
import Notification from "./notification";
import './notification-container.css';

class NotificationContainer extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      notifications: [],
      notificationIncrement: 1,
    };
  }

  componentDidMount() {
    this.tickInterval = setInterval(this.tick.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  addNotification(severity, message, time) {
    var notification = {
      message: message,
      severity: severity,
      time: time,
      timeRemaining: time,
      key: this.state.notificationIncrement,
    };

    this.setState(state => ({
      notifications: [...state.notifications, notification],
      notificationIncrement: state.notificationIncrement + 1
    }));
  }

  removeNotification(key) {
    this.setState(state => ({
      notifications: state.notifications.filter(n => n.key !== key)
    }));
  }

  tick() {
    var notifications = this.state.notifications.map(notification => {
      notification = { ...notification };
      notification.timeRemaining -= 1;
      return notification;
    });

    this.setState(state => ({
      notifications: notifications.filter(n => n.timeRemaining >= 0)
    }));
  }

  render() {
    return (
      <div className="notificationContainer">
        {this.state.notifications.map(notification => (
          <Notification 
            notification={notification}
            key={notification.key}
            removeNotification={this.removeNotification.bind(this)}
          />
        ))}
      </div>
    )
  }
}

export default NotificationContainer;