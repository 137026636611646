import React, { Component } from 'react';
import './verticalbar-chart.css';

class VerticalbarChart extends Component {
  render() {
    return (
      <div className='verticalbar'>
        <div className='verticalbar-container'>
          {this.props.stats && this.props.stats.map(stat => {
            let percentage = stat.percentage ? stat.percentage + '%' : '';
            return (
              <div
                className='verticalbar-item'
                style={{ width: percentage }}
                key={stat.tag}
              >
                <div className='verticalbar-meter'>
                  <span>
                    <span
                      className='progress'
                      style={{ backgroundColor: stat.color }}
                    ></span>
                  </span>
                </div>
                <span className='verticalbar-amountLabel'>{percentage}</span>
                <span className='verticalbar-rangeLabel'>{stat.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default VerticalbarChart;
