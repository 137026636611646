import React, { Fragment } from "react";
import { Redirect } from "react-router";
import moment from "moment";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Topbar from "../../app/topbar/topbar";
import DashboardFilter from "./dashboard-filter";
import Sidebar from "../../app/sidebar/sidebar";
import DashboardStatistics from "./dashboard-statistics";
import "./dashboard-page.css";

class DashboardPage extends BaseComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      statistics: null,
      filters: {
        date: {
          from: moment().startOf("year").format('YYYY-MM-DD'),
          until: moment().endOf("month").format('YYYY-MM-DD')
        }
      },
    };
  }

  componentDidMount() {
    this.getStatistics();
  }

  getStatistics() {
    if(this.context.user && this.context.user.role === 'Trainer') {
      return this.setState({
        statistics: [],
      }); 
    }
    
    this.setState({
      statistics: null,
    });

    var params = {};

    if (this.state.filters.date) {
      if (this.state.filters.date.from) {
        params["Start"] = moment(this.state.filters.date.from).toISOString();
      }
      if (this.state.filters.date.until) {
        params["End"] = moment(this.state.filters.date.until).add('1', 'days').toISOString();
      }
    }

    if (this.state.filters.trainers) {
      params["Trainers"] =
        this.state.filters.trainers.length > 0
          ? this.state.filters.trainers.join(",")
          : "00000000-0000-0000-0000-000000000000";
    }

    return new ApiClient(this.context)
      .call("GET", "/v1/Statistics/Dashboard", null, false, {}, false, params)
      .then((response) => {
        let data = response.json;

        return this.setStateAsync((state) => ({
          statistics: data,
        }));
      });
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getStatistics();
    });
  }

  render() {
    return (
      <div className="dashboardPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar expanded={true} />

        <div className="dashboardPage-container">
          <Topbar title="Dashboard" />

          <div className="dashboardPage-mainContent">
            {this.context.user && this.context.user.role === 'Trainer'  
              ? <div className="dashboardPage-trainerRole">Statistieken zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  <DashboardFilter
                    initialFilters={this.state.filters} 
                    updateFilter={this.updateFilter.bind(this)}
                  />

                  <div className="dashboardPage-statisticsContainer">
                    <DashboardStatistics
                      statistics={this.state.statistics}
                      filters={this.state.filters}
                    />
                  </div>
                </Fragment>}
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardPage;
