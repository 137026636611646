import React, { Fragment } from "react";
import { Redirect } from "react-router";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import BaseComponent from "../../app/base-component";
import Topbar from "../../app/topbar/topbar";
import AddButton from "../../app/add-button/add-button";
import EditButton from "../../app/edit-button/edit-button";
import Sidebar from "../../app/sidebar/sidebar";
import Table from "../../app/table/table";
import ProductsPopup from "./products-popup";
import "./products-page.css";

class ProductsPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      products: null,
      product: {},
      sortQuery: null,
      filterQuery: null,
      productPopupShown: false,
      selectedProduct: false,
      userRole: context.user && context.user.role,
      pagination: {},
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts(page = 1, pageSize = null) {
    if(this.context.user && this.state.userRole === 'Trainer') {
      return this.setState({
        products: [],
      }); 
    }
    
    pageSize = pageSize || this.state.pagination.pageSize || 25;

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.state.filterQuery || "";

    new ApiClient(this.context)
      .call("GET", "/v1/Product", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        let data = response.json;

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        this.setState((state) => ({
          products: data.results,
          pagination: pagination
        }));
      });
  }

  toggleProductPopup() {
    this.setState((state) => ({
      productPopupShown: !state.productPopupShown,
      selectedProduct: null,
    }));
  }

  toggleEditProductPopup(product) {
    this.setState((state) => ({
      productPopupShown: !state.productPopupShown,
      selectedProduct: product,
    }));
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getProducts.bind(this));
  }

  updateFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getProducts.bind(this));
  }

  mapDataToTable() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    return this.state.products && this.state.products.map((product) => ({
      key: product.id,
      name: product.name,
      price: "€ " + product.price.toFixed(2).replace(".", ","),
      duration: product.duration + " minuten",
      billable: product.billable ? "Ja" : "Nee",
      active: product.active === true ? "Actief" : "Inactief",
      edit: admin && (
        <EditButton onClick={this.toggleEditProductPopup.bind(this, product)} />
      ),
    }));
  }

  updatePagination(page, pageSize) {
    this.getProducts(page, pageSize);
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    return (
      <div className="productsPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="productsPage-container">
          <div className="topbar">
            <Topbar
              title="Diensten"
              button={admin && (
                <AddButton
                  actions={[
                    { label: "Add", handler: this.toggleProductPopup.bind(this) },
                  ]}
                />
                ) 
              }
            />
          </div>

          <div className="productsPage-mainContent">
            {!admin 
              ? <div className="productsPage-trainerRole">Producten zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  {this.state.productPopupShown && (
                    <ProductsPopup
                      product={this.state.selectedProduct}
                      refreshList={this.getProducts.bind(this)}
                      onDismiss={this.toggleProductPopup.bind(this)}
                    />
                  )}

                  <Table
                    pagination={{
                      ...this.state.pagination, 
                      onPageChange: this.updatePagination.bind(this),
                    }}
                    filterQuery={this.state.filterQuery}
                    updateFilter={this.updateFilter.bind(this)}
                    sortQuery={this.state.sortQuery}
                    updateSort={this.updateSort.bind(this)}
                    headers={[
                      {
                        title: "Naam",
                        property: "name",
                        filterKey: "Name",
                      },
                      {
                        title: "Standaard verkoop",
                        property: "price",
                        filterKey: "Price",
                        filterable: false,
                      },
                      {
                        title: "Standaard tijdsduur",
                        property: "duration",
                        filterKey: "Duration",
                        filterable: false,
                      },
                      {
                        title: "Facturabel",
                        property: "billable",
                        filterKey: "Billable",
                        filterable: false,
                      },
                      {
                        title: "Actief",
                        property: "active",
                        filterKey: "active",
                        filterable: false,
                      },
                      { 
                        property: "edit" 
                      }
                    ]}
                    data={this.mapDataToTable()}
                  />
                </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsPage;
