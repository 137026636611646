import React, { Component } from "react";
import "./select.css";

class Select extends Component {
  onChange(e) {
    let val = JSON.parse(e.target.value);
    this.props.onSelect(val);
  }

  componentDidMount() {
    if (!this.props.value && this.props.options[0]) {
      this.props.onSelect(this.props.options[0].value);
    }
  }

  componentDidUpdate(prevProps) {
    var values = this.props.options.map(o => o.value);
    
    if(!values.includes(this.props.value)) {
      if(this.props.options[0]) {
        this.props.onSelect(this.props.options[0].value);
      } else if (this.props.value !== "") {
        this.props.onSelect("");
      }
    }
  }

  render() {
    return (
      <select
        name={this.props.name}
        value={JSON.stringify(this.props.value)}
        onChange={this.onChange.bind(this)}
        disabled={this.props.disabled}
      >
        {this.props.options.map((opt) => (
          <option
            key={opt.id || opt.label}
            value={JSON.stringify(opt.value)}
            disabled={opt.disabled}
          >
            {opt.label}
          </option>
        ))}
      </select>
    );
  }
}

export default Select;
