import React, { Component } from "react";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import InputWithLabel from "../../app/input/input-with-label";
import PopupOverlay from "../../app/popup/popup-overlay";
import Checkbox from "../../app/input/checkbox/checkbox";

const billable = [
  {
    label: "Ja",
    value: "true",
  },
  {
    label: "Nee",
    value: "false",
  },
];

class ProductsPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var defaultProduct = {
      name: "",
      price: "",
      duration: 0,
      trainingType: "",
      billable: "false",
      active: false,
    };
    if (props.product) {
      defaultProduct = {
        id: props.product.id,
        name: props.product.name,
        price: props.product.price.toFixed(2).replace(".", ","),
        duration: props.product.duration,
        billable: String(props.product.billable),
        trainingType: props.product.trainingType,
        active: props.product.active,
      };
    }
    this.state = {
      product: defaultProduct,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      product: { ...state.product, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      product: { ...state.product, [key]: val },
    }));
  }

  toggleProductStatus(key, val) {
    this.setState((state) => ({
      product: {
        ...state.product,
        active: !state.product.active,
      },
    }));
  }

  onSubmit() {
    if (this.props.product) {
      this.updateProduct();
    } else {
      this.createProduct();
    }
  }

  updateProduct() {
    let product = this.state.product;
    let priceString = product.price.replace(",", ".");
    let price = parseFloat(priceString);

    let updatedProduct = {
      ...product,
      price: price,
      billable: product.billable === "true",
    };

    new ApiClient(this.context)
      .call("PUT", "/v1/Product/" + product.id, updatedProduct)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.refreshList();
          this.context.addNotification("success", "Product is aangepast");
        } else {
          throw result;
        }
      });
  }

  createProduct() {
    let product = this.state.product;
    let priceString = product.price.replace(",", ".");
    let price = parseFloat(priceString);

    let newProduct = {
      ...this.state.product,
      price: price,
      billable: product.billable === "true",
    };

    new ApiClient(this.context)
      .call("POST", "/v1/Product", newProduct)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.refreshList();
          this.context.addNotification("success", "Product is aangemaakt");
        } else {
          throw result;
        }
      });
  }

  cancelProduct() {
    new ApiClient(this.context)
      .call("DELETE", "/v1/Product/" + this.state.product.id)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.refreshList();
        } else {
          throw result;
        }
      });
  }

  render() {
    return (
      <div className="products-popup">
        <PopupOverlay
          actions={[
            // {
            //   label: 'Dienst verwijderen',
            //   handler: this.cancelProduct.bind(this),
            // },
            {
              label: this.props.product ? "Dienst bewerken" : "Dienst aanmaken",
              handler: this.onSubmit.bind(this),
              submit: true,
            },
          ]}
          title={this.props.product ? "Dienst bewerken" : "Dienst aanmaken"}
          onDismiss={this.props.onDismiss}
        >
          <div className="products-popupFormContainer">
            <InputWithLabel
              text={true}
              title="Naam"
              value={this.state.product.name}
              name="name"
              onInputChange={this.onInputChange.bind(this, "name")}
            />

            <div className="products-popupFormRow">
              <InputWithLabel
                text={true}
                title="Standaard prijs"
                value={this.state.product.price}
                name="price"
                onInputChange={this.onInputChange.bind(this, "price")}
              />
              <InputWithLabel
                select={true}
                title="Facturabel"
                value={this.state.product.billable}
                name="billable"
                options={billable}
                onSelect={this.onSelect.bind(this, "billable")}
              />
            </div>

            <div className="products-popupFormRow">
              <InputWithLabel
                select={true}
                title="Standaard tijdsduur"
                options={[
                  { value: 30, label: "30 minuten" },
                  { value: 60, label: "60 minuten" },
                  { value: 90, label: "90 minuten" },
                ]}
                value={this.state.product.duration}
                name="duration"
                onSelect={this.onSelect.bind(this, "duration")}
              />
              <InputWithLabel
                select={true}
                title="Training type"
                options={[
                  { value: "SOLO", label: "Solo" },
                  { value: "GROUP", label: "Groep" },
                ]}
                value={this.state.product.trainingType}
                name="trainingType"
                onSelect={this.onSelect.bind(this, "trainingType")}
              />
            </div>
            <div className="products-popupFormRow">
              <div className="products-status">
                <span className="statusLabel">Status</span>
                <Checkbox
                  key={"active"}
                  id={"active"}
                  label="Actief"
                  val={this.state.product.active}
                  onToggle={this.toggleProductStatus.bind(this, "active")}
                />
              </div>
            </div>
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default ProductsPopup;
