import React, { Component } from 'react';
import './checkbox.css';

class Checkbox extends Component {
  onChange(e){
    this.props.onToggle([{
      key: this.props.id, 
      val: e.target.checked,
    }]);
  }

  onClick(e) {
    if(e.target.matches('.checkbox-label') && this.props.onClick) {
      this.props.onClick();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  render() {
    let disabled = !!this.props.disabled;
    let rawVal = disabled ? 'mixed' : this.props.val;
    let val = rawVal !== false;
    const type = typeof rawVal === 'boolean' ? 'checkmark' : 'squared';

    return (
      <label className='checkboxContainer' htmlFor={this.props.id} onClick={this.onClick.bind(this)}>
        <div className='checkbox-label'>{this.props.label}</div>
        <input
          type='checkbox'
          id={this.props.id}
          name={this.props.id}
          onChange={this.onChange.bind(this)}
          disabled={disabled}
          checked={val}
        />
        <span className={type}></span>
      </label>
    );
  }
}

export default Checkbox;
