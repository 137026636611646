import React, { Component } from 'react';
import './badge.css';

class Badge extends Component {
  render() {
    return (
      <input
        className='badge'
        type='text'
        style={{backgroundColor: this.props.backgroundColor}}
        name={this.props.id}
        value={this.props.value}
        disabled
      />
    );
  }
}

export default Badge;
