import React from "react";
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import FilterBar from "../../app/filterbar/filterbar";
import BaseFilterbar from "../../app/filterbar/base-filterbar";
class TrainersFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      gyms: [],
    };
  }

  componentDidMount() {
    this.getGyms();
  }

  render() {
    return (
      <div className="trainersFilter">
        <FilterBar>
          <FilterbarCheckboxGroup
            title="Gyms"
            allTitle="Alle gyms"
            options={this.state.gyms}
            onUpdate={this.updateCheckboxFilter.bind(this, "gyms")}
          />
        </FilterBar>
      </div>
    );
  }
}

export default TrainersFilter;
