import React, { Component } from 'react';
import './slider-popup-card.css';
class SliderPopupCard extends Component {
  render() {
    return (
      <div className='sliderPopup-cardContainer' id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}

export default SliderPopupCard;
