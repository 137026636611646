import React, { Component } from 'react';
import './category-badge.css';

class CategoryBadge extends Component {
  
  render() {
    return (
      <div className="categoryBadge">
        <this.props.icon color={'var(--color-blue-700)'} />
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default CategoryBadge;
