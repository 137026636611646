import React, { Component } from "react";
import InputLabel from "./label-input/label-input";
import SelectGroup from "./select/select-group";
import TextInput from "./text-input/text-input";
import DateInput from "./date-input/date-input";
import "./input-with-label.css";

class InputWithLabel extends Component {
  render() {
    return (
      <div className="inputContainer">
        <InputLabel content={this.props.title} />
        {this.props.select && (
          <SelectGroup
            options={this.props.options}
            onSelect={this.props.onSelect}
            value={this.props.value}
            name={this.props.name}
            disabled={this.props.disabled}
          />
        )}
        {this.props.text && (
          <TextInput
            type="text"
            name={this.props.name}
            value={this.props.value}
            onInputChange={this.props.onInputChange}
            onBlur={this.props.onBlur}
            disabled={this.props.disabled}
          />
        )}
        {this.props.date && (
          <DateInput
            name={this.props.name}
            value={this.props.value}
            onInputChange={this.props.onInputChange}
            onBlur={this.props.onBlur}
          />
        )}
      </div>
    );
  }
}

export default InputWithLabel;
