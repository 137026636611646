import React from "react";
import moment from "moment-timezone";
import { ArrowLeft, ArrowRight } from "react-feather";
import BaseComponent from "../../../app/base-component";
import DateInput from "../../../app/input/date-input/date-input";
import OutlineButton from "../../../app/outline-button/outline-button";
import "./calendar-controls.css";

class CalendarControls extends BaseComponent {
  setToday() {
    this.props.setDate(moment());
  }

  setNextDay() {
    this.props.setDate(this.props.currentDate.add(1, "days"));
  }

  setPrevDay() {
    this.props.setDate(this.props.currentDate.subtract(1, "days"));
  }

  setDay(val) {
    this.props.setDate(moment(val));
  }

  render() {
    let date = this.props.currentDate.format("YYYY-MM-DD");
    return (
      <div className="calendarControls">
        <div className="calendarControls-container">
          <div className="calendarControls-today">
            <OutlineButton
              actions={[
                { label: "vandaag", handler: this.setToday.bind(this) },
              ]}
            />
          </div>
          <div className="calendarControls-slide">
            <OutlineButton
              actions={[
                { label: <ArrowLeft />, handler: this.setPrevDay.bind(this) },
              ]}
            />
            <OutlineButton
              actions={[
                { label: <ArrowRight />, handler: this.setNextDay.bind(this) },
              ]}
            />
          </div>
          <div className="calendarControls-date">
            <DateInput
              name="date"
              value={date}
              onInputChange={this.setDay.bind(this)}
            />
          </div>
          <div className="calendarControls-views">
            <OutlineButton actions={[{ label: "dag" }]} />
            <OutlineButton actions={[{ label: "week", disabled: true }]} />
            <OutlineButton actions={[{ label: "maand", disabled: true }]} />
            <OutlineButton actions={[{ label: "lijst", disabled: true }]} />
          </div>
          <div className="calendarControls-search">
            <OutlineButton
              actions={[
                {
                  handler: this.props.toggleSearchModal,
                  label: (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="#2B6A93"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.9999 20.9999L16.6499 16.6499"
                        stroke="#2B6A93"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CalendarControls;
