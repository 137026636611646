import React, { Component } from "react";
import "./text-input.css";

class TextInput extends Component {
  onInputChange(e) {
    e.preventDefault();
    let val = e.target.value;
    this.props.onInputChange(val);
  }

  render() {
    return (
      <input
        className={this.props.disabled ? "input disabled" : "input"}
        type={this.props.type}
        name={this.props.name}
        value={this.props.value}
        onChange={this.onInputChange.bind(this)}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
      />
    );
  }
}

export default TextInput;
