export const statuses = [
  {
    label: 'Open',
    value: 0,
  },
  {
    label: 'Gepland',
    value: 1,
  },
  {
    label: 'Verstuurd',
    value: 2,
  },
  {
    label: 'Betaald',
    value: 3,
  },
  {
    label: 'Achterstallig',
    value: 4,
  },
  {
    label: 'Herinnerd',
    value: 5,
  },
  {
    label: 'Oninbaar',
    value: 6,
  },
];
