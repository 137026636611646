import React, { Fragment } from "react";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import Topbar from "../../app/topbar/topbar";
import AddButton from "../../app/add-button/add-button";
import EditButton from "../../app/edit-button/edit-button";
import Sidebar from "../../app/sidebar/sidebar";
import ClientsPopup from "./clients-popup/clients-popup";
import EditClientPopup from "./edit-client-popup/edit-client-popup";
import ClientWeightlogsPopup from "./client-weightlogs-popup/client-weightlogs-popup";
import Table from "../../app/table/table";
import BaseComponent from "../../app/base-component";
import ClientFilter from "./clients-filter";
import "./clients-page.css";
import DotsButton from "../../app/dots-button/dots-button";

class ClientsPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var params = props.location.state || {};

    this.state = {
      clients: null,
      client: {
        birthdate: "",
      },
      gyms: [],
      trainers: [],
      products: [],
      sortQuery: null,
      filterQuery: null,
      filters: params.filters || {},
      clientsPopupShown: false,
      clientEditPopupShown: false,
      pagination: {},
    };
  }

  componentDidMount() {
    this.getClients();
    this.getGyms();
    this.getTrainers();
    this.getProducts();
  }

  getGyms() {
    new ApiClient(this.context).call("GET", "/v1/Gym/").then((response) => {
      let data = response.json;

      this.setState({
        gyms: data.results,
      });
    });
  }

  getTrainers() {
    new ApiClient(this.context).call("GET", "/v1/Trainer").then((response) => {
      let data = response.json;

      this.setState({
        trainers: data.results,
      });
    });
  }

  getProducts() {
    new ApiClient(this.context).call("GET", "/v1/Product").then((response) => {
      let data = response.json;

      this.setState({
        products: data.results,
      });
    });
  }

  getClients(page = null, pageSize = null) {
    pageSize = pageSize || this.state.pagination.pageSize || 25;
    page = page || this.state.pagination.currentPage || 1;

    this.setState({
      clients: null,
    });

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      statuses: {
        filterKey: "Active",
        type: "select",
        noneFilter: "GymName==-1",
      },
      trainers: {
        filterKey: "Trainer",
        type: "select",
      },
    });

    if (this.context.user && this.context.user.role === "Trainer") {
      return new ApiClient(this.context)
        .call("GET", "/v1/Client", null, false, {}, false, {
          Sorts: sortQuery,
          Filters: `ShouldValidateImportedData==false,${filterQuery},TrainerName==${this.context.user.fitUser.name}`,
          Page: page,
          PageSize: pageSize,
        })
        .then((response) => {
          if (!response.ok) return;

          let data = response.json;

          const clientsFromTrainer = data.results.map((client) => ({
            ...client,
            fitUser: {
              ...client.fitUser,
              birthdate: moment(client.fitUser.birthdate).format("YYYY-MM-DD"),
            },
          }));
          const pagination = {
            currentPage: data.currentPage,
            pageCount: data.pageCount,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
          };
          return this.setStateAsync((state) => ({
            clients: clientsFromTrainer,
            pagination: pagination,
          }));
        });
    } else {
      return new ApiClient(this.context)
        .call("GET", "/v1/Client", null, false, {}, false, {
          Sorts: sortQuery,
          Filters: `ShouldValidateImportedData==false,${filterQuery}`,
          Page: page,
          PageSize: pageSize,
        })
        .then((response) => {
          if (!response.ok) return;

          let data = response.json;

          const clients = data.results.map((client) => ({
            ...client,
            fitUser: {
              ...client.fitUser,
              birthdate: moment(client.fitUser.birthdate).format("YYYY-MM-DD"),
            },
          }));
          const pagination = {
            currentPage: data.currentPage,
            pageCount: data.pageCount,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
          };
          return this.setStateAsync((state) => ({
            clients: clients,
            pagination: pagination,
          }));
        });
    }
  }

  toggleClientsPopup() {
    this.setState((state) => ({
      clientsPopupShown: !state.clientsPopupShown,
    }));
  }

  toggleClientEditPopup(client) {
    this.setState((state) => ({
      clientEditPopupShown: !state.clientEditPopupShown,
      client: client,
    }));
  }

  toggleWeightLogsPopup(client) {
    this.setState((state) => ({
      weightLogsPopupShown: !state.weightLogsPopupShown,
      client: client,
    }));
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getClients.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getClients.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getClients(1);
    });
  }

  sortArrayOnDate(array, key = "date") {
    return array.sort((a, b) => moment(a[key]).diff(moment(b[key])));
  }

  mapProgressionCircle(string, isLarge) {
    let result = string.toLowerCase();
    let size = isLarge ? "large" : "";

    switch (string) {
      case "GREEN":
        return <div className={`progression ${size} ${result}`}></div>;
      case "ORANGE":
        return <div className={`progression ${size} ${result}`}></div>;
      case "RED":
        return <div className={`progression ${size} ${result}`}></div>;

      default:
        return null;
    }
  }

  renderWeekMetrics(weekMetrics) {
    let sortedMetrics = this.sortArrayOnDate(weekMetrics);

    if (!weekMetrics || weekMetrics.length === 0) {
      sortedMetrics = [{ weekResult: "" }];
    }

    return (
      <Fragment>
        {this.mapProgressionCircle(
          sortedMetrics.length > 3
            ? sortedMetrics[sortedMetrics.length - 4].weekResult
            : ""
        )}
        {this.mapProgressionCircle(
          sortedMetrics.length > 2
            ? sortedMetrics[sortedMetrics.length - 3].weekResult
            : ""
        )}
        {this.mapProgressionCircle(
          sortedMetrics.length > 1
            ? sortedMetrics[sortedMetrics.length - 2].weekResult
            : ""
        )}
        {this.mapProgressionCircle(
          sortedMetrics[sortedMetrics.length - 1].weekResult
            ? sortedMetrics[sortedMetrics.length - 1].weekResult
            : ""
        )}
        {this.mapProgressionCircle("")}
      </Fragment>
    );
  }

  renderProgression(weekMetrics) {
    let sortedMetrics = this.sortArrayOnDate(weekMetrics);
    if (!weekMetrics || weekMetrics.length === 0) {
      sortedMetrics = [{ weekResult: "" }];
    }

    return (
      <Fragment>
        {this.mapProgressionCircle(
          sortedMetrics[sortedMetrics.length - 1].weekResult
            ? sortedMetrics[sortedMetrics.length - 1].weekResult
            : "",
          true
        )}
      </Fragment>
    );
  }

  renderStack(text1, text2) {
    return (
      <div className="stackContainer">
        <div className="text">{text1}</div>
        <div className="verticalLine"></div>
        <div className="text">{text2}</div>
      </div>
    );
  }

  mapDataToTable() {
    return (
      this.state.clients &&
      this.state.clients.map((client) => ({
        key: client.id,
        name: client.fitUser.firstName + " " + client.fitUser.lastName,
        clientStatus: client.active ? "Actief" : "Inactief",
        lastFourWeeks: this.renderWeekMetrics(client.weekMetrics),
        progression: this.renderProgression(client.weekMetrics),
        calories: this.renderStack(
          client.calories === 0 ? "-" : client.calories,
          client.currentCalories === 0 ? "-" : client.currentCalories
        ),
        viewWeightLogs: (
          <DotsButton onClick={this.toggleWeightLogsPopup.bind(this, client)} />
        ),
        weight: this.renderStack(
          client.clientGoal
            ? client.clientGoal.startingWeight.toLocaleString()
            : client.weight === 0
            ? "-"
            : client.weight.toLocaleString(),
          client.weightMetrics && client.weightMetrics.length > 0
            ? client.weightMetrics
                .sort((a, b) => moment(a.date).diff(moment(b.date)))
                [client.weightMetrics.length - 1].weight.toLocaleString()
            : "-"
        ),
        trainer: client.trainer
          ? client.trainer.firstName + " " + client.trainer.lastName
          : "",
        gym: client.gym ? client.gym.city : "",
        email: client.fitUser.email,
        phoneNumber: client.fitUser.phoneNumber,
        edit: (
          <EditButton onClick={this.toggleClientEditPopup.bind(this, client)} />
        ),
      }))
    );
  }

  updatePagination(page, pageSize) {
    this.getClients(page, pageSize);
  }

  render() {
    return (
      <div className="clientsPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="clientsPage-container">
          <Topbar
            title="Klanten"
            button={
              <AddButton
                actions={[
                  {
                    label: "Add",
                    handler: this.toggleClientsPopup.bind(this),
                  },
                ]}
              />
            }
          />

          <div className="clientsPage-mainContent">
            <ClientFilter
              initialFilters={this.state.filters}
              updateFilter={this.updateFilter.bind(this)}
            />
            <Table
              pagination={{
                ...this.state.pagination,
                onPageChange: this.updatePagination.bind(this),
              }}
              filterQuery={this.state.filterQuery}
              updateFilter={this.updateTableFilter.bind(this)}
              sortQuery={this.state.sortQuery}
              updateSort={this.updateSort.bind(this)}
              headers={[
                {
                  title: "Naam",
                  property: "name",
                  filterKey: "Name",
                },
                {
                  title: "Laatste 4 wk",
                  property: "lastFourWeeks",
                  filterKey: "lastFourWeeks",
                  filterable: false,
                },
                {
                  title: "Progressie",
                  property: "progression",
                  filterKey: "progression",
                  filterable: false,
                },
                {
                  title: "Calorieën",
                  property: "calories",
                  filterable: false,
                },
                {
                  title: "Gewicht",
                  property: "weight",
                  filterable: false,
                },
                {
                  property: "viewWeightLogs",
                },
                {
                  title: "Trainer",
                  property: "trainer",
                  filterKey: "TrainerName",
                },
                {
                  title: "Gym",
                  property: "gym",
                  filterKey: "GymLocation",
                  filterable: false,
                },
                {
                  title: "E-mail",
                  property: "email",
                  filterKey: "Email",
                },
                {
                  title: "Telefoon",
                  property: "phoneNumber",
                  filterKey: "PhoneNumber",
                },
                {
                  title: "Klant status",
                  property: "clientStatus",
                  filterKey: "Active",
                  filterable: false,
                },
                { property: "edit" },
              ]}
              data={this.mapDataToTable()}
            />

            {this.state.clientEditPopupShown && (
              <EditClientPopup
                products={this.state.products}
                gyms={this.state.gyms}
                client={this.state.client}
                trainers={this.state.trainers}
                onDismiss={this.toggleClientEditPopup.bind(this)}
                refreshList={this.getClients.bind(this)}
              />
            )}

            {this.state.clientsPopupShown && (
              <ClientsPopup
                refreshList={this.getClients.bind(this)}
                gyms={this.state.gyms}
                trainers={this.state.trainers}
                toggleClientsPopup={this.toggleClientsPopup.bind(this)}
                onDismiss={this.toggleClientsPopup.bind(this)}
              />
            )}

            {this.state.weightLogsPopupShown && (
              <ClientWeightlogsPopup
                refreshList={this.getClients.bind(this)}
                client={this.state.client}
                onDismiss={this.toggleWeightLogsPopup.bind(this)}
              ></ClientWeightlogsPopup>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientsPage);
