import React, { Component } from "react";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import InputWithLabel from "../../../app/input/input-with-label";
import PopupOverlay from "../../../app/popup/popup-overlay";
import "./moneybird-popup.css";

class MoneybirdPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      loaderShown: false,
      isLoggedIn: false,
      administrations: [],
      documentStyles: [],
      taxRates: [],
      ledgerAccounts: [],
      workflows: [],
      administration: "default",
      documentStyle: "default",
      taxRate: "default",
      ledgerAccount: "default",
      workflow: "default",
      administrationSelected: false,
    };
  }

  componentDidMount() {
    this.setUp();
  }

  setUp() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/status/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok) {
          this.setState({
            isLoggedIn: result.json.isLoggedIn,
          });
          if (result.json.isLoggedIn) {
            this.getAdministrationsFromAPI();
          }
        }
      });
  }

  getAdministrationsFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/administrations/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok) {
          let selected = "default";
          let apiId = "def";
          let results = result.json.map((a) => {
            if (a.active) {
              selected = a.id;
              apiId = a.contactCustomAPIField;
              this.getDocumentStylesFromAPI();
              this.getTaxRatesFromAPI();
              this.getLedgerAccountsFromAPI();
              this.getWorkflowsFromAPI();
            }

            return {
              value: a.id,
              label: a.name,
              apiId: a.contactCustomAPIField,
            };
          });
          this.setState({
            administrations: [
              { value: "default", label: "Kies een waarde", disabled: true },
              ...results,
            ],
            apiId: apiId,
            administration: selected,
            administrationSelected: selected !== "default",
          });
        }
      });
  }
  getDocumentStylesFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/document-styles/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok && Array.isArray(result.json)) {
          let selected = "default";
          let results = result.json.map((d) => {
            if (d.active) {
              selected = d.id;
            }
            return { value: d.id, label: d.name };
          });
          this.setState({
            documentStyles: [
              { value: "default", label: "Kies een waarde", disabled: true },
              ...results,
            ],
            documentStyle: selected,
          });
        }
      });
  }
  getTaxRatesFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/tax-rates/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok && Array.isArray(result.json)) {
          let selected = "default";
          let results = result.json
            .filter((t) => t.type === "sales_invoice")
            .map((t) => {
              if (t.active) {
                selected = t.id;
              }
              return { value: t.id, label: t.name };
            });
          this.setState({
            taxRates: [
              { value: "default", label: "Kies een waarde", disabled: true },
              ...results,
            ],
            taxRate: selected,
          });
        }
      });
  }
  getLedgerAccountsFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/ledger-accounts/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok && Array.isArray(result.json)) {
          let selected = "default";
          let results = result.json.map((l) => {
            if (l.active) {
              selected = l.id;
            }
            return { value: l.id, label: l.name };
          });
          this.setState({
            ledgerAccounts: [
              { value: "default", label: "Kies een waarde", disabled: true },
              ...results,
            ],
            ledgerAccount: selected,
          });
        }
      });
  }
  getWorkflowsFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/workflows/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok && Array.isArray(result.json)) {
          let selected = "default";
          let results = result.json
            .filter((w) => w.type === "InvoiceWorkflow")
            .map((w) => {
              if (w.active) {
                selected = w.id;
              }
              return { value: w.id, label: w.name };
            });
          this.setState({
            workflows: [
              { value: "default", label: "Kies een waarde", disabled: true },
              ...results,
            ],
            workflow: selected,
          });
        }
      });
  }

  onSelect(key, val) {
    this.setState({
      [key]: val,
    });
  }

  importClients() {
    this.setState({
      loaderShown: true,
    });
    new ApiClient(this.context)
      .call("GET", "/v1/MoneyBird/clients/gym/" + this.props.gym.id)
      .then((result) => {
        if (result.ok) {
          this.context.addNotification("success", "Klanten zijn geimporteerd");
        } else {
          this.context.addNotification(
            "success",
            "Er is iets misgegaan, klanten zijn niet geimporteerd"
          );
        }
        this.setState({
          loaderShown: false,
        });
      });
  }
  toggleLogin() {
    if (this.state.isLoggedIn) {
      new ApiClient(this.context)
        .call("DELETE", "/v1/MoneyBird/logout/gym/" + this.props.gym.id)
        .then((result) => {
          this.setUp();
        });
    } else {
      new ApiClient(this.context)
        .call("GET", "/v1/MoneyBird/login/gym/" + this.props.gym.id)
        .then((result) => {
          if (result.ok) {
            window.location.href = result.json.redirectTo;
          }
        });
    }
  }
  saveAdministration() {
    if (this.state.administration !== "default") {
      new ApiClient(this.context)
        .call(
          "PUT",
          "/v1/MoneyBird/administration/" +
            this.state.administration +
            "/activate/gym/" +
            this.props.gym.id
        )
        .then((result) => {
          if (result.ok) {
            this.context.addNotification(
              "success",
              "De administratie is opgeslagen"
            );
            this.setUp();
          }
        });
    } else {
      this.context.addNotification(
        "error",
        "Kies een waarde voor de administratie a.u.b."
      );
    }
  }

  saveAll() {
    let allFilledIn = true;
    if (this.state.documentStyle === "default") {
      allFilledIn = false;
      this.context.addNotification(
        "error",
        "Kies een waarde voor de document stijl a.u.b."
      );
    }
    if (this.state.taxRate === "default") {
      allFilledIn = false;
      this.context.addNotification(
        "error",
        "Kies een waarde voor het BTW tarief a.u.b."
      );
    }
    if (this.state.ledgerAccount === "default") {
      allFilledIn = false;
      this.context.addNotification(
        "error",
        "Kies een waarde voor de grootboekkaarten a.u.b."
      );
    }
    if (this.state.workflow === "default") {
      allFilledIn = false;
      this.context.addNotification(
        "error",
        "Kies een waarde voor de workflow a.u.b."
      );
    }
    if (allFilledIn) {
      const saveDocumentStyle = new ApiClient(this.context).call(
        "PUT",
        "/v1/MoneyBird/document-styles/" +
          this.state.documentStyle +
          "/activate/gym/" +
          this.props.gym.id
      );
      const saveTaxRate = new ApiClient(this.context).call(
        "PUT",
        "/v1/MoneyBird/tax-rates/" +
          this.state.taxRate +
          "/activate/gym/" +
          this.props.gym.id
      );

      const saveLedgerAccount = new ApiClient(this.context).call(
        "PUT",
        "/v1/MoneyBird/ledger-accounts/" +
          this.state.ledgerAccount +
          "/activate/gym/" +
          this.props.gym.id
      );

      const saveWorkflow = new ApiClient(this.context).call(
        "PUT",
        "/v1/MoneyBird/workflows/" +
          this.state.workflow +
          "/activate/gym/" +
          this.props.gym.id
      );

      const saveApiId = new ApiClient(this.context).call(
        "PATCH",
        "/v1/Moneybird/administration/" +
          this.state.administration +
          "/gym/" +
          this.props.gym.id +
          "/set-custom-field",
        { contactCustomAPIField: this.state.apiId }
      );

      Promise.all([
        saveDocumentStyle,
        saveTaxRate,
        saveLedgerAccount,
        saveWorkflow,
        saveApiId,
      ]).then((values) => {
        let success =
          values.filter((value) => value.ok === true).length === values.length;
        if (success) {
          this.context.addNotification(
            "success",
            "De instellingen zijn opgeslagen"
          );
          this.setUp();
        } else {
          this.context.addNotification(
            "error",
            "Er ging iets mis bij het opslaan"
          );
        }
      });
    }
  }
  onInputChange(val) {
    this.setState({
      apiId: val.replace(/\D/g, ""),
    });
  }
  render() {
    return (
      <div className="moneybird-popup">
        <PopupOverlay
          actions={
            this.state.isLoggedIn
              ? [
                  {
                    label: this.state.loaderShown
                      ? "Laden..."
                      : "Importeer klanten",
                    handler: this.state.loaderShown
                      ? () => {}
                      : this.importClients.bind(this),
                    submit: false,
                  },
                  {
                    label: "Verlaat scherm",
                    handler: this.props.onDismiss,
                    submit: true,
                  },
                ]
              : [
                  {
                    label: "Verlaat scherm",
                    handler: this.props.onDismiss,
                    submit: true,
                  },
                ]
          }
          title={"Moneybird " + this.props.gym.name}
          onDismiss={this.props.onDismiss}
        >
          <button
            className="login-button"
            onClick={this.toggleLogin.bind(this)}
          >
            {this.state.isLoggedIn ? "Logout" : "Login"}
          </button>
          {this.state.isLoggedIn && (
            <>
              <InputWithLabel
                select={true}
                title="Administratie"
                options={this.state.administrations}
                value={this.state.administration}
                name="administration"
                onSelect={this.onSelect.bind(this, "administration")}
              />

              <button
                className="save-button"
                onClick={this.saveAdministration.bind(this)}
              >
                Administratie opslaan
              </button>

              {this.state.administrationSelected && (
                <>
                  <InputWithLabel
                    select={true}
                    title="Document stijl"
                    options={this.state.documentStyles}
                    value={this.state.documentStyle}
                    name="documentStyle"
                    onSelect={this.onSelect.bind(this, "documentStyle")}
                  />
                  <InputWithLabel
                    select={true}
                    title="BTW percentage"
                    options={this.state.taxRates}
                    value={this.state.taxRate}
                    name="taxRate"
                    onSelect={this.onSelect.bind(this, "taxRate")}
                  />
                  <InputWithLabel
                    select={true}
                    title="Grootboekkaarten"
                    options={this.state.ledgerAccounts}
                    value={this.state.ledgerAccount}
                    name="ledgerAccount"
                    onSelect={this.onSelect.bind(this, "ledgerAccount")}
                  />
                  <InputWithLabel
                    select={true}
                    title="Workflows"
                    options={this.state.workflows}
                    value={this.state.workflow}
                    name="workflow"
                    onSelect={this.onSelect.bind(this, "workflow")}
                  />
                  <InputWithLabel
                    text={true}
                    title="API id"
                    value={this.state.apiId}
                    name="apiId"
                    onInputChange={this.onInputChange.bind(this)}
                  />
                  <button
                    className="save-button"
                    onClick={this.saveAll.bind(this)}
                  >
                    {this.state.isLoggedIn ? "Opslaan" : "Opslaan"}
                  </button>
                </>
              )}
            </>
          )}
        </PopupOverlay>
      </div>
    );
  }
}

export default MoneybirdPopup;
