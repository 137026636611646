import React, { Component } from 'react';
import { ChevronRight, ChevronDown } from 'react-feather';
import './collapse.css';

class Collapse extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef();
  }
  
  render() {
    let empty = this.props.empty;

    return (
      <div className={`collapseContainer ${empty && 'empty'}`} >
        <div className='collapse-menu'>
          {this.props.open 
            ? <ChevronDown onClick={this.props.onClick} /> 
            : <ChevronRight onClick={this.props.onClick} />}
          {this.props.label}
        </div>
        <div
          ref={this.ref}
          className={this.props.open 
            ? 'collapse-options show' 
            : 'collapse-options'}
          style={this.props.open 
            ? {height: `${this.ref.current.scrollHeight}px`}
            : {height: '0px'}}
        >
        <div className='collapse-content'>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Collapse;
