import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {Smile, User } from "react-feather";
import moment from "moment-timezone";
import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";
import Dumbbell from "../../../app/custom-icons/dumbbell-icon";
import placeholderImg from "../../../app/image/placeholder-image.png";
import SliderPopup from "../../../app/slider-popup/slider-popup";
import InputWithLabel from "../../../app/input/input-with-label";
import MFImage from "../../../app/image/image";
import StatisticsCharts from "../../../app/statistics/statistics-charts";
import "./edit-trainer-popup.css";

class EditTrainerPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var defaultTrainer = {
      id: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      birthdate: "",
      email: "",
      street: "",
      houseNumber: "",
      zipCode: "",
      city: "",
      roleName: "",
      iban: "",
      image: {},
    };

    if (props.trainer) {
      defaultTrainer = {
        ...props.trainer.fitUser,
        id: props.trainer.id,
        birthdate: props.trainer.fitUser.birthdate,
        roleName: props.trainer.role && props.trainer.role.name,
        gymId: props.trainer.gym.id,
        iban: props.trainer.fitUser.billingDetails.iban,
        image: props.trainer.fitUser.image,
      };
    }

    this.state = {
      trainer: defaultTrainer,
      statistics: null,
    };
  }

  componentDidMount() {
    this.getStatistics();
  }

  getStatistics() {
    new ApiClient(this.context)
      .call("GET", "/v1/Statistics/Dashboard", null, false, {}, false, {
        Start: moment("2022-01-01").toISOString(),
        End: moment().endOf("month").toISOString(),
        Trainers: this.state.trainer.id,
      })
      .then((response) => {
        let data = response.json;

        this.setState((state) => ({
          statistics: data,
        }));
      });
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      trainer: { ...state.trainer, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      trainer: { ...state.trainer, [key]: val },
    }));
  }

  redirectTo(key) {
    this.props.history.push(`/${key}`, {
      trainer: this.state.trainer.name,
    });
  }

  updateTrainer() {
    let minimumDate = moment().subtract("12", "years").toISOString();
    let validBirthdate = moment(this.state.trainer.birthdate).isBefore(
      minimumDate
    );

    let roleName = this.state.trainer.roleName || 'Trainer';

    let updatedTrainer = {
      ...this.state.trainer,
      roleName: roleName,
    }

    if (!validBirthdate) {
      return this.context.addNotification(
        "warning",
        "Ben je ouder dan 12 jaar?"
      );
    } else {
      new ApiClient(this.context)
        .call("PUT", "/v1/Trainer/" + this.state.trainer.id, updatedTrainer)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.refreshList();
            this.context.addNotification("success", "Trainer is opgeslagen");
          } else {
            throw result;
          }
        });
    }
  }

  render() {
    return (
      <div className="editTrainerPopup">
        <SliderPopup
          title={
            this.state.trainer.firstName + " " + this.state.trainer.lastName
          }
          closeButton="Sluiten zonder opslaan"
          close={this.props.toggleTrainerEditPopup}
          saveButton="Trainer opslaan"
          save={this.updateTrainer.bind(this)}
          items={[
            {
              title: "Algemeen",
              target: "algemeen",
              icon: User,
              content: (
                <div>
                  <div className="editTrainerPopup-image">
                    <MFImage
                      image={this.state.trainer.image}
                      alt=""
                      placeholder={placeholderImg}
                    />
                    {this.state.trainer.image === null && (
                      <span>Kan aangepast worden door gebruiker in de app</span>
                    )}
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      {!this.props.roles
                        .map(r => r.value)
                        .includes(this.state.trainer.roleName) 
                          ? (
                            <InputWithLabel
                              text={true}
                              title="Gebruikersniveau"
                              name="roleName"
                              value={this.state.trainer.roleName}
                              disabled />
                            ) : (
                              <InputWithLabel
                                select={true}
                                title="Gebruikersniveau"
                                name="roleName"
                                options={this.props.roles}
                                onSelect={this.onSelect.bind(this, "roleName")}
                                value={this.state.trainer.roleName} />
                            )
                      }
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        select={true}
                        title="Gym"
                        name="gymId"
                        options={this.props.gyms}
                        onSelect={this.onSelect.bind(this, "gymId")}
                        value={this.state.trainer.gymId}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Voornaam"
                        name="firstName"
                        value={this.state.trainer.firstName}
                        onInputChange={this.onInputChange.bind(this, "firstName")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Achternaam"
                        value={this.state.trainer.lastName}
                        name="lastName"
                        onInputChange={this.onInputChange.bind(this, "lastName")}
                      />
                    </div>
                  </div>
                  <div className="form-halfWidth">
                    <InputWithLabel
                      date={true}
                      title="Geboortedatum"
                      value={this.state.trainer.birthdate}
                      name="birthdate"
                      onInputChange={this.onInputChange.bind(this, "birthdate")}
                    />
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Telefoonnummer"
                        value={this.state.trainer.phoneNumber}
                        name="phoneNumber"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "phoneNumber"
                        )}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        disabled={true}
                        text={true}
                        title="E-mailadres"
                        value={this.state.trainer.email}
                        name="email"
                        onInputChange={this.onInputChange.bind(this, "email")}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Straatnaam"
                        value={this.state.trainer.street}
                        name="street"
                        onInputChange={this.onInputChange.bind(this, "street")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Huisnummer"
                        value={this.state.trainer.houseNumber}
                        name="houseNumber"
                        onInputChange={this.onInputChange.bind(this, "houseNumber")}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Stad"
                        value={this.state.trainer.city}
                        name="city"
                        onInputChange={this.onInputChange.bind(this, "city")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Postcode"
                        value={this.state.trainer.zipCode}
                        name="zipCode"
                        onInputChange={this.onInputChange.bind(this, "zipCode")}
                      />
                    </div>
                  </div>
                  <div className="form-halfWidth">
                    <InputWithLabel
                      text={true}
                      title="IBAN"
                      value={this.state.trainer.iban}
                      name="iban"
                      onInputChange={this.onInputChange.bind(this, "iban")}
                    />
                  </div>
                  <div className="sliderPopup-buttonContainer">
                    <Link 
                      to={{
                        pathname: '/password-request',
                        state: {
                          trainer: true,
                          email: this.state.trainer.email,
                        }
                      }}
                    >
                      Wachtwoordherstel e-mail versturen
                    </Link>
                  </div>
                </div>
              ),
            },
            {
              title: "Klanttevredenheid",
              target: "clientSatisfaction",
              icon: Smile,
              content: (
                <div className="statistics">
                  <StatisticsCharts
                    statistics={this.state.statistics}
                    filter={{
                      start: moment("2022-01-01").toISOString(),
                      end: moment().endOf("month").toISOString(),
                      trainers: this.state.trainer.id,
                    }}
                  />
                </div>
              ),
            },
            {
              title: "Gegeven activiteiten",
              target: "activities",
              icon: Dumbbell,
              content: (
                <div className="sliderPopup-buttonContainer">
                  <button
                    className="sliderPopup-button"
                    onClick={this.redirectTo.bind(this, "activities")}
                  >
                    Klik hier om alle activiteiten te bekijken
                  </button>
                </div>
              ),
            },
            /* {
              title: "Facturen",
              target: "invoices",
              icon: FileText,
              content: (
                <div className="sliderPopup-buttonContainer">
                  <button
                    className="sliderPopup-button"
                    onClick={this.redirectTo.bind(this, "invoices")}
                  >
                    Klik hier om alle facturen te bekijken
                  </button>
                </div>
              ),
            }, */
          ]}
        />
      </div>
    );
  }
}

export default withRouter(EditTrainerPopup);
