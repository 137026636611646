import React, { Component } from 'react';
import PopupOverlay from '../../../app/popup/popup-overlay';
import RadioInput from "../../../app/input/radio-input/radio-input";
import './appointment-type-popup.css';

class AppointmentTypePopup extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      single: false,
      future: false,
      all: false, 
    };
  }

  selectRadioButton(selectedValue) {
    this.setState({
      single: false,
      future: false,
      all: false,
      [selectedValue]: true,
    });
  }

  setAppointmentType() {
    var value;

    if(this.state.single) {
      value = 'SINGLE';
    } else if(this.state.future) {
      value = 'FUTURE';
    } else {
      value = 'ALL';
    }
  
    this.props.onComplete(value);
  }

  render() {
    return (
      <div className='appointmentType-popup'>
        <PopupOverlay
          actions={[
            {
              label: 'Bevestigen',
              handler: this.setAppointmentType.bind(this),
              submit: true,
            },
          ]}
          title='Afspraak wijzigen'
          onDismiss={this.props.onDismiss}
        >
          <div className='appointmentType-popupFormContainer'>
            <p>Welke afspraken wil je wijzigen?</p>
            <div className='radioButtonContainer'>
              <RadioInput
                isChecked={this.state.single}
                toggleRadio={this.selectRadioButton.bind(this, 'single')}
              />
              <label>Deze afspraak</label>
            </div>
            <div className='radioButtonContainer'>
              <RadioInput
                isChecked={this.state.future}
                toggleRadio={this.selectRadioButton.bind(this, 'future')}
              />
              <label>Deze en opvolgende afspraken</label>
            </div>
            <div className='radioButtonContainer'>
              <RadioInput
                isChecked={this.state.all}
                toggleRadio={this.selectRadioButton.bind(this, 'all')}
              />
              <label>Alle afspraken</label>
            </div>
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default AppointmentTypePopup;
