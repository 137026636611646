import React, { Component } from "react";
import AppContext from "../app-context";

class MFImage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
  }

  componentDidMount() {
    this.getAvatar();
  }

  async getAvatar() {
    var settings = {
      method: "GET",
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    };
    if (this.props.image) {
      fetch(this.context.settings.apiUrl + this.props.image.filePath, settings)
        .then(async (response) => {
          if (response.status === 401) {
            this.context.setUser(null);
          }
          if (response.ok) {
            let blob = await response.blob();
            this.avatarRef.current.src = URL.createObjectURL(blob);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  render() {
    return (
      <img
        ref={this.avatarRef}
        id="MFImage"
        src={this.props.placeholder}
        alt={this.props.alt}
      />
    );
  }
}

export default MFImage;
