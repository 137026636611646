import React, { Component } from "react";
import moment from "moment-timezone";
import "../client-weightlogs-popup.css";
import DateInput from "../../../../app/input/date-input/date-input";

class Weightlogsrow extends Component {
  render() {
    const { isSelected, setLog, log, selectedLog, saveLog, setProp, cancel } =
      this.props;
    return (
      <button
        className={`dayContainer`}
        disabled={isSelected}
        onClick={() => setLog(log)}
      >
        <div className="dayHeader">
          {isSelected ? (
            <DateInput
              className="dateInput"
              name="date"
              value={moment(selectedLog.date).format("YYYY-MM-DD")}
              onInputChange={(e) => setProp("date", e)}
            />
          ) : (
            <div>{`${moment(log.date).format("dd D MMMM YYYY")}`}</div>
          )}
          {isSelected ? (
            <div>
              <input
                className={`weightInput`}
                value={selectedLog.weight}
                type="number"
                step="0.1"
                min="0"
                onChange={(e) => setProp("weight", e.target.value)}
              ></input>
              <span>kg</span>
            </div>
          ) : (
            <div>{`${log.weight.toLocaleString()} kg`}</div>
          )}
        </div>

        {(isSelected || log.note) && (
          <div className="noteContainer">
            {`Notitie: ${!isSelected ? log.note : ""}`}
          </div>
        )}

        {isSelected && (
          <input
            className={`noteInput`}
            value={selectedLog.note}
            type="text"
            onChange={(e) => setProp("note", e.target.value)}
          ></input>
        )}

        {isSelected && (
          <div className="buttonContainer">
            <button className="cancelButton" onClick={cancel}>
              Annuleren
            </button>
            <button className="saveButton" onClick={saveLog}>
              Opslaan
            </button>
          </div>
        )}
      </button>
    );
  }
}

export default Weightlogsrow;
