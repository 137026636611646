import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, XOctagon } from "react-feather";
import AppContext from "../app-context";
import placeholderImg from "../../app/image/placeholder-image.png";
import MFImage from "../image/image";
import ShowProfile from "./show-profile";
import "./topbar.css";

class Topbar extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      showProfile: false,
    };
  }

  toggleDropdown() {
    this.setState((state) => ({
      showDropdown: !state.showDropdown,
    }));
  }

  toggleShowProfile() {
    this.setState((state) => ({
      showProfile: !state.showProfile,
      showDropdown: false,
    }));
  }

  render() {
    return (
      <div
        className={
          this.props.title || this.props.children
            ? "topbarContainerSpaceBetween"
            : "topbarContainerFlexEnd"
        }
      >
        {this.state.showProfile && (
          <ShowProfile
            trainer={this.context.user}
            toggleShowProfile={this.toggleShowProfile.bind(this)}
          />
        )}
        <div
          className="topbar-right"
          style={
            this.props.title
              ? { borderRight: "1px solid #E0E0E0" }
              : { borderRight: "none" }
          }
        >
          <div className="topbar-rightContainer">
            {this.context.mobile && (
              <button
                onClick={this.context.toggleHamburgerMenu}
                className="hamburgerButton"
              >
                <svg
                  className="hamburgerButtonSVG"
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    className={"line topLine"}
                    x1="3"
                    y1="3"
                    x2="97"
                    y2="3"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <line
                    className={"line"}
                    x1="3"
                    y1="50"
                    x2="97"
                    y2="50"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <line
                    className={"line bottomLine"}
                    x1="3"
                    y1="97"
                    x2="97"
                    y2="97"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            )}
            <h2>{this.props.title}</h2>
            <span>{this.props.button}</span>
          </div>
        </div>
        <div
          className="childrenContainer"
          style={{ display: this.props.children ? "block" : "none" }}
        >
          {this.props.children}
        </div>

        {this.context.user && (
          <div className="topbar-user">
            <MFImage
              image={this.context.user.fitUser.image}
              alt="avatar"
              placeholder={placeholderImg}
            />
            {!this.context.mobile && (
              <div className="username">
                {this.context.user.fitUser.firstName +
                  " " +
                  this.context.user.fitUser.lastName}
              </div>
            )}

            <div className="dropdown-container">
              <span
                className="dropdown"
                onClick={this.toggleDropdown.bind(this)}
              >
                <ChevronDown />
              </span>
              {this.state.showDropdown && (
                <div className="dropdown-content">
                  {/* <div className='dropdown-contentItem'>
                    <span
                      onClick={this.toggleShowProfile.bind(this)}
                    >
                      <User /> Profiel
                    </span>
                  </div> */}

                  <div className="dropdown-contentItem">
                    <Link to='/logout'>
                      <XOctagon />
                      Uitloggen
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Topbar;
