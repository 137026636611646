import { Component } from "react";
import moment from "moment";
import AppContext from "./app-context";

class BaseComponent extends Component {
  static contextType = AppContext;

  buildFilterQuery(mapping = {}) {
    var filters = [];

    if (this.state.filterQuery) {
      filters.push(this.state.filterQuery);
    }

    if (this.state.filters) {
      Object.entries(mapping).forEach(([stateKey, mapping]) => {
        let { filterKey, type } = mapping;
        let transform = mapping.transform || ((v) => v);
        let itemTransform = mapping.itemTransform || ((v) => v);
        let stateVal = this.state.filters[stateKey]
          ? transform(this.state.filters[stateKey])
          : {};

        if (type === "select" && Array.isArray(stateVal)) {
          let noneFilter = mapping.noneFilter || `${filterKey}==-1`;

          if (stateVal.length > 0) {
            filters.push(
              `${filterKey}==${stateVal.map(itemTransform).join("|")}`
            );
          } else {
            filters.push(`${noneFilter}`);
          }
        }

        if (type === "range" && stateVal) {
          let from = stateVal.from;
          let until = stateVal.until;

          if (from) {
            filters.push(`${filterKey}>=${itemTransform(from)}`);
          }

          if (until) {
            if(filterKey === "Date") {
              var formatToUntil = moment(until).add(1, "days");
              filters.push(`${filterKey}<=${itemTransform(formatToUntil)}`);
            } else if(filterKey === "Price") {
              filters.push(`${filterKey}<=${itemTransform(until)}`);
            }
          } 
        }
      });
    }

    return filters.join(",");
  }

  setStateAsync(state) {
    return new Promise((resolve) => this.setState(state, resolve));
  }
}

export default BaseComponent;
