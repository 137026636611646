import React, { Component } from "react";
import AppContext from "../../../app/app-context";
import { Users, Zap } from "react-feather";
import cardImg01 from "../card-img-01.png";
import MFImage from "../../../app/image/image";
import "./clubs-card.css";

class ClubsCard extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="clubsCard">
        <MFImage
          className="clubsCard-img"
          image={null}
          alt="img"
          placeholder={cardImg01}
        ></MFImage>
        <div className="clubsCard-body">
          <h5 className="clubsCard-title">{this.props.title}</h5>
          <div className="clubsCard-text">
            <div className="clubsCard-textLocation">
              <span className="street">{this.props.street}</span>
              <span className="houseNumber">{this.props.houseNumber},</span>
              <div className="zipCode">{this.props.zipCode}</div>
              <div className="city">{this.props.city}</div>
            </div>
            <div className="clubsCard-textIcons">
              <span className="clubsCard-amount">
                <span className="amountIcon">
                  <Zap color="var(--color-blue-700)" />
                </span>
                <span className="amount">{this.props.amountTrainers}</span>
                trainers
              </span>

              <span className="clubsCard-amount">
                <span className="amountIcon">
                  <Users color="var(--color-blue-700)" />
                </span>
                <span className="amount">{this.props.amountClients}</span>
                klanten
              </span>
            </div>
          </div>
        </div>
        <div className="clubsCard-footer">
          <button
            className="clubsCard-button"
            onClick={this.props.toggleEditClubsPopup}
          >
          {(this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin') 
              ? 'Bewerken' 
              : 'Bekijken'}
          </button>
          {(this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin') && (
            <button
              className="clubsCard-button"
              onClick={this.props.toggleMoneybirdPopup}
            >
              Moneybird
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default ClubsCard;
