import React, { Fragment } from "react";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import AppContext from "../../app/app-context";
import Topbar from "../../app/topbar/topbar";
import DownloadButton from "../../app/download-button/download-button";
import Table from "../../app/table/table";
import Sidebar from "../../app/sidebar/sidebar";
import ApiClient from "../../api-client/api-client";
import InvoiceFilter from "./invoice-filter";
import BaseComponent from "../../app/base-component";
import ChevronDown from "../../app/custom-icons/chevron-down";
import "./invoices-page.css";

class InvoicesPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      invoices: null,
      statistics: null,
      sortQuery: null,
      filterQuery: null,
      filters: {},
      calculationType: "total",
      calculationSetterVisible: false,
      userRole: context.user && context.user.role,
      pagination: {},
    };
  }

  componentDidMount() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      let s = this.props.location.state;
      this.setStateAsync({
        filterQuery: `ClientName @= ${s.client}`,
      }).then(() => this.getInvoices());
    } else {
      this.getInvoices();
    }
  }

  getInvoices(page = 1, pageSize = null) {
    if(this.context.user && this.state.userRole === 'Trainer') {
      return this.setState({
        invoices: [],
      }); 
    }

    pageSize = pageSize || this.state.pagination.pageSize || 25;

    this.setState({
      invoices: null,
    });

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      date: {
        filterKey: "Date",
        type: "range",
        itemTransform: (v) => moment(v).toISOString(),
      },
      price: {
        filterKey: "Amount",
        type: "range",
        itemTransform: (v) => parseFloat(v.replace(",", ".")).toFixed(2),
      },
      statuses: {
        filterKey: "InvoiceStatus",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Invoice", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        if (!response.ok) return;

        let data = response.json;

        const statistics = {
          average: data.results.average,
          total: data.results.total,
          count: data.results.count,
        };

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        return this.setStateAsync({
          invoices: data.results.values,
          statistics: statistics,
          pagination: pagination
        });
      });
  }

  toggleCalculationSetter() {
    this.setState({
      calculationSetterVisible: !this.state.calculationSetterVisible,
    });
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getInvoices.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getInvoices.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      this.getInvoices();
    });
  }

  mapStatus(status) {
    switch (status) {
      case "OPEN":
        return <span className="status statusOpen">Open</span>;
      case "PENDING PAYMENT":
        return <span className="status statusPending">Verstuurd</span>;
      case "PAID":
        return <span className="status statusPaid">Betaald</span>;
      case "LATE":
        return <span className="status statusLate">Achterstallig</span>;
      case "REMINDED":
        return <span className="status statusReminded">Herinnerd</span>;
      case "UNCOLLECTABLE":
        return <span className="status statusUncollectable">Oninbaar</span>;

      default:
        return null;
    }
  }

  mapDataToTable() {
    return (
      this.state.invoices &&
        this.state.invoices.map((invoice) => ({
          ...invoice.values,
          key: invoice.id,
          invoiceNumber: invoice.invoiceId,
          name:
            invoice.client.fitUser.firstName +
            " " +
            invoice.client.fitUser.lastName,
          amount: invoice.amount.toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
          }),
          date: moment(invoice.date).format("DD-MM-YYYY"),
          dueDate: moment(invoice.dueDate).format("DD-MM-YYYY"),
          status: this.mapStatus(invoice.invoiceStatus),
          download: <DownloadButton />,
        }))
    );
  }

  formatTotal() {
    return parseFloat(this.state.statistics.total).toLocaleString("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
  }

  formatCount() {
    return this.state.statistics.count + " facturen";
  }

  formatAverage() {
    return parseFloat(this.state.statistics.average).toLocaleString("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
  }

  setCalculation(calculationType) {
    this.setState((state) => ({
      calculationType: calculationType,
    }));
  }

  updatePagination(page, pageSize) {
    this.getInvoices(page, pageSize);
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    let calculationMapping = {
      total: this.formatTotal.bind(this),
      count: this.formatCount.bind(this),
      average: this.formatAverage.bind(this),
    };
    let calculationValue = this.state.statistics
      ? calculationMapping[this.state.calculationType]()
      : "";

    return (
      <div className="invoicesPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="invoicesPage-container">
          <Topbar title="Facturen" />

          <div className="invoicesPage-mainContent">
            {!admin
              ? <div className="invoicesPage-trainerRole">Facturen zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                <InvoiceFilter updateFilter={this.updateFilter.bind(this)} />
                <Table
                  pagination={{
                    ...this.state.pagination, 
                    onPageChange: this.updatePagination.bind(this),
                  }}
                  filterQuery={this.state.filterQuery}
                  updateFilter={this.updateTableFilter.bind(this)}
                  sortQuery={this.state.sortQuery}
                  updateSort={this.updateSort.bind(this)}
                  headers={[
                    {
                      title: "Factuur",
                      property: "invoiceNumber",
                      filterKey: "InvoiceId",
                    },
                    {
                      title: "Naam",
                      property: "name",
                      filterKey: "ClientName",
                    },
                    {
                      title: "Bedrag",
                      property: "amount",
                      filterKey: "Amount",
                      filterable: false,
                    },
                    {
                      title: "Datum",
                      property: "date",
                      filterKey: "Date",
                      filterable: false,
                    },
                    {
                      title: "Vervaldatum",
                      property: "dueDate",
                      filterKey: "DueDate",
                    },
                    {
                      title: "Status",
                      property: "status",
                      filterKey: "InvoiceStatus",
                      filterable: false,
                    },
                    {
                      property: "download",
                    },
                  ]}
                  data={this.mapDataToTable()}
                  footers={[
                    { property: "invoiceNumber" },
                    { property: "name" },
                    {
                      property: "amount",
                      title: calculationValue,
                      button: (
                        <button
                          className="arrowDown"
                          onClick={this.toggleCalculationSetter.bind(this)}
                        >
                          <ChevronDown />

                          {this.state.calculationSetterVisible && (
                            <div className="calculationSetter">
                              <button
                                onClick={this.setCalculation.bind(this, "total")}
                              >
                                Totaal
                              </button>
                              <button
                                onClick={this.setCalculation.bind(this, "count")}
                              >
                                Aantal
                              </button>
                              <button
                                onClick={this.setCalculation.bind(this, "average")}
                              >
                                Gemiddelde
                              </button>
                            </div>
                          )}
                        </button>
                      ),
                    },
                    { property: "date" },
                    { property: "dueDate" },
                    { property: "status" },
                    { property: "download" },
                  ]}
                />
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvoicesPage);
