import React, { Component } from 'react';
import CloseIcon from './close-icon';
import './popup.css';

class Popup extends Component {
  renderButtons() {
    return (
      this.props.actions && (
        <div className='popup-section popup-buttonSection'>
          {this.props.actions.map((action) => (
            <button
              key={action.label}
              type={action.submit ? "submit" : "button"}
              className={action.submit ? 'popup-popupButton submit' : 'popup-popupButton button' }
              onClick={action.handler}
              disabled={this.props.isLoading}
            >
              {action.label}
            </button>
          ))}
        </div>
      )
    );
  }

  render() {
    return (
      <div className='popup'>
        <div className='popup-container'>
          <div className='popup-titleContainer'>
            <div className='popup-title'>{this.props.title}</div>
            {this.props.onDismiss && (
              <div className='popup-closeIcon' 
                onClick={!this.props.isLoading && this.props.onDismiss}
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <div className='popup-body'>
            {this.props.img && (
              <div className='popup-img'>
                {this.props.img}
                
                {this.props.placeholder && (
                  <span>Kan aangepast worden door gebruiker in de app</span>
                )}
              </div>
            )}
            <div className='popup-form'>{this.props.children}</div>
          </div>
          {this.props.actions && (
            <div className='popup-footer'>{this.renderButtons()}</div>
          )}
        </div>
      </div>
    );
  }
}

export default Popup;
