import React, { Component, Fragment } from 'react';
import { Grid, Users, Zap } from "react-feather";
import CategoryBadge from "../../app/category-button/category-badge";
import ArrowDown from '../../app/custom-icons/arrow-down';
import ArrowUp from '../../app/custom-icons/arrow-up';
import './notification-item.css';

class NotificationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  updateNotification() {
    this.props.updateNotification();
  }

  renderCategories() {
    return (
      <div className="categories">
        <Fragment key={this.props.gym}>
          <CategoryBadge
            icon={Grid}
          >
            {this.props.gym}
          </CategoryBadge>

          <CategoryBadge
            icon={Zap}
          >
            {this.props.trainer}
          </CategoryBadge>

          <CategoryBadge
            icon={Users}
          >
            {this.props.client}
          </CategoryBadge>
        </Fragment>
      </div>
    )
  }

  renderSentence() {
    const { trainer , client, type, oldGoal, goal, date } = this.props;

    let changedTarget = type === 'WEIGHT_GOAL_CHANGED' && 'gewijzigd';

    if(type === 'WEIGHT_GOAL_NOT_REACHED') {
      return (
        <div className="target">
          <span className="client">{client}</span>
          <span>heeft zijn/haar wekelijkse doel niet behaald</span>
          <div className='notificationItem-headerDate'>
            {date}
          </div>  
        </div>
      )
    }
    
    return (
      <div>
        <span className='trainer'>{trainer}</span>
        <span className="target">heeft het afvalpercentage van</span>
        <span className='client'>{client}</span>
        <span className='type'>{changedTarget}</span> van
        <span className='goal'>{oldGoal && oldGoal.toFixed(1).replace('.', ',') + '%'}</span>naar
        <span className='goal'>{goal && goal.toFixed(1).replace('.', ',') + '%'}</span>
        <div className='notificationItem-headerDate'>
          {date}
        </div>
      </div>
    )
  }


  render() {    
    return (
      <div className='notificationItem'>
        <div className='notificationItem-headerContainer'>
          <div className='notificationItem-header'>
            <div className='notificationItem-headerIcon'>
              {
                this.props.type === 'WEIGHT_GOAL_NOT_REACHED' || this.props.goal < this.props.oldGoal 
                  ? <ArrowDown /> 
                  : <ArrowUp />
              }
            </div>
            <div className='notificationItem-headerContent'>
              {this.renderSentence()}
            </div>
          </div>

          <div className="notificationItem-categories">
            {this.renderCategories()}
          </div>

          {!this.props.processed && 
            <div className="notificationItem-buttonContainer">
              <button 
                onClick={this.updateNotification.bind(this)}
                className="notificationItem-button"
              >
                Notificatie verwerken
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default NotificationItem;
