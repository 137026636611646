import React from 'react';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import FilterBar from "../../app/filterbar/filterbar";
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import { statuses, types } from './notification-constants';

class NotificationFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      statuses: statuses.map(status => ({
        ...status,
        checked: true,
      })),
      types: types.map(type => ({
        ...type,
        checked: true,
      })),
      gyms: [],
    };
  }

  componentDidMount() {
    this.getGyms();
  }

  render() {
    return (
      <div className='invoiceFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup
              title="Status"
              allTitle="Alle statussen"
              options={this.state.statuses}
              onUpdate={this.updateCheckboxFilter.bind(this, 'statuses')}
            />
            <FilterbarCheckboxGroup
              title="Type notificatie"
              allTitle="Type notificatie"
              options={this.state.types}
              onUpdate={this.updateCheckboxFilter.bind(this, 'types')}
            />
            <FilterbarCheckboxGroup
              title="Gym"
              allTitle="Alle gyms"
              options={this.state.gyms}
              onUpdate={this.updateCheckboxFilter.bind(this, 'gyms')}
            />
        </FilterBar>
      </div>
    );
  }
}

export default NotificationFilter;
