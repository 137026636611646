import React, { Component } from "react";
import AppContext from "../../../../app/app-context";
import EditButton from "../../../../app/edit-button/edit-button";
import ApiClient from "../../../../api-client/api-client";

class Calories extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    let client = this.props.client;
    this.state = {
      editable: false,
      calories: client.calories,
      currentCalories: client.currentCalories,
    };
  }
  toggleEditable() {
    this.setState((state) => ({
      editable: !state.editable,
    }));
  }
  setProp(key, value) {
    this.setState({
      [key]: value,
    });
  }

  cancelCalories() {
    this.setState({
      editable: false,
      calories: this.props.client.calories,
      currentCalories: this.props.client.currentCalories,
    });
  }

  saveCalories() {
    const { refreshList, getClientFromAPI, client: c } = this.props;
    const { calories, currentCalories } = this.state;

    let newCalories = Math.round(Number(calories));
    let newCurrentCalories = Math.round(currentCalories);
    
    let client = {
      firstName: c.fitUser.firstName,
      lastName: c.fitUser.lastName,
      phoneNumber: c.fitUser.phoneNumber,
      email: c.fitUser.email,
      birthdate: c.fitUser.birthdate,
      street: c.fitUser.street,
      houseNumber: c.fitUser.houseNumber,
      zipCode: c.fitUser.zipCode,
      city: c.fitUser.city,
      gymId: c.gym.id,
      trainerId: c.trainer.id,
      weight: c.weight,
      calories: newCalories,
      currentCalories: newCurrentCalories,
      active: true,
    };

    new ApiClient(this.context)
      .call("PUT", "/V1/Client/" + c.id, client)
      .then((result) => {
        if (result.ok) {
          getClientFromAPI();
          refreshList();
          this.toggleEditable();
        }
      });
  }

  render() {
    var calories = this.state.calories.toString();
    var currentCalories = this.state.currentCalories.toString();

    const data = [
      { 
        key: "calories", 
        label: "Start calorieën", 
        value: calories 
      },
      {
        key: "currentCalories",
        label: "Huidige calorieën",
        value: currentCalories,
      },
    ];
    return (
      <div className="clientCaloriesWrapper">
        <div className="clientCaloriesHeader">
          <div className="title">Calorieën</div>
          {!this.state.editable && (
            <EditButton onClick={this.toggleEditable.bind(this)} />
          )}
        </div>

        <div className="clientCaloriesContainer">
          {data.map((d) => (
            <div key={d.key} className="clientCaloriesItem">
              {this.state.editable ? (
                <input
                  value={d.value}
                  type="number"
                  onChange={(e) => this.setProp(d.key, e.target.value)}
                ></input>
              ) : (
                <div className="disabledInput">{d.value}</div>
              )}
              <p>{d.label}</p>
            </div>
          ))}
        </div>
        {this.state.editable && (
          <div className="buttonContainer">
            <button
              onClick={() => this.cancelCalories()}
              className="cancelButton"
            >
              Annuleren
            </button>
            <button onClick={() => this.saveCalories()} className="saveButton">
              Opslaan
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Calories;
