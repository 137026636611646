import React from 'react';
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import FilterBar from '../../app/filterbar/filterbar';
import FilterbarRange from "../../app/filterbar/filtebar-range";
import BaseFilterbar from "../../app/filterbar/base-filterbar";

class HourFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      date: {},
      gyms: [],
    };
  }
  
  componentDidMount() {
    this.getGyms();
  }

  render() {
    return (
      <div className='activityFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup
            title="Gym"
            allTitle="Alle gyms"
            options={this.state.gyms}
            onUpdate={this.updateCheckboxFilter.bind(this, 'gyms')}
          />
          <FilterbarRange 
            date={true}
            title="Periode"
            className="dateFilter"
            resetFilter={this.resetFilter.bind(this, 'date')}
            values={this.state.date}
            onChange={this.onInputChange.bind(this, 'date')}
            onCommit={this.commitFilter.bind(this, 'date')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default HourFilter;
