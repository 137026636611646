import React, { Component } from "react";
import { User, Grid } from "react-feather";
import moment from "moment-timezone";
import Euro from "../../../app/custom-icons/euro-icon";
import InputWithLabel from "../../../app/input/input-with-label";
import SliderPopup from "../../../app/slider-popup/slider-popup";
import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";

class EditClientPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let client = props.client && {
      ...props.client.fitUser,
      ...props.client,
      birthdate: props.client.fitUser.birthdate,
      street: props.client.fitUser.street,
      trainerId: "",
      houseNumber: props.client.fitUser.houseNumber,
    };

    let trainers = props.trainers.map((trainer) => ({
      ...trainer,
      value: trainer.id,
      label: trainer.fitUser.firstName + " " + trainer.fitUser.lastName,
    }));

    let gyms = props.gyms.map((gym) => ({
      ...gym,
      value: gym.id,
      label: gym.name,
    }));

    this.state = {
      trainers: trainers,
      gyms: gyms,
      client: client,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  updateClient() {
    let client = this.state.client;
    let active = false;

    let updatedClient = {
      ...client,
      active: active,
      shouldValidateImportedData: false,
    };

    let minimumDate = moment().subtract("12", "years").toISOString();
    let validBirthdate = moment(this.state.client.birthdate).isBefore(
      minimumDate
    );

    if (!validBirthdate) {
      return this.context.addNotification(
        "warning",
        "Ben je ouder dan 12 jaar?"
      );
    } else {
      if (this.state.client.trainerId) {
        new ApiClient(this.context)
          .call("PUT", "/v1/Client/" + client.id, updatedClient)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.refreshList();
              this.context.addNotification("success", "Klant is opgeslagen");
            } else {
              throw result;
            }
          });
      } else {
        this.context.addNotification("error", "Er is een fout opgetreden");
      }
    }
  }

  render() {
    let gymId = this.state.client.gymId;
    let trainers = this.state.trainers
      .map((t) => ({
        gymId: t.gym.id,
        value: t.id,
        label: t.fitUser.firstName + " " + t.fitUser.lastName,
      }))
      .filter((trainer) => trainer.gymId.includes(gymId));

    return (
      <div className="editClientPopup">
        <SliderPopup
          title={this.state.client.firstName + " " + this.state.client.lastName}
          closeButton="Sluiten zonder opslaan"
          close={this.props.onDismiss}
          saveButton="Klant opslaan"
          save={this.updateClient.bind(this)}
          items={[
            {
              title: "Algemeen",
              target: "algemeen",
              icon: User,
              content: (
                <div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Voornaam"
                        name="firstName"
                        value={this.state.client.firstName}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "firstName"
                        )}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Achternaam"
                        name="lastName"
                        value={this.state.client.lastName}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "lastName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm-select form-halfWidth">
                    <InputWithLabel
                      date={true}
                      title="Geboortedatum"
                      value={this.state.client.birthdate}
                      name="birthdate"
                      onInputChange={this.onInputChange.bind(this, "birthdate")}
                    />
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Telefoonnummer"
                        name="phoneNumber"
                        value={this.state.client.phoneNumber}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "phoneNumber"
                        )}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="E-mailadres"
                        name="email"
                        value={this.state.client.email}
                        onInputChange={this.onInputChange.bind(this, "email")}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Straatnaam"
                        name="street"
                        value={this.state.client.street}
                        onInputChange={this.onInputChange.bind(this, "street")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Huisnummer"
                        name="houseNumber"
                        value={this.state.client.houseNumber}
                        onInputChange={this.onInputChange.bind(
                          this,
                          "houseNumber"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Plaats"
                        name="city"
                        value={this.state.client.city}
                        onInputChange={this.onInputChange.bind(this, "city")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Postcode"
                        name="zipCode"
                        value={this.state.client.zipCode}
                        onInputChange={this.onInputChange.bind(this, "zipCode")}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: "Gym en diensten",
              target: "gym",
              icon: Grid,
              content: (
                <div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        select={true}
                        title="Gym"
                        name="gymId"
                        options={this.state.gyms}
                        onSelect={this.onSelect.bind(this, "gymId")}
                        value={this.state.client.gymId}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        select={true}
                        title="Trainer"
                        name="trainerId"
                        options={trainers}
                        onSelect={this.onSelect.bind(this, "trainerId")}
                        value={this.state.client.trainerId}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: "Betaalgegevens",
              target: "payment",
              icon: Euro,
              content: (
                <div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="IBAN"
                        value={this.state.client.iban}
                        name="iban"
                        onInputChange={this.onInputChange.bind(this, "iban")}
                      />
                    </div>
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="Naam"
                        value={this.state.client.cardName}
                        name="cardName"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "cardName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-rowOfTwo">
                    <div className="form-halfWidth">
                      <InputWithLabel
                        text={true}
                        title="BIC code"
                        value={this.state.client.bic}
                        name="bic"
                        onInputChange={this.onInputChange.bind(this, "bic")}
                      />
                    </div>
                    <div className="form-quarterWidth">
                      <InputWithLabel
                        text={true}
                        title="Mandaat ID"
                        value={this.state.client.mandaatId}
                        name="mandaadId"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "mandaatId"
                        )}
                      />
                    </div>
                    <div className="form-quarterWidth">
                      <InputWithLabel
                        text={true}
                        title="Mandaat datum"
                        value={this.state.client.mandaatDate}
                        name="mandaatDate"
                        onInputChange={this.onInputChange.bind(
                          this,
                          "mandaatDate"
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default EditClientPopup;
