import React from 'react';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import FilterbarRange from "../../app/filterbar/filtebar-range";
import FilterBar from "../../app/filterbar/filterbar";
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import { statuses } from './invoice-constants';

class InvoiceFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      date: {},
      price: {},
      statuses: statuses.map(status => ({
        ...status,
        checked: true,
      })),
    };
  }

  render() {
    return (
      <div className='invoiceFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup 
            title="Factuurstatus"
            allTitle="Alle statussen"
            options={this.state.statuses}
            onUpdate={this.updateCheckboxFilter.bind(this, 'statuses')}
          />
          <FilterbarRange 
            text={true}
            title="Prijs"
            className="priceFilter"
            resetFilter={this.resetFilter.bind(this, 'price')}
            values={this.state.price}
            onChange={this.onInputChange.bind(this, 'price')}
            onCommit={this.commitFilter.bind(this, 'price')}
          />
          <FilterbarRange
            date={true}
            title="Periode"
            className="dateFilter"
            resetFilter={this.resetFilter.bind(this, 'date')}
            values={this.state.date}
            onChange={this.onInputChange.bind(this, 'date')}
            onCommit={this.commitFilter.bind(this, 'date')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default InvoiceFilter;
