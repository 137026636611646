import React, { Component } from "react";
import { X } from "react-feather";
import AppContext from "../app-context";
import FilterButton from "../filter-button/filter-button";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import "./table.css";

class Table extends Component {
  static contextType = AppContext;

  renderData() {
    return this.props.data.map((item) => (
      <div className="tableRow" key={item.key || item.id}>
        {this.props.headers.map((property) => {
          let key = property.property;
          let title = typeof item[key] === "string" ? item[key] : null;

          return (
            <div
              className={`tableColumn tableColumn-${key}`}
              title={title}
              key={key}
            >
              {item[key]}
            </div>
          );
        })}
      </div>
    ));
  }

  updateSort(header, e) {
    let key = header.filterKey;
    let sortable = typeof header.sortable === "undefined" || header.sortable;

    if (sortable && key) {
      let sortAsc = `${key}`;
      let sortDesc = `-${key}`;

      if (this.props.sortQuery === sortDesc) {
        this.props.updateSort("");
      } else if (this.props.sortQuery === sortAsc) {
        this.props.updateSort(sortDesc);
      } else {
        this.props.updateSort(sortAsc);
      }
    }

    e.preventDefault();
    e.stopPropagation();
  }

  updateFilter(header, e, value = false) {
    let key = header.filterKey;
    let name = header.title;

    if (value === false) {
      value = window.prompt(`\r\nVul een filter in voor ${name}:\r\n`);
    }

    let filterElements = this.parseFilterQuery(this.props.filterQuery);
    filterElements[key] = value;
    let query = this.formatFilterQuery(filterElements);

    this.props.updateFilter(query);

    e.preventDefault();
    e.stopPropagation();
  }

  parseFilterQuery(filterQuery) {
    filterQuery = filterQuery || "";
    let elements = filterQuery
      .split(",")
      .map((e) => e.split("@=*"))
      .filter((e) => e[0] && e[1]);

    return Object.fromEntries(elements);
  }

  formatFilterQuery(elements) {
    elements = Object.entries(elements);
    let query = elements
      .filter((e) => e[0] && e[1])
      .map((e) => `${e[0]}@=*${e[1]}`)
      .join(",");

    return query;
  }

  resetFilter(header, e) {
    this.updateFilter(header, e, null);
  }

  renderHeaders() {
    let filterElements = this.parseFilterQuery(this.props.filterQuery);

    return this.props.headers.map((header) => {
      let filterable =
        typeof header.filterable === "undefined" || header.filterable;
      let sortAsc = this.props.sortQuery === `${header.filterKey}`;
      let sortDesc = this.props.sortQuery === `-${header.filterKey}`;
      let sortIcon = sortAsc ? "▲" : sortDesc ? "▼" : "\xa0\xa0\xa0\xa0";
      let hasFilterValue = !!filterElements[header.filterKey];

      return (
        <div
          className={`tableColumn tableColumn-${header.property}`}
          key={header.property}
          onClick={this.updateSort.bind(this, header)}
        >
          {header.title} {sortIcon}
          {filterable && header.filterKey && (
            <div className="table-icons">
              <FilterButton
                updateSort={this.updateFilter.bind(this, header)}
                color={hasFilterValue ? "var(--color-blue-500)" : ""}
                fill={hasFilterValue ? "var(--color-blue-500)" : ""}
              />
              {hasFilterValue && (
                <div
                  className="table-iconClose"
                  onClick={this.resetFilter.bind(this, header)}
                >
                  <X size={17} />
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  }

  renderFooter() {
    return this.props.footers.map((footer) => (
      <div
        className={`tableColumn tableColumn-${footer.property}`}
        key={footer.property}
      >
        {footer.title}
        {footer.button}
      </div>
    ));
  }

  render() {
    if (!Array.isArray(this.props.data)) {
      return (
        <div className="mfTableWrapper">
          <Loader />
        </div>
      );
    }

    return (
      <div className="mfTableWrapper">
        <div className="mfTable" cellSpacing="0">
          <div className="tableHeader tableRow">{this.renderHeaders()}</div>
          {this.renderData()}
          {this.props.footers && (
            <div className="tableFooter tableRow">{this.renderFooter()}</div>
          )}
        </div>
        <div className="mfTablePagination">
          <Pagination {...this.props.pagination} />
          </div>
      </div>
    );
  }
}

export default Table;
