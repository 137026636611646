import React from 'react';
import Popup from './popup';
import BaseComponent from '../base-component';
import './popup-overlay.css';

class PopupOverlay extends BaseComponent {
  render() {
    return (
      <div className='popupOverlay'>
        <div 
          className="popupOverlay-container"
          onClick={!this.props.isLoading && this.props.onDismiss}
        ></div>
        <Popup
          isLoading={this.props.isLoading}
          actions={this.props.actions}
          title={this.props.title}
          onDismiss={this.props.onDismiss}
          img={this.props.img}
          placeholder={this.props.placeholder}
        >
          <div className="popupOverlay-mainContainer">
            {this.props.children}
          </div>
        </Popup>
      </div>
    );
  }
}

export default PopupOverlay;
