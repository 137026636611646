import React, { Component } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import RRule from "rrule";
import moment from "moment-timezone";
import AppContext from "../../../app/app-context";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./day-calendar.css";
import { ActiveStatusses, Status } from "../status";

const localizer = momentLocalizer(moment);
const noop = () => {};

let formats = {
  dateFormat: "YYYY-MM-DD",
  timeGutterFormat: "HH",
};

class DayCalendar extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectEvent(event) {
    this.props.toggleEditAgendaPopup(event);
  }

  onSelectSlotEvent(event) {
    this.props.toggleSlotEvent(event);
  }
  isRecurring(item) {
    return (
      item.recurrenceRule &&
      item.recurrenceRule.rule !== null &&
      item.recurrenceRule.rule !== "null" &&
      RRule.parseString(item.recurrenceRule.rule).freq !== undefined
    );
  }
  displayEvent(event) {
    let clients = event.clients.filter(c => ActiveStatusses.includes(c.status));
    let smallCalendar = this.props.selectedTrainers.length > 9;
    let clientName = clients.length === 1 
        ? `${clients[0].firstName} ${clients[0].lastName}`
        : `${clients.length} deelnemers`;

    const productName =
      smallCalendar && event.product.duration < 1
        ? event.product.name.split(" ").map((w) => w[0])
        : event.product.name;

    const isCancelled =
      event.status === Status.Cancelled.name || event.status === Status.CancelledFree.name;
    const isCancelledFree = event.status === Status.CancelledFree.name;

    const getTextStyle = (text) => {
      return isCancelled ? (
        <s>{isCancelledFree ? <i>{text}</i> : text}</s>
      ) : (
        text
      );
    };
    return (
      <div
        className={`event ${smallCalendar ? "small" : "normal"} ${
          this.isRecurring(event) ? "recurring" : "notRecurring"
        } ${isCancelled && "cancelledAppointment"}`}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23 4V10H17"
            stroke="#111111"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 20V14H7"
            stroke="#111111"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.51 9.00008C4.01717 7.56686 4.87913 6.28548 6.01547 5.27549C7.1518 4.26551 8.52547 3.55984 10.0083 3.22433C11.4911 2.88883 13.0348 2.93442 14.4952 3.35685C15.9556 3.77928 17.2853 4.56479 18.36 5.64008L23 10.0001M1 14.0001L5.64 18.3601C6.71475 19.4354 8.04437 20.2209 9.50481 20.6433C10.9652 21.0657 12.5089 21.1113 13.9917 20.7758C15.4745 20.4403 16.8482 19.7346 17.9845 18.7247C19.1209 17.7147 19.9828 16.4333 20.49 15.0001"
            stroke="#111111"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div>{getTextStyle(productName)}</div>
        <div>{getTextStyle(clientName)}</div>
      </div>
    );
  }

  eventStyleGetter(event) {
    return {
      style: { opacity: event.isCancelled ? 0.5 : 1 },
    };
  }

  render() {
    let events =
      this.props.calendarResources &&
      this.props.calendarResources.filter(e => e.status !== Status.Deleted.name).map((event) => ({
        ...event,
        title: this.displayEvent(event),
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        resourceId: event.trainer.id,
        isCancelled:
          event.status === Status.Cancelled.name || event.status === Status.CancelledFree.name,
      }));

    let resources = this.props.selectedTrainers.map((resource) => ({
      ...resource,
      resourceId: resource.id,
      resourceTitle:
        resource.fitUser.firstName + " " + resource.fitUser.lastName,
    }));

    let displayDate = moment(this.props.calendarDate).format("dd D");
    let initTime = moment(this.props.calendarDate).clone().subtract(2, "hours");

    return (
      <div className="agenda">
        <Calendar
          selectable={true}
          onSelectSlot={this.onSelectSlotEvent.bind(this)}
          events={events}
          localizer={localizer}
          defaultView={Views.DAY}
          date={moment(this.props.calendarDate).toDate()}
          style={{ "--calendar-date": `'${displayDate}'` }}
          onNavigate={noop}
          formats={formats}
          resources={resources}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          onSelectEvent={this.onSelectEvent.bind(this)}
          scrollToTime={initTime}
          eventPropGetter={this.eventStyleGetter.bind(this)}
        />
      </div>
    );
  }
}

export default DayCalendar;
