import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../app-context";

class MenuItem extends Component {
  static contextType = AppContext;
  render() {
    return (
      <NavLink
        onClick={this.context.toggleHamburgerMenu}
        to={this.props.target}
      >
        <div className={"menuItem"}>
          <this.props.icon color={"var(--color-blue-700)"} />
          <div>{this.props.children}</div>
        </div>
      </NavLink>
    );
  }
}
export default MenuItem;
