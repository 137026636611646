import React, { Component } from "react";
import { Plus } from "react-feather";
import "./add-button.css";

class AddButton extends Component {
  renderButton() {
    return (
      this.props.actions && (
        <div>
          {this.props.actions.map((action) => (
            <button
              key={action.label}
              className={`addButton ${this.props.className}`}
              onClick={action.handler}
            >
              <span>
                <Plus color="#fff" />
              </span>
            </button>
          ))}
        </div>
      )
    );
  }

  render() {
    return <div>{this.renderButton()}</div>;
  }
}

export default AddButton;
