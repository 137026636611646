import React, { Component } from 'react'
import AppContext from "../../../app/app-context";
import PopupOverlay from "../../../app/popup/popup-overlay";
import RadioInput from "../../../app/input/radio-input/radio-input";
import { Status, Type } from '../status';
import './cancel-appointment-popup.css';

class CancelAppointmentPopup extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);

    this.state = {
      cancelled: false,
      cancelledFree: false,
      cancelDelete: false,

      single: false,
      future: false,
      all: false,
    };
  }

  selectCancelStatus(selectedValue) {
    this.setState(({
      cancelled: false,
      cancelledFree: false,
      cancelDelete: false,
      [selectedValue]: true,
    }));
  }


  selectCancelUpdateType(selectedValue) {
    this.setState({
      single: false,
      future: false,
      all: false,
      [selectedValue]: true,
    });
  }
  

  cancelAppointment() {
    const { cancelled, cancelledFree, cancelDelete, single, future , all } = this.state;
    let isRecurrence = this.props.recurrence;
    
    var status;
    var type;

    if(cancelled && single) {
      status = Status.Cancelled.name;
      type = Type.Single.name;
    } else if(cancelled && future) {
      status = Status.Cancelled.name;
      type = Type.Future.name;
    } else if(cancelled && all) {
      status = Status.Cancelled.name;
      type = Type.All.name;
    } else if(cancelledFree && single) {
      status = Status.CancelledFree.name;
      type = Type.Single.name;
    } else if(cancelledFree && future){
      status = Status.CancelledFree.name;
      type = Type.Future.name;
    } else if(cancelledFree && all){
      status = Status.CancelledFree.name;
      type = Type.All.name;
    } else if(cancelDelete && single) {
      status = Status.Deleted.name;
      type = Type.Single.name;
    } else if(cancelDelete && future) {
      status = Status.Deleted.name;
      type = Type.Future.name;
    } else if(cancelDelete && all) {
      status = Status.Deleted.name;
      type = Type.All.name;
    } else if(!isRecurrence && cancelled) {
      status = Status.Cancelled.name;
      type = Type.Single.name;
    } else if(!isRecurrence && cancelledFree) {
      status = Status.CancelledFree.name;
      type = Type.Single.name;
    } else if(!isRecurrence && cancelDelete) {
      status = Status.Deleted.name;
      type = Type.Single.name;
    } 
    else {
      return this.context.addNotification('warning', 'Zijn alle opties geselecteerd?');
    }
    
    this.props.onComplete(status, type);
  }
  
  render() {
    return (
      <div className='cancelAppointment-popup'>
        <PopupOverlay
          actions={[
            {
              label: 'Bevestigen',
              handler: this.cancelAppointment.bind(this),
              submit: true,
            },
          ]}
          title="Afspraak annuleren"
          onDismiss={this.props.onDismiss}
        >
          <div className="cancelAppointment-popupFormContainer">
            <div className="cancelStatus">
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelled}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelled')}
                />
                <label>Wel kosten klant, geen inhaal tegoed</label>
              </div>
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelledFree}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelledFree')}
                />
                <label>Wel kosten klant, wel inhaal tegoed</label>
              </div>
              <div className="radioButtonContainer">
                <RadioInput
                  isChecked={this.state.cancelDelete}
                  toggleRadio={this.selectCancelStatus.bind(this, 'cancelDelete')}
                />
                <div className="cancelAppointment-delete">
                  <label>Geen kosten klant, geen inhaal tegoed</label>
                  <span>Let op, deze annulering kan niet ongedaan worden gemaakt</span>
                </div>  
              </div>
            </div>

            {this.props.recurrence && 
              <div className="cancelUpdateType">
                <div className="dividerLine" />
                <p>Welke afspraken wil je wijzigen?</p>
                <div className='radioButtonContainer'>
                  <RadioInput
                    isChecked={this.state.single}
                    toggleRadio={this.selectCancelUpdateType.bind(this, 'single')}
                  />
                  <label>Deze afspraak</label>
                </div>
                <div className='radioButtonContainer'>
                  <RadioInput
                    isChecked={this.state.future}
                    toggleRadio={this.selectCancelUpdateType.bind(this, 'future')}
                  />
                  <label>Deze en opvolgende afspraken</label>
                </div>
                <div className='radioButtonContainer'>
                  <RadioInput
                    isChecked={this.state.all}
                    toggleRadio={this.selectCancelUpdateType.bind(this, 'all')}
                  />
                  <label>Alle afspraken</label>
                </div>
              </div>  
            }
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default CancelAppointmentPopup;
