import React, { Component } from 'react';
import './card.css';

class Card extends Component {
  render() {
    return (
      <div className='card'>
        <h1>{this.props.title}</h1>
        {this.props.value && (
          <span className='card-value'>{this.props.value}</span>
        )}
        {this.props.children}
        {this.props.action && (
          <button 
            className='card-button'
            onClick={this.props.action.handler}
          >{this.props.action.label}</button>
        )}
      </div>
    );
  }
}

export default Card;
