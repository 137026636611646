import React from 'react';
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";
import FilterBar from '../../app/filterbar/filterbar';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import { statuses } from './client-constants';

class ClientFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = {
      statuses: statuses.map(status => ({
        ...status,
        checked: true,
      })),
      groupedTrainers: [],
    };
  }
  
  componentDidMount() {
    this.getGroupedTrainers()
      .then(() => {
        this.mapInitialGroupedCheckboxFilter('trainers', 'groupedTrainers');
      });
  }

  render() {
    return (
      <div className='activityFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup
            title="Klantstatus"
            allTitle="Alle statussen"
            options={this.state.statuses}
            onUpdate={this.updateCheckboxFilter.bind(this, 'statuses')}
          />
          <FilterbarCheckboxGroup
            title="Gym's en trainers"
            optionGroups={this.state.groupedTrainers}
            onUpdate={this.updateGroupedCheckboxFilter.bind(this, 'groupedTrainers', 'trainers')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default ClientFilter;
