import React from 'react';
import BaseFilterbar from "../../app/filterbar/base-filterbar";
import FilterBar from '../../app/filterbar/filterbar';
import FilterbarCheckboxGroup from "../../app/filterbar/filterbar-checkbox-group";

class CalendarFilter extends BaseFilterbar {
  constructor(props) {
    super(props);

    this.state = this.mapProps();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.gyms !== prevProps.gyms
      || this.props.trainers !== prevProps.trainers
      || this.props.products !== prevProps.products) {

        this.setStateAsync(this.mapProps())
        .then(() => {
          this.mapInitialGroupedCheckboxFilter('trainers', 'groupedTrainers');
        });;
    }
  }

  mapProps() {
    return {
      groupedTrainers: this.groupTrainers(this.props.gyms, this.props.trainers),
      products: this.props.products.map(product => ({
        value: product.id,
        label: product.name,
        checked: true,
      })),
    };
  }

  render() {
    return (
      <div className='agendaFilter'>
        <FilterBar>
          <FilterbarCheckboxGroup
            title="Agenda's"
            optionGroups={this.state.groupedTrainers}
            onUpdate={this.updateGroupedCheckboxFilter.bind(this, 'groupedTrainers', 'trainers')}
          />
          <FilterbarCheckboxGroup
            title="Diensten"
            allTitle="Alle diensten"
            options={this.state.products}
            onUpdate={this.updateCheckboxFilter.bind(this, 'products')}
          />
        </FilterBar>
      </div>
    );
  }
}

export default CalendarFilter;
