export const statuses = [
  {
    label: 'Open',
    value: false,
  },
  {
    label: 'Verwerkt',
    value: true,
  },
];

export const types = [
  {
    label: 'Afvaltraject gewijzigd',
    value: 0,
  },
  {
    label: 'Success te laag',
    value: 1,
  },
];
