import React, { Component } from "react";
import moment from "moment-timezone";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import PopupOverlay from "../../../app/popup/popup-overlay";
import "./client-weightlogs-popup.css";
import Weightlogs from "./components/weightlogs";
import Weightchart from "./components/weightchart";
import Calories from "./components/calories";
import Clientgoal from "./components/clientgoal";

class ClientWeightlogsPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      client: this.props.client,
      lastWeight:
        this.props.client.weightMetrics.length !== 0 &&
        this.props.client.weightMetrics.sort((a, b) =>
          moment(a.date).diff(moment(b.date))
        )[this.props.client.weightMetrics.length - 1].weight,
    };
  }

  componentDidMount() {
    this.getClientFromAPI();
  }

  getClientFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/Client/" + this.props.client.id)
      .then((result) => {
        if (result.ok) {
          const client = result.json;
          const lastWeight =
            client.weightMetrics.length !== 0 &&
            client.weightMetrics.sort((a, b) =>
              moment(a.date).diff(moment(b.date))
            )[client.weightMetrics.length - 1].weight;
          this.setState({
            client: client,
            lastWeight: lastWeight,
          });
        }
      });
  }

  render() {
    const client = this.state.client;
    const name = `${client.fitUser.firstName} ${client.fitUser.lastName}`;

    return (
      <div className="client-weightlogs-popup">
        <PopupOverlay
          title={`Gewicht progressie van ${name}`}
          onDismiss={this.props.onDismiss}
        >
          {client.clientGoal && <Weightchart client={client} />}
          <div className="clientCaloriesAndClientGoalContainer">
            <Calories
              client={client}
              refreshList={this.props.refreshList}
              getClientFromAPI={this.getClientFromAPI.bind(this)}
            />

            <Clientgoal
              client={client}
              refreshList={this.props.refreshList}
              getClientFromAPI={this.getClientFromAPI.bind(this)}
            />
          </div>
          {client.weightMetrics.length !== 0 && (
            <Weightlogs
              client={client}
              lastWeight={this.state.lastWeight}
              refreshList={this.props.refreshList}
              getClientFromAPI={this.getClientFromAPI.bind(this)}
            />
          )}
        </PopupOverlay>
      </div>
    );
  }
}

export default ClientWeightlogsPopup;
