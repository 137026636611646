import React, { Fragment } from "react";
import moment from "moment-timezone";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import BaseComponent from "../../app/base-component";
import Topbar from "../../app/topbar/topbar";
import Table from "../../app/table/table";
import Sidebar from "../../app/sidebar/sidebar";
import ReviewFilter from "./review-filter";
import ChevronDown from "../../app/custom-icons/chevron-down";
import "./reviews-page.css";

class ReviewsPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    var params = props.location.state || {};

    this.state = {
      reviews: null,
      statistics: null,
      filters: params.filters || {},
      calculationType: "average",
      calculationSetterVisible: false,
      userRole: context.user && context.user.role,
      pagination: {},
    };
  }

  componentDidMount() {
    this.getReviews();
  }

  getReviews(page = 1, pageSize = null) {
    this.setState({
      reviews: null,
    });

    pageSize = pageSize || this.state.pagination.pageSize || 25;

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      date: {
        filterKey: "Date",
        type: "range",
        itemTransform: (v) => moment(v).toISOString(),
      },
      trainers: {
        filterKey: "Trainer",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Review", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        if (!response.ok) return;

        let data = response.json;

        const statistics = {
          average: data.results.average,
          min: data.results.min,
          max: data.results.max,
          count: data.results.count,
        };

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        return this.setStateAsync({
          reviews: data.results.values,
          statistics: statistics,
          pagination: pagination
        });
      });
  }

  toggleCalculationSetter() {
    this.setState({
      calculationSetterVisible: !this.state.calculationSetterVisible,
    });
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getReviews.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getReviews.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getReviews();
    });
  }

  mapDataToTable() {
    return (
      this.state.reviews &&
        this.state.reviews.map((review) => ({
          ...review.values,
          key: review.id,
          date: moment(review.date).format("DD-MM-YYYY"),
          trainer: review.trainer.firstName + " " + review.trainer.lastName,
          rating: review.rating,
          review: review.text,
        }))
    );
  }

  formatMin() {
    return this.state.statistics.min.toFixed(0);
  }

  formatMax() {
    return this.state.statistics.max.toFixed(0);
  }

  formatCount() {
    return this.state.statistics.count;
  }

  formatAverage() {
    return this.state.statistics.average
      ? this.state.statistics.average.toFixed(1)
      : 0;
  }

  setCalculation(calculationType) {
    this.setState((state) => ({
      calculationType: calculationType,
    }));
  }

  updatePagination(page, pageSize) {
    this.getReviews(page, pageSize);
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    let calculationMapping = {
      min: this.formatMin.bind(this),
      max: this.formatMax.bind(this),
      count: this.formatCount.bind(this),
      average: this.formatAverage.bind(this),
    };
    let calculationValue = this.state.statistics
      ? calculationMapping[this.state.calculationType]()
      : "";

    return (
      <div className="reviewsPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="reviewsPage-container">
          <Topbar title="Beoordelingen" />

          <div className="reviewsPage-mainContent">
            {!admin
              ? <div className="reviewsPage-trainerRole">Reviews zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  <ReviewFilter 
                    initialFilters={this.state.filters}
                    updateFilter={this.updateFilter.bind(this)}
                  />
                  <Table
                    pagination={{
                      ...this.state.pagination, 
                      onPageChange: this.updatePagination.bind(this),
                    }}
                    filterQuery={this.state.filterQuery}
                    updateFilter={this.updateTableFilter.bind(this)}
                    sortQuery={this.state.sortQuery}
                    updateSort={this.updateSort.bind(this)}
                    headers={[
                      {
                        title: "Datum",
                        property: "date",
                        filterKey: "Date",
                        filterable: false,
                      },
                      {
                        title: "Trainer",
                        property: "trainer",
                        filterKey: "TrainerName",
                      },
                      {
                        title: "Cijfer",
                        property: "rating",
                        filterKey: "Rating",
                        filterable: false,
                      },
                      {
                        title: "Commentaar",
                        property: "review",
                        filterKey: "Text",
                        filterable: false,
                      },
                    ]}
                    data={this.mapDataToTable()}
                    footers={[
                      { property: "date" },
                      { property: "trainer" },
                      {
                        property: "rating",
                        title: calculationValue,
                        button: (
                          <button
                            className="arrowDown"
                            onClick={this.toggleCalculationSetter.bind(this)}
                          >
                            <ChevronDown />

                            {this.state.calculationSetterVisible && (
                              <div className="calculationSetter">
                                <button
                                  onClick={this.setCalculation.bind(this, "max")}
                                >
                                  Hoogste
                                </button>
                                <button
                                  onClick={this.setCalculation.bind(this, "min")}
                                >
                                  Laagste
                                </button>
                                <button
                                  onClick={this.setCalculation.bind(this, "count")}
                                >
                                  Aantal
                                </button>
                                <button
                                  onClick={this.setCalculation.bind(this, "average")}
                                >
                                  Gemiddelde
                                </button>
                              </div>
                            )}
                          </button>
                        ),
                      },
                      { property: "beoordelingen" },
                    ]}
                  />
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewsPage);
