import React, { Component } from "react";
import Select from "../select/select";

class SelectGroup extends Component {
  render() {
    return (
      <div className="selectGroup">
        <Select
          options={this.props.options}
          value={this.props.value}
          name={this.props.name}
          onSelect={this.props.onSelect}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default SelectGroup;
