import React, { Fragment } from "react";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";
import BaseComponent from "../../app/base-component";
import Topbar from "../../app/topbar/topbar";
import AddButton from "../../app/add-button/add-button";
import EditButton from "../../app/edit-button/edit-button";
import Sidebar from "../../app/sidebar/sidebar";
import TrainersPopup from "./trainers-popup/trainers-popup";
import EditTrainerPopup from "./edit-trainer-popup/edit-trainer-popup";
import Table from "../../app/table/table";
import TrainersFilter from "./trainers-filter";
import "./trainers-page.css";

class TrainersPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      trainers: null,
      trainer: {
        birthdate: "",
      },
      filters: {},
      roles: [],
      gyms: [],
      sortQuery: null,
      filterQuery: null,
      trainerEditPopupShown: false,
      trainersPopupShown: false,
      pagination: {}
    };
  }

  componentDidMount() {
    this.getRoles();
    this.getGyms();
    this.getTrainers();
  }

  getRoles() {
    if(this.context.user && this.context.user && this.context.user.role === 'Trainer') return null;
    if(!this.context.user) return null;
    
    new ApiClient(this.context)
      .call("GET", "/v1/Trainer/GetTrainerRoles")
      .then((response) => {
        let data = response.json;
        
        const roles = data.map((d) => ({
          value: d.name,
          label: d.name,
        }));

        this.setState({
          roles: roles,
        });
      });
  }

  getGyms() {
    if(!this.context.user) return null;
    new ApiClient(this.context).call("GET", "/v1/Gym/").then((response) => {
      let data = response.json;

      const gyms = data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }));

      this.setState({
        gyms: gyms,
      });
    });
  }

  getTrainers(page = 1, pageSize = null) {
    if(!this.context.user) return null;
    pageSize = pageSize || this.state.pagination.pageSize || 25;

    this.setState({
      trainers: null,
    });

    if(this.context.user && this.context.user.role === 'Trainer') {
      return this.setState({
        trainers: null,
      }); 
    }

    var sortQuery = this.state.sortQuery || "";
    let filterQuery = this.buildFilterQuery({
      gyms: {
        filterKey: "GymName",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Trainer", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        let data = response.json;

        const trainers = data.results.map((trainer) => ({
          ...trainer,
          fitUser: {
            ...trainer.fitUser,
            birthdate: moment(trainer.fitUser.birthdate).format("YYYY-MM-DD"),
          },
        }));

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        return this.setStateAsync({
          trainers: trainers,
          pagination: pagination
        });
      });
  }

  toggleTrainersPopup() {
    this.setState((state) => ({
      trainersPopupShown: !state.trainersPopupShown,
    }));
  }

  toggleTrainerEditPopup(trainer) {
    this.setState((state) => ({
      trainerEditPopupShown: !state.trainerEditPopupShown,
      trainer: trainer,
    }));
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getTrainers.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getTrainers.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getTrainers();
    });
  }

  mapDataToTable() {
    return ( 
      this.state.trainers &&
      this.state.trainers.map((trainer) => ({
        key: trainer.id,
        name: trainer.fitUser.firstName + " " + trainer.fitUser.lastName,
        gym: trainer.gym.city,
        role: trainer.role ? trainer.role.name : "",
        phoneNumber: trainer.fitUser.phoneNumber,
        email: trainer.fitUser.email,
        edit: (
          <EditButton
            onClick={this.toggleTrainerEditPopup.bind(this, trainer)}
          />
        ),
      }))
    );
  }

  updatePagination(page, pageSize) {
    this.getTrainers(page, pageSize);
  }

  render() {
    const admin = this.context.user && (this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin');

    return (
      <div className="trainersPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="trainersPage-container">
          <div className="topbar">
            <Topbar
              title="Trainers"
              button={ admin && (
                <AddButton
                  actions={[
                    { label: "Add", handler: this.toggleTrainersPopup.bind(this)},
                  ]}
                />)}
            />
          </div>

          <div className="trainersPage-mainContent">
            {this.context.user && this.context.user.role === 'Trainer' 
              ? <div className="trainersPage-trainerRole">Teams zijn voor trainers niet beschikbaar</div> 
              : <Fragment>
                  <TrainersFilter updateFilter={this.updateFilter.bind(this)} />
                  <Table
                    pagination={{
                      ...this.state.pagination,
                      onPageChange: this.updatePagination.bind(this),
                    }}
                    filterQuery={this.state.filterQuery}
                    updateFilter={this.updateTableFilter.bind(this)}
                    sortQuery={this.state.sortQuery}
                    updateSort={this.updateSort.bind(this)}
                    headers={[
                      {
                        title: "Naam",
                        property: "name",
                        filterKey: "Name",
                      },
                      {
                        title: "Gym",
                        property: "gym",
                        filterKey: "GymLocation",
                      },
                      {
                        title: "Rol",
                        property: "role",
                        filterKey: "Role",
                      },
                      {
                        title: "Telefoon",
                        property: "phoneNumber",
                        filterKey: "PhoneNumber",
                      },
                      {
                        title: "E-mailadres",
                        property: "email",
                        filterKey: "Email",
                      },
                      { property: "edit" },
                    ]}
                    data={this.mapDataToTable()}
                  />

                  {this.state.trainerEditPopupShown && (
                    <EditTrainerPopup
                      refreshList={this.getTrainers.bind(this)}
                      toggleTrainerEditPopup={this.toggleTrainerEditPopup.bind(this)}
                      gyms={this.state.gyms}
                      roles={this.state.roles}
                      trainer={this.state.trainer}
                      onDismiss={this.toggleTrainerEditPopup.bind(this)}
                    />
                  )}

                  {this.state.trainersPopupShown && (
                    <TrainersPopup
                      showOnly={false}
                      refreshList={this.getTrainers.bind(this)}
                      onDismiss={this.toggleTrainersPopup.bind(this)}
                      gyms={this.state.gyms}
                      roles={this.state.roles}
                      toggleTrainersPopup={this.toggleTrainersPopup.bind(this)}
                    />
                  )}
                </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TrainersPage;
