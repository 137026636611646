import ApiClient from "../../api-client/api-client";
import BaseComponent from '../base-component';

export default class BaseFilterbar extends BaseComponent {
  onInputChange(prop, key, val) {
    this.setStateAsync(state => ({
      [prop]: {
        ...state[prop],
        [key]: val,
      },
    }));
  }

  mapInitialRangeFilter(prop) {
    var initialFilters = this.props.initialFilters;

    if(initialFilters && initialFilters[prop]) {
      var initialValue = initialFilters[prop];
      var value = {};

      if(initialValue.from) {
        value.from = initialValue.from;
      } 

      if(initialValue.until) {
        value.until = initialValue.until;
      } 

      this.setState(state => ({
        [prop]: value
      }));
    }
  }

  mapInitialGroupedCheckboxFilter(inKey, outKey) {
    var initialFilters = this.props.initialFilters;

    if(initialFilters && initialFilters[inKey]) {
      this.setState(state => {
        var selected = initialFilters[inKey];
        var groupedOptions = state[outKey];

        return {
          [outKey]: groupedOptions.map(group => ({
            ...group,
            options: group.options.map(option => ({
              ...option,
              checked: selected.includes(option.value)
            }))
          }))
        }
      });
    } 
  }

  resetFilter(prop) {
    return this.setStateAsync(state => ({
      [prop]: {},
    })).then(() => this.commitFilter(prop));
  }

  commitFilter(prop) {
    this.props.updateFilter(prop, this.state[prop]);
  }

  updateCheckboxFilter(key, newOptions) {
    this.setStateAsync(state => ({
      [key]: newOptions
    }))
    .then(() => {
      var list = this.state[key]
        .filter(p => p.checked)
        .map(p => p.value);
  
      this.props.updateFilter(key, list);
    });
  }

  updateGroupedCheckboxFilter(inKey, outKey, newOptionGroups) {
    this.setStateAsync(state => ({
      [inKey]: newOptionGroups
    }))
    .then(() => {
      var list = this.state[inKey]
        .flatMap(p => p.options)
        .filter(p => p.checked)
        .map(p => p.value);
  
      this.props.updateFilter(outKey, list);
    });
  }

  getGyms() {
    new ApiClient(this.context).call('GET', '/v1/Gym/')
      .then((response) => {
        if(!response.ok) return;
        
        let data = response.json;

        const gyms = data.results.map(d => ({
          value: d.name,
          label: d.name,
          checked: true,
        }));

        this.setState(state => ({
          gyms: gyms,
        }));
    });
  }

  getGroupedTrainers() {
    return new ApiClient(this.context)
    .call("GET", "/v1/Gym/").then((response) => {
      let data = response.json;

      return data.results;
    })
    .then(gyms => {
      return new ApiClient(this.context)
      .call("GET", "/v1/Trainer").then((response) => {
        let data = response.json;
  
        return this.setStateAsync((state) => ({
          groupedTrainers: this.groupTrainers(gyms, data.results),
        }));
      });
    });
  }

  groupTrainers(gyms, trainers) {
    return gyms.map(gym => ({
      title: gym.name,
      options: trainers
        .filter(trainer => trainer.gym.id === gym.id)
        .map(trainer => {
          let fitUser = trainer.fitUser;

          return {
            value: trainer.id,
            label: `${fitUser.firstName} ${fitUser.lastName}`,
            checked: true,
          };
        })
    }));
  }
}