import React, { Component, Fragment } from 'react';
import AppContext from '../../../app/app-context';
import ApiClient from '../../../api-client/api-client';
import popupImg from '../card-img-01.png';
import PopupOverlay from '../../../app/popup/popup-overlay';
import InputWithLabel from '../../../app/input/input-with-label';
import Badge from '../../../app/input/badge/badge';
import TimeInput from '../../../app/input/time-input/time-input';
import MFImage from '../../../app/image/image';
import './clubs-popup.css';

const dayMapping = [
  { dayOfWeek: 'Monday', label: 'Maandag' },
  { dayOfWeek: 'Tuesday', label: 'Dinsdag' },
  { dayOfWeek: 'Wednesday', label: 'Woensdag' },
  { dayOfWeek: 'Thursday', label: 'Donderdag' },
  { dayOfWeek: 'Friday', label: 'Vrijdag' },
  { dayOfWeek: 'Saturday', label: 'Zaterdag' },
  { dayOfWeek: 'Sunday', label: 'Zondag' },
];

class ClubsPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var defaultGym = {
      name: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      phoneNumber: '',
      email: '',
      openingDays: [
        { dayOfWeek: 'Monday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Tuesday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Wednesday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Thursday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Friday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Saturday', opens: '07:00:00', closes: '17:00:00' },
        { dayOfWeek: 'Sunday', opens: '07:00:00', closes: '17:00:00' },
      ],
    };

    if (props.gym) {
      defaultGym = { ...defaultGym, ...props.gym };
    }

    defaultGym.openingDays = defaultGym.openingDays.map(day => {
      let mapping = dayMapping
        .filter(m => m.dayOfWeek === day.dayOfWeek)[0];

      return {
        ...day,
        ...mapping
      };
    });

    this.state = {
      gym: defaultGym,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      gym: { ...state.gym, [key]: val },
    }));
  }

  onOpeningTimeChange(dayOfWeek, key, val) {
    this.setState((state) => {
      var openingDays = state.gym.openingDays.map((d) => ({ ...d }));
      var day = openingDays.filter((d) => d.dayOfWeek === dayOfWeek)[0];

      day[key] = val;

      return {
        gym: {
          ...state.gym,
          openingDays: openingDays,
        },
      };
    });
  }

  onSubmit() {
    if(this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin') {
      if (this.props.gym) {
        this.updateGym();
      } else {
        this.createGym();
      }
    } else {
      this.props.onDismiss();
    }
  }

  createGym() {
    let gym = this.state.gym;
    const requiredVals = 
      gym.name &&
      gym.street &&
      gym.houseNumber &&
      gym.zipCode &&
      gym.city &&
      gym.phoneNumber &&
      gym.email &&
      gym.openingDays;

    if (!requiredVals) {
      return this.context.addNotification('warning', 'Zijn alle velden ingevuld?');
    }
    else {  
      new ApiClient(this.context)
        .call('POST', '/v1/Gym', gym)
        .then(result => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.onRefreshData();
            this.context.addNotification('success', 'Club is aangemaakt');
          } 
          else {
            this.context.addNotification('error', 'Er is een fout opgetreden');
          }
        });
    } 
  }

  updateGym() {
    let gym = this.state.gym;
    const requiredVals = 
      gym.name &&
      gym.street &&
      gym.houseNumber &&
      gym.zipCode &&
      gym.city &&
      gym.phoneNumber &&
      gym.email &&
      gym.openingDays;

    if (!requiredVals) {
      return this.context.addNotification('warning', 'Zijn alle velden ingevuld?');
    } 
    else {
      new ApiClient(this.context)
        .call('PUT', '/v1/Gym/' + gym.id, gym)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.onRefreshData();
            this.context.addNotification('success', 'Club is opgeslagen');
          } else {
            throw result;
          }
        });
    }
  }
  

  renderOpeningHours() {
    const admin = this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin';
    let openingHours = this.state.gym.openingDays;

    return (
      <div className='clubs-openingHours'>
        <h2>Openingstijden</h2>
        <h2>van</h2>
        <h2>tot</h2>
        {openingHours.map((day) => (
          <Fragment key={day.dayOfWeek}>
            <Badge
              title={day.dayOfWeek}
              id={day.dayOfWeek}
              value={day.label}
              disabled={true}
            />
            <TimeInput
              value={day.opens}
              onInputChange={this.onOpeningTimeChange.bind(this, day.dayOfWeek, 'opens')}
              disabled={!admin}
            />
            <TimeInput
              value={day.closes}
              onInputChange={this.onOpeningTimeChange.bind(this, day.dayOfWeek, 'closes')}
              disabled={!admin}
            />
          </Fragment>
        ))}
      </div>
    );
  }

  render() {
    const admin = this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin' ;
    return (
      <PopupOverlay
        actions={
          admin 
            ? [
                {
                  label: this.props.gym ? 'Club bewerken' : 'Club aanmaken',
                  handler: this.onSubmit.bind(this),
                  submit: true,
                },
              ]
            : [
                {
                  label: 'Sluiten',
                  handler: this.onSubmit.bind(this)
                }
            ]
        }
        title={
          admin
            ? this.props.gym 
              ? 'Club bewerken' : 'Club aanmaken'
            : 'Club bekijken'
        }
        onDismiss={this.props.onDismiss}
      >
        <div className='clubs-popupFormContainer'>
          <div className='clubs-popupColumn'>
            <MFImage image={null} alt='' placeholder={popupImg}></MFImage>
            <div className='clubs-popupFormRow'>
              <InputWithLabel
                text={true}
                title='Naam'
                value={this.state.gym.name}
                name='name'
                onInputChange={this.onInputChange.bind(this, 'name')}
                disabled={!admin}
              />
            </div>
            <div className='clubs-popupFormRow'>
              <InputWithLabel
                text={true}
                title='Straatnaam'
                value={this.state.gym.street}
                name='street'
                onInputChange={this.onInputChange.bind(this, 'street')}
                disabled={!admin}
              />
              <InputWithLabel
                text={true}
                title='Huisnummer'
                value={this.state.gym.houseNumber}
                name='houseNumber'
                onInputChange={this.onInputChange.bind(this, 'houseNumber')}
                disabled={!admin}
              />
            </div>
            <div className='clubs-popupFormRow'>
              <InputWithLabel
                text={true}
                title='Stad'
                value={this.state.gym.city}
                name='city'
                onInputChange={this.onInputChange.bind(this, 'city')}
                disabled={!admin}
              />
              <InputWithLabel
                text={true}
                title='Postcode'
                value={this.state.gym.zipCode}
                name='zipCode'
                onInputChange={this.onInputChange.bind(this, 'zipCode')}
                disabled={!admin}
              />
            </div>
            <div className='clubs-popupFormRow'>
              <InputWithLabel
                text={true}
                title='Telefoonnummer'
                value={this.state.gym.phoneNumber}
                name='phoneNumber'
                onInputChange={this.onInputChange.bind(this, 'phoneNumber')}
                disabled={!admin}
              />
              <InputWithLabel
                text={true}
                title='E-mailadres'
                value={this.state.gym.email}
                name='email'
                onInputChange={this.onInputChange.bind(this, 'email')}
                disabled={!admin}
              />
            </div>
          </div>
          <div className='clubs-popupColumn'>
            {this.renderOpeningHours()}
          </div>
        </div>
      </PopupOverlay>
    );
  }
}

export default ClubsPopup;
