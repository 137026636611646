import BaseComponent from "../base-component";
import Checkbox from "../input/checkbox/checkbox";
import CheckboxGroup from "../input/checkbox/checkbox-group";

export default class FilterbarCheckboxGroup extends BaseComponent {
  constructor(props, context) {
    super(props);
    this.state = {
      open: context.mobile ? false : true,
    };
  }
  toggleFilter() {
    this.setState((state) => ({
      open: !state.open,
    }));
  }

  toggleCheckbox(selection) {
    let optionGroups = this.props.optionGroups || [
      { title: this.props.allTitle, options: this.props.options },
    ];

    let newOptionGroups = optionGroups.map((group) => {
      let newOptions = group.options.map((option) => {
        let newOption = selection.find((o) => o.key === option.value);

        return {
          ...option,
          checked: newOption ? newOption.val : option.checked,
        };
      });

      return {
        ...group,
        options: newOptions,
      };
    });

    if (this.props.optionGroups) {
      this.props.onUpdate(newOptionGroups);
    } else {
      this.props.onUpdate(newOptionGroups.flatMap((g) => g.options));
    }
  }

  render() {
    let optionGroups = this.props.optionGroups || [
      { title: this.props.allTitle, options: this.props.options },
    ];

    return (
      <div className="filter-section">
        <div className="filter-sectionContainer">
          <div className="filter-titleContainer">
            <h1>{this.props.title}</h1>
            {this.context.mobile && (
              <button
                className="filter-filterOpenButton"
                onClick={this.toggleFilter.bind(this)}
              >
                {this.state.open ? "filter sluiten" : "filter openen"}
              </button>
            )}
          </div>
          {this.state.open &&
            optionGroups.map((group) => (
              <CheckboxGroup
                key={group.title}
                title={group.title}
                onToggle={this.toggleCheckbox.bind(this)}
              >
                {group.options.map((property) => (
                  <Checkbox
                    key={property.value}
                    id={property.value}
                    label={property.label}
                    val={property.checked}
                    onToggle={this.toggleCheckbox.bind(this)}
                  />
                ))}
              </CheckboxGroup>
            ))}
        </div>
      </div>
    );
  }
}
