import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Card from "../card/card";
import DoughnutChart from "../charts/doughnut/doughnut-chart";
import VerticalbarChart from "../charts/verticalbar/verticalbar-chart";
import "./statistics-charts.css";

class StatisticsCharts extends Component {
  onAction(key, id) {
    this.props.history.push(`/${key}`, {
      filters: this.props.filters
    });
  }

  render() {
    if (this.props.statistics === null) return false;

    const {
      customerSatisfactionAverage,
      customerSatisfactionCount,
      weightLossGreenPercentage,
      weightLossOrangePercentage,
      weightLossRedPercentage,
    } = this.props.statistics;

    var progressionStats = [
      {
        tag: "bad",
        label: weightLossRedPercentage !== 0 && "< 0,5%",
        percentage: Math.round(weightLossRedPercentage),
      },
      {
        tag: "mediocre",
        label: weightLossOrangePercentage !== 0 && "0,5% - 1%",
        percentage: Math.round(weightLossOrangePercentage),
      },
      {
        tag: "good",
        label: weightLossGreenPercentage !== 0 && "> 1%",
        percentage: Math.round(weightLossGreenPercentage),
      },
    ];

    progressionStats = progressionStats.map((stat) => ({
      ...stat,
      color: "var(--color-" + stat.tag + ")",
    }));

    let app = document.querySelector("body");
    let doughnutColor = app
      ? getComputedStyle(app).getPropertyValue("--color-good")
      : "";

    return (
      <div className="chartStatistics">
        <Card
          title="Klanttevredenheid"
          action={{
            label: `Bekijk ${customerSatisfactionCount} beoordelingen`,
            handler: this.onAction.bind(this, "reviews"),
          }}
        >
          <DoughnutChart
            percentage={customerSatisfactionAverage}
            label={customerSatisfactionAverage}
            color={doughnutColor}
            subLabel={"gemiddelde\r\nbeoordeling"}
          />
        </Card>

        <Card
          title="Afvaltrajecten"
          action={{
            label: "Bekijk klanten",
            handler: this.onAction.bind(this, "clients"),
          }}
        >
          {weightLossGreenPercentage === 0 &&
          weightLossOrangePercentage === 0 &&
          weightLossRedPercentage === 0 ? (
            <>
              <div className="placeholderChart"></div>
              <span className="placeholderText">
                Geen afvaltrajecten van klanten om te laten zien
              </span>
            </>
          ) : (
            <VerticalbarChart stats={progressionStats} />
          )}
        </Card>
      </div>
    );
  }
}

export default withRouter(StatisticsCharts);
