import React, { Component } from "react";
import "../client-weightlogs-popup.css";
import LineChart from "../../../../app/charts/line/line-chart";

class Weightchart extends Component {
  render() {
    const { client } = this.props;
    return (
      client.clientGoal &&
      client.clientGoal.weeklyTarget && (
        <LineChart
          data={client.weightMetrics}
          target={client.clientGoal.weeklyTarget}
        />
      )
    );
  }
}

export default Weightchart;
