import React, { Fragment } from "react";
import { Redirect } from "react-router";
import BaseComponent from "../../app/base-component";
import moment from "moment-timezone";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Topbar from "../../app/topbar/topbar";
import Table from "../../app/table/table";
import Sidebar from "../../app/sidebar/sidebar";
import DeleteButton from "../../app/delete-button/delete-button";
import ActivityFilter from "./activity-filter";
import ChevronDown from "../../app/custom-icons/chevron-down";
import { withRouter } from "react-router-dom";
import "./activities-page.css";

class ActivitiesPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      activities: null,
      statistics: null,
      sortQuery: null,
      filterQuery: null,
      filters: {},
      calculationType: "total",
      calculationSetterVisible: false,
      userRole: context.user && context.user.role,
      pagination: {},
    };
  }

  componentDidMount() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      let s = this.props.location.state;
      let filterQuery = "";
      if (s.trainer) {
        filterQuery = `TrainerName@=${s.trainer}`;
      } else if (s.client) {
        filterQuery = `ClientName@=${s.client}`;
      }
      this.setStateAsync({
        filterQuery: filterQuery,
      }).then(() => this.getActivities());
    } else {
      this.getActivities();
    }
  }

  getActivities(page = 1, pageSize = null) {
    if(this.context.user && this.state.userRole === 'Trainer') {
      return this.setState({
        activities: [],
      }); 
    }

    pageSize = pageSize || this.state.pagination.pageSize || 25;

    this.setState({
      activities: null,
    });

    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.buildFilterQuery({
      date: {
        filterKey: "Date",
        type: "range",
        itemTransform: (v) => moment(v).toISOString(),
      },
      price: {
        filterKey: "Price",
        type: "range",
        itemTransform: (v) => parseFloat(v.replace(",", ".")).toFixed(2),
      },
      statuses: {
        filterKey: "Status",
        type: "select",
      },
      gyms: {
        filterKey: "GymName",
        type: "select",
      },
    });

    return new ApiClient(this.context)
      .call("GET", "/v1/Activity", null, false, {}, false, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
        'Page': page,
        'PageSize': pageSize,
      })
      .then((response) => {
        if (!response.ok) return;

        let data = response.json;

        const statistics = {
          average: data.results.average,
          total: data.results.total,
          count: data.results.count,
        };

        const pagination = {
          currentPage: data.currentPage,
          pageCount: data.pageCount,
          pageSize: data.pageSize,
          totalCount: data.totalCount
        };

        return this.setStateAsync({
          activities: data.results.values,
          statistics: statistics,
          pagination: pagination
        });
      });
  }

  toggleCalculationSetter() {
    this.setState({
      calculationSetterVisible: !this.state.calculationSetterVisible,
    });
  }

  updateSort(query) {
    this.setStateAsync((state) => ({
      sortQuery: query,
    })).then(this.getActivities.bind(this));
  }

  updateTableFilter(query) {
    this.setStateAsync((state) => ({
      filterQuery: query,
    })).then(this.getActivities.bind(this));
  }

  updateFilter(key, value) {
    return this.setStateAsync((state) => ({
      filters: {
        ...state.filters,
        [key]: value,
      },
    })).then(() => {
      return this.getActivities();
    });
  }

  mapStatus(status) {
    switch (status) {
      case "SCHEDULED":
        return <span className="status statusScheduled">Gepland</span>;
      case "COMPLETED":
        return <span className="status statusCompleted">Afgerond</span>;
      case "INVOICED":
        return <span className="status statusInvoiced">Gefactureerd</span>;
      case "CANCELLED":
        return <span className="status statusCancelled">Geannuleerd</span>;
      case "CANCELLED_FREE":
        return <span className="status statusCancelledFree">Gratis geannuleerd</span>
      case "DELETED":
        return <span className="status statusDeleted">Verwijderd</span>
      case "INVOICED_CREDIT":
        return <span className="status statusInvoicedCredit">Gefactureerd krediet</span>
      case "RESCHEDULED":
        return <span className="status statusRescheduled">Verplaatst</span>
      case "RESCHEDULED_CANCELLED":
        return <span className="status statusRescheduledCancelled">Gratis verplaatst</span>
      case "RESCHEDULED_COMPLETED":
        return <span className="status statusRescheduledCompleted">Verplaatst voltooid</span>
      default:
        return null;
    }
  }

  mapDataToTable() {
    return (
      this.state.activities &&
        this.state.activities.map((activity) => ({
          ...activity.values,
          key: Math.random(),
          date: moment(activity.date).format("DD-MM-YYYY"),
          client: activity.client.firstName + " " + activity.client.lastName,
          trainer: activity.trainer.firstName + " " + activity.trainer.lastName,
          activity: activity.product.name,
          gym: activity.gym.name,
          price: activity.price.toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
          }),
          status: this.mapStatus(activity.status),
          delete: <DeleteButton />,
        }))
    );
  }

  formatTotal() {
    return parseFloat(this.state.statistics.total).toLocaleString("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
  }

  formatCount() {
    return this.state.statistics.count + " activiteiten";
  }

  formatAverage() {
    return parseFloat(this.state.statistics.average).toLocaleString("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
  }

  setCalculation(calculationType) {
    this.setState((state) => ({
      calculationType: calculationType,
    }));
  }

  updatePagination(page, pageSize) {
    this.getActivities(page, pageSize);
  }

  render() {
    const role = this.state.userRole;
    const admin = role === 'Admin' || role === 'GlobalAdmin' || role === 'ClubManager';

    let calculationMapping = {
      total: this.formatTotal.bind(this),
      count: this.formatCount.bind(this),
      average: this.formatAverage.bind(this),
    };
    let calculationValue = this.state.statistics
      ? calculationMapping[this.state.calculationType]()
      : "";

    return (
      <div className="activitiesPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="activitiesPage-container">
          <Topbar title="Activiteiten" />

          <div className="activitiesPage-mainContent">
            {!admin 
              ? <div className="activitiesPage-trainerRole">Activiteiten zijn voor trainers niet beschikbaar</div>
              : <Fragment>
                  <ActivityFilter updateFilter={this.updateFilter.bind(this)} />
                  <Table
                     pagination={{
                      ...this.state.pagination, 
                      onPageChange: this.updatePagination.bind(this),
                    }}
                    filterQuery={this.state.filterQuery}
                    updateFilter={this.updateTableFilter.bind(this)}
                    sortQuery={this.state.sortQuery}
                    updateSort={this.updateSort.bind(this)}
                    headers={[
                      {
                        title: "Datum",
                        property: "date",
                        filterKey: "Date",
                        filterable: false,
                      },
                      {
                        title: "Klant",
                        property: "client",
                        filterKey: "ClientName",
                      },
                      {
                        title: "Trainer",
                        property: "trainer",
                        filterKey: "TrainerName",
                      },
                      {
                        title: "Activiteit",
                        property: "activity",
                        filterKey: "ProductName",
                      },
                      {
                        title: "Gym",
                        property: "gym",
                        filterKey: "GymName",
                        filterable: false,
                      },
                      {
                        title: "€",
                        property: "price",
                        filterKey: "Price",
                        filterable: false,
                      },
                      {
                        title: "Status",
                        property: "status",
                        filterKey: "Status",
                        filterable: false,
                      },
                      { property: "delete" },
                    ]}
                    data={this.mapDataToTable()}
                    footers={[
                      { property: "date" },
                      { property: "client" },
                      { property: "trainer" },
                      { property: "activity" },
                      { property: "gym" },
                      {
                        property: "price",
                        title: calculationValue,
                        button: (
                          <button
                            className="arrowDown"
                            onClick={this.toggleCalculationSetter.bind(this)}
                          >
                            <ChevronDown />

                            {this.state.calculationSetterVisible && (
                              <div className="calculationSetter">
                                <button
                                  onClick={this.setCalculation.bind(this, "total")}
                                >
                                  Totaal
                                </button>
                                <button
                                  onClick={this.setCalculation.bind(this, "count")}
                                >
                                  Aantal
                                </button>
                                <button
                                  onClick={this.setCalculation.bind(this, "average")}
                                >
                                  Gemiddelde
                                </button>
                              </div>
                            )}
                          </button>
                        ),
                      },
                      { property: "status" },
                      { property: "delete" },
                    ]}
                  />
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActivitiesPage);
