import React, { Component } from "react";
import moment from "moment-timezone";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import PopupOverlay from "../../../app/popup/popup-overlay";
import placeholderImg from "../../../app/image/placeholder-image.png";
import InputWithLabel from "../../../app/input/input-with-label";
import MFImage from "../../../app/image/image";
import "./clients-popup.css";

class ClientsPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let gyms = props.gyms.map((gym) => ({
      ...gym,
      value: gym.id,
      label: gym.name,
    }));

    let trainers = props.trainers.map((trainer) => ({
      ...trainer,
      value: trainer.id,
      label: trainer.fitUser.firstName + " " + trainer.fitUser.lastName,
    }));

    this.state = {
      client: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        birthdate: "",
        street: "",
        houseNumber: "",
        zipCode: "",
        city: "",
        gymId: "",
        trainerId: "",
      },
      gyms: gyms,
      trainers: trainers,
    };
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  onSelect(key, val) {
    this.setState((state) => ({
      client: { ...state.client, [key]: val },
    }));
  }

  createClient() {
    let client = this.state.client;
    const requiredVals =
      client.firstName &&
      client.lastName &&
      client.phoneNumber &&
      client.email &&
      client.street &&
      client.houseNumber &&
      client.zipCode &&
      client.city &&
      client.trainerId &&
      client.gymId;

    let minimumDate = moment().subtract("12", "years").toISOString();
    let validBirthdate = moment(client.birthdate).isBefore(minimumDate);

    if (!requiredVals) {
      return this.context.addNotification(
        "warning",
        "Zijn alle velden ingevuld?"
      );
    } else if (!validBirthdate) {
      return this.context.addNotification(
        "warning",
        "Ben je ouder dan 12 jaar?"
      );
    } else {
      new ApiClient(this.context)
        .call("POST", "/v1/Client", client)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.context.addNotification("success", "Klant is aangemaakt");
          } else {
            this.context.addNotification("error", "Er is een fout opgetreden");
          }
        });
    }
  }

  render() {
    let gymId = this.state.client.gymId;
    let trainers = this.state.trainers
      .map((t) => ({
        gymId: t.gym.id,
        value: t.id,
        label: t.fitUser.firstName + " " + t.fitUser.lastName,
      }))
      .filter((trainer) => trainer.gymId.includes(gymId));

    return (
      <div className="clients-popup">
        <PopupOverlay
          actions={[
            { label: "Klant opslaan", handler: this.createClient.bind(this) },
          ]}
          title="Klant aanmaken"
          onDismiss={this.props.onDismiss}
          img={
            <MFImage image={null} alt="" placeholder={placeholderImg} />
          }
          placeholder
        >
          <div className="clientsPopup-formContainer">
            <div className="clientsPopup-section">
              <div className="clientsPopup-formRow">
                <InputWithLabel
                  text={true}
                  title="Voornaam"
                  value={this.state.client.firstName}
                  type="text"
                  name="firstName"
                  onInputChange={this.onInputChange.bind(this, "firstName")}
                />
                <InputWithLabel
                  text={true}
                  title="Achternaam"
                  value={this.state.client.lastName}
                  type="text"
                  name="lastName"
                  onInputChange={this.onInputChange.bind(this, "lastName")}
                />
              </div>
              <InputWithLabel
                date={true}
                title="Geboortedatum"
                value={this.state.client.birthdate}
                name="birthdate"
                onInputChange={this.onInputChange.bind(this, "birthdate")}
              />

              <div className="clientsPopup-formRow">
                <InputWithLabel
                  text={true}
                  title="Telefoonnummer"
                  value={this.state.client.phoneNumber}
                  type="text"
                  name="phoneNumber"
                  onInputChange={this.onInputChange.bind(this, "phoneNumber")}
                />
                <InputWithLabel
                  text={true}
                  title="E-mailadres"
                  value={this.state.client.email}
                  type="text"
                  name="email"
                  onInputChange={this.onInputChange.bind(this, "email")}
                />
              </div>

              <div className="clientsPopup-formRow">
                <InputWithLabel
                  text={true}
                  title="Straatnaam"
                  value={this.state.client.street}
                  type="text"
                  name="street"
                  onInputChange={this.onInputChange.bind(this, "street")}
                />
                <InputWithLabel
                  text={true}
                  title="Huisnummer"
                  value={this.state.client.houseNumber}
                  type="text"
                  name="houseNumber"
                  onInputChange={this.onInputChange.bind(this, "houseNumber")}
                />
              </div>

              <div className="clientsPopup-formRow">
                <InputWithLabel
                  text={true}
                  title="Stad"
                  value={this.state.client.city}
                  type="text"
                  name="city"
                  onInputChange={this.onInputChange.bind(this, "city")}
                />
                <InputWithLabel
                  text={true}
                  title="Postcode"
                  value={this.state.client.zipCode}
                  type="text"
                  name="zipCode"
                  onInputChange={this.onInputChange.bind(this, "zipCode")}
                />
              </div>
            </div>

            <div className="clientsPopup-section">
              <div className="clientsPopup-sectionHeaderContainer">
                <h1 className="clientsPopup-sectionTitle">Gym</h1>
                <div className="clientsPopup-sectionHorizontalLine"></div>
              </div>
              <div className="clientsPopup-formRow">
                <InputWithLabel
                  select={true}
                  title="Gym"
                  value={this.state.client.gymId}
                  name="gymId"
                  options={this.state.gyms}
                  onSelect={this.onSelect.bind(this, "gymId")}
                />
                <InputWithLabel
                  select={true}
                  title="Trainer"
                  value={this.state.client.trainerId}
                  name="trainerId"
                  options={trainers}
                  onSelect={this.onSelect.bind(this, "trainerId")}
                />
              </div>
            </div>
          </div>
        </PopupOverlay>
      </div>
    );
  }
}

export default ClientsPopup;
