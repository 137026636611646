import React, { Component } from 'react';
import TrainersPopup from '../../pages/trainers/trainers-popup/trainers-popup';
import AppContext from '../app-context';

class ShowProfile extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className='showProfile'>
        <TrainersPopup
          showOnly={true}
          onDismiss={this.props.toggleShowProfile}
        />
      </div>
    );
  }
}

export default ShowProfile;
