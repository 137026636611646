import React, { Component } from 'react';
import './outline-button.css';

class OutlineButton extends Component {
  renderButton() {
    return (
      this.props.actions &&
      this.props.actions.map(action => (
        <button 
          key={action.label} 
          className='outlineButton' 
          onClick={action.handler}
          disabled={action.disabled}
          style={{backgroundColor: action.disabled ? '#c7c7c7' : 'transparent', border: action.disabled ? 'none': '', cursor: action.disabled ? 'default': 'pointer'}}
        >
          {action.label}
        </button>
      ))
    );
  }
  render() {
    return <div>{this.renderButton()}</div>;
  }
}

export default OutlineButton;
