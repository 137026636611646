import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "./line-chart.css";
import moment from "moment";

class LineChart extends Component {
  renderLogs() {
    return this.props.data.map((data) => data.weight + " ");
  }
  sortArrayOfObjectsOnDate(array, key = "date") {
    return array.sort((a, b) => moment(a[key]).diff(moment(b[key])));
  }
  sortArrayOfObjectsOnKey(array, key) {
    return array.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  getNrOfWeeks() {
    let sortedInput = this.sortArrayOfObjectsOnDate(this.props.data);

    let start = moment(sortedInput[0].date);
    let startDay = start.get("day");
    let end = moment(sortedInput[sortedInput.length - 1].date);
    let difference = end.diff(start, "days");
    let additionalDaysInFirstWeek = startDay === 0 ? 0 : 7 - startDay;
    let numberOfWeeks =
      Math.ceil((difference - additionalDaysInFirstWeek) / 7) + 1;

    return numberOfWeeks;
  }

  render() {
    let sortedData = this.sortArrayOfObjectsOnDate(this.props.data).map(
      (item) => {
        return { date: item.date, weight: item.weight };
      }
    );
    //creating a data array with the weight, number of days since start, and number of week since start
    let startDate = sortedData[0].date;
    let startDay = moment(startDate).get("day");

    let additionalDaysInFirstWeek = startDay === 0 ? 0 : 7 - startDay;

    let data = sortedData.map((dataEntry) => {
      let week;
      if (dataEntry.date === startDate) {
        week = 1;
      } else {
        week =
          Math.ceil(
            (moment(dataEntry.date).diff(startDate, "days") -
              additionalDaysInFirstWeek) /
              7
          ) + 1;
      }

      return {
        weight: dataEntry.weight,
        day: moment(dataEntry.date).diff(moment(startDate), "days") + 1,
        week: week,
      };
    });

    //last entry is the last time the weight was logged
    let lastEntry = data[data.length - 1];
    let originalLength = data.length;
    for (let i = 0; i < originalLength - 1; i++) {
      if (data[i].day + 1 !== data[i + 1].day) {
        const deltaWeight = data[i + 1].weight - data[i].weight;
        const deltaDays = data[i + 1].day - data[i].day;
        const diff = deltaWeight / deltaDays;

        let count = 1;
        for (let n = data[i].day + 1; n < data[i + 1].day; n++) {
          data.push({
            weight: data[i].weight + count * diff,
            day: n,
            week: n / 7 + 1,
          });
          count++;
        }
      }
    }

    data = this.sortArrayOfObjectsOnKey(data, "day");

    //total days is the number of weeks of the journey times 7 days
    let totalDays = this.getNrOfWeeks() * 7;

    //compute the slope of weightloss over the passed time
    let difference = this.props.target / 100;

    //filling the data array regressionLine with the date for the straight line
    let regressionLine = [];
    for (let n = 0; n < lastEntry.day; n++) {
      const dailyLoss = (data[0].weight * difference) / 7;
      regressionLine.push({
        weight: data[0].weight - dailyLoss * n,
        day: n + 1,
        week: n / 7 + 1,
      });
    }
    //filling the data array up with empty entries until for every day of the weightloss journey there is an entry
    for (let i = lastEntry.day; i < totalDays; i++) {
      data.push({
        weight: null,
        day: i + 1,
        week: i / 7 + 1,
      });
    }
    let width, height, gradient;
    function getGradient(ctx, chartArea) {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );

        gradient.addColorStop(0, "rgba(255,255,255,0)");
        gradient.addColorStop(1, "#7db4e1");
      }

      return gradient;
    }

    let inputData = {
      labels: data.map((d) =>
        d.day % 7 === 1
          ? Math.ceil((d.day - additionalDaysInFirstWeek) / 7) + 1
          : " "
      ),
      datasets: [
        {
          label: "Doellijn",
          backgroundColor: "#2b6a93",
          borderColor: "#2b6a93",
          data: regressionLine.map((d) => d.weight),
        },
        {
          label: "Gewichten",
          fill: {
            target: "origin",
            above: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
          },
          backgroundColor: "#7db4e1",
          borderColor: "#7db4e1",
          data: data.map((d) => d.weight),
        },
      ],
    };
    return (
      <Line
        data={inputData}
        options={{
          borderJoinStyle: "round",
          pointRadius: 0,
          pointHoverRadius: 0,
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              ticks: {
                minRotation: 0,
                maxRotation: 0,
                sampleSize: 1,
              },
            },
          },
        }}
      />
    );
  }
}

export default LineChart;
