import React, { Component } from 'react';
import { Filter } from 'react-feather';
import './filter-button.css';

class FilterButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  updateSort(key) {
    this.props.updateSort(key);
  }

  render() {
    return (
      <div className='filterButton' onClick={this.updateSort.bind(this)}>
        <Filter 
          color={this.props.color}
          fill={this.props.fill} 
          size={17}  
        />
      </div>
    );
  }
}

export default FilterButton;
