export const statuses = [
  {
    label: 'Gepland',
    value: 'SCHEDULED',
  },
  {
    label: 'Afgerond',
    value: 'COMPLETED',
  },
  {
    label: 'Gefactureerd',
    value: 'INVOICED',
  },
  {
    label: 'Geannuleerd',
    value: 'CANCELLED',
  },
  {
    label: 'Gratis geannuleerd',
    value: 'CANCELLED_FREE',
  },
  {
    label: 'Verwijderd',
    value: 'DELETED',
  },
  {
    label: 'Gefactureerd krediet',
    value: 'INVOICED_CREDIT',
  },
  {
    label: 'Verplaatst',
    value: 'RESCHEDULED'
  },
  {
    label: 'Gratis verplaatst',
    value: 'RESCHEDULED_CANCELLED'
  },
  {
    label: 'Verplaatst voltooid',
    value: 'RESCHEDULED_COMPLETED'
  }
];