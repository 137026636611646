import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import AppContext from "../../app/app-context";
import InputWithLabel from "../../app/input/input-with-label";
import Sidebar from "../../app/sidebar/sidebar";
import Topbar from "../../app/topbar/topbar";
import "./settings-page.css";

const gyms = [
  {
    value: "Max Fit Elst",
    label: "Max Fit Elst",
  },
  {
    value: "Max Fit Nijmegen",
    label: "Max Fit Nijmegen",
  },
];

class SettingsPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      account: "",
      password: "",
      settings: {
        gymId: "",
      },
    };
  }

  onSelect(key, val) {
    this.setState((state) => ({
      settings: { ...state.settings, [key]: val },
    }));
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      [key]: val,
    }));
  }

  onClientsCheck() {
    this.props.history.push("/clients-check");
  }

  render() {
    const admin = this.context.user && (this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin');

    return (
      <div className="settingsPage">
        {!this.context.user && <Redirect to="login" />}
        {!admin && <Redirect to="dashboard" />}

        <Sidebar />
        <div className="settingsPage-container">
          <Topbar title="Instellingen" />

          <div className="settingsPage-mainContent">
            <div className="settingsPage-row">
              <div className="settingsPage-heading">
                <h1 className="settingsPage-title">Algemene instellingen</h1>
                <div className="title-horizontalLine"></div>
              </div>

              {/* Mockup Data */}
              <InputWithLabel
                select={true}
                title="Hoofdvestiging"
                value={this.state.settings.gymId}
                name="gymId"
                options={gyms}
                onSelect={this.onSelect.bind(this, "gymId")}
              />
            </div>

            <div className="settingsPage-row">
              <div className="settingsPage-heading">
                <h1 className="settingsPage-title">API instellingen</h1>
                <div className="title-horizontalLine"></div>
              </div>

              <div className="settingsPage-item">
                <h2 className="settingsPage-subtitle">
                  MoneyBird klanten extra check
                </h2>
                <button
                  className="settingsPage-button button-outline"
                  onClick={this.onClientsCheck.bind(this)}
                >
                  Bekijk klanten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SettingsPage);
