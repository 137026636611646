import React, { Component } from "react";
import {
  Trello,
  Calendar,
  Bell,
  Grid,
  Zap,
  Heart,
  Users,
  Clock,
  FileText,
  Settings,
  Star,
} from "react-feather";
import MenuItem from "./menu-item";
import Logo from "../logo/logo";
import Dumbbell from "../custom-icons/dumbbell-icon";
import AppContext from "../app-context";
import "./sidebar.css";

class Sidebar extends Component {
  static contextType = AppContext;

  render() {
    let admin = this.context.user &&
      (this.context.user.role === 'Admin' || this.context.user.role === 'GlobalAdmin');

    return (
      <div
        style={
          this.context.mobile
            ? {
                transform: `translateX(${
                  this.context.hamburgerOpen ? "0" : "-100%"
                })`,
              }
            : {}
        }
        className={`sidebar${!this.props.expanded ? " closed" : ""}`}
      >
        <div className="sidebarContainer">
          <div className="sidebar-top">
            {this.context.mobile && (
              <button
                className="hamburgerButton"
                onClick={this.context.toggleHamburgerMenu}
              >
                <svg
                  className="hamburgerButtonSVG"
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    className={"line topLineActive"}
                    x1="3"
                    y1="3"
                    x2="97"
                    y2="3"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <line
                    className={"line middleLine"}
                    x1="3"
                    y1="50"
                    x2="97"
                    y2="50"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <line
                    className={"line bottomLineActive"}
                    x1="3"
                    y1="97"
                    x2="97"
                    y2="97"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            )}
            <div className="sidebar-topContent">
              <Logo fill={"var(--color-blue-800)"} width="32" heigt="32" />
              {this.props.expanded && <h1>maxfit</h1>}
            </div>
          </div>
          <div className={"sidebar-menu"}>
            <div className="sidebar-menuItems">
              <MenuItem target="/dashboard" icon={Trello}>
                Dashboard
              </MenuItem>
              <MenuItem target="/agenda" icon={Calendar}>
                Agenda
              </MenuItem>
              <MenuItem target="/notification" icon={Bell}>
                Notificaties
              </MenuItem>
              <MenuItem target="/clubs" icon={Grid}>
                Clubs
              </MenuItem>
              <MenuItem target="/trainers" icon={Zap}>
                Team
              </MenuItem>
              <MenuItem target="/products" icon={Heart}>
                Diensten
              </MenuItem>
              <MenuItem target="/clients" icon={Users}>
                Klanten
              </MenuItem>
              <MenuItem target="/activities" icon={Dumbbell}>
                Trainingen
              </MenuItem>
              <MenuItem target="/hours" icon={Clock}>
                Urenoverzicht
              </MenuItem>
              <MenuItem target="/reviews" icon={Star}>
                Beoordelingen
              </MenuItem>
              <MenuItem target="/invoices" icon={FileText}>
                Facturen
              </MenuItem>
              {admin && (
                <MenuItem target="/settings" icon={Settings}>
                  Instellingen
                </MenuItem>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
