import React, { Component } from 'react';

class ChevronDown extends Component {
  render() {
    return (
      <svg width="14" height="9" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.548768 1.37807L4.82078 5.64989C5.10328 5.93237 5.56469 5.93237 5.84719 5.64989L10.1192 1.37807C10.5775 0.919819 10.2542 0.138275 9.60443 0.138275H1.06354C0.413796 0.138275 0.0904916 0.919819 0.548768 1.37807V1.37807Z" fill="#000" />
    </svg>
    );
  }
}

export default ChevronDown;


