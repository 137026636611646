import React from "react";
import moment from "moment-timezone";
import { RRule } from "rrule";
import BaseComponent from "../../../app/base-component";
import AppContext from "../../../app/app-context";
import InputWithLabel from "../../../app/input/input-with-label";
import PopupOverlay from "../../../app/popup/popup-overlay";
import InputLabel from "../../../app/input/label-input/label-input";
import SelectAsync from "react-select/async";
import TimeInput from "../../../app/input/time-input/time-input";
import ApiClient from "../../../api-client/api-client";
import CancelAppointmentPopup from "../cancel-appointment-popup/cancel-appointment-popup";
import RecurrencePopup from "../recurrence-popup/recurrence-popup";
import AppointmentTypePopup from "../appointment-type-popup/appointment-type-popup";
import RemoveClientsPopup from '../remove-clients-popup/remove-clients-popup';
import Checkbox from "../../../app/input/checkbox/checkbox";
import Loader from "../../../app/loader/loader";
import { ActiveStatusses, InactiveStatusses, RescheduledStatusses, Status, Type } from "../status";
import { components } from 'react-select';
import { Plus, X } from "react-feather";
import "./calendar-popup.css";

const { MultiValueRemove } = components;

class AgendaPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var newSlotAppointment = props.newSlotEvent ? {
      trainerId: props.newSlotEvent.trainerId,
      gymId: props.newSlotEvent.gymId,
      date: moment(props.newSlotEvent.start).format("YYYY-MM-DD"),
      startTime: moment(props.newSlotEvent.start).format("HH:mm"),
      endTime: moment(props.newSlotEvent.end).format("HH:mm"),
    } : {};
    
    var defaultAppointment = {
      trainerId: "",
      gymId: "",
      productId: "",
      note: "",
      date: moment().format("YYYY-MM-DD"),
      startTime: moment().startOf("hour").format("HH:mm"),
      endTime: moment().startOf("hour").format("HH:mm"),
      recurrenceRule: {},
      clients: [],
      sendEmailConfirmation: false,
      ...newSlotAppointment
    };

    if (props.appointment) {
      defaultAppointment = {
        id: props.appointment.id,
        trainerId: props.appointment.trainer.id,
        gymId: props.appointment.gym.id,
        productId: props.appointment.product.id,
        date: moment(props.appointment.start).format("YYYY-MM-DD"),
        startTime: moment(props.appointment.start).format("HH:mm"),
        endTime: moment(props.appointment.end).format("HH:mm"),
        recurrenceRule: props.appointment.recurrenceRule,
        clients: props.appointment.clients,
        note: props.appointment.note,
        sendEmailConfirmation: false,
      };
    }

    let trainers =
      (props.trainers &&
        props.trainers.map((trainer) => ({
          ...trainer,
          value: trainer.id,
          label: trainer.fitUser.firstName + " " + trainer.fitUser.lastName,
        }))) ||
      [];

    let gyms =
      (props.gyms &&
        props.gyms.map((gym) => ({
          ...gym,
          value: gym.id,
          label: gym.name,
        }))) ||
      [];
    
    let products =
      (props.products &&
        props.products.map((product) => ({
          ...product,
          value: product.id,
          label: product.name,
        }))) ||
      [];

    this.state = {
      trainers: trainers,
      gyms: gyms,
      products: products,
      appointment: defaultAppointment,
      originalAppointment: {...defaultAppointment},
      sendEmailConfirmation: false,
      rescheduleAppointment: false,

      isLoading: false,
    };
  }
  
  componentDidUpdate(prevProp, prevState) {
    let keys = Object.keys(prevState.appointment)
      .concat(Object.keys(this.state.appointment))
      .filter((v, i, s) => s.indexOf(v) === i);

    keys.forEach((key) => {
      if (prevState.appointment[key] !== this.state.appointment[key]) {
        let seFn = this[`${key}DidUpdate`];

        if (typeof seFn === "function") {
          seFn.apply(this, [
            prevState.appointment[key],
            this.state.appointment[key],
          ]);
        }
      }
    });
  }

  onInputChange(key, val) {
    this.setState((state) => ({
      appointment: { ...state.appointment, [key]: val },
    }));
  }

  onTextAreaChange(e) {
    this.setState(state => ({
      appointment: {...state.appointment, note: e.target.value}
    }));
  }

  onSelectChange(key, val) {
    this.setState((state) => ({
      appointment: { ...state.appointment, [key]: val },
    }));
  }

  loadMultiSelectOptions(canSelectMore, searchTerm, callback) {
    if(!canSelectMore) {
      callback([]);
      return;
    }

    return new ApiClient(this.context)
      .call("GET", "/v1/Client", null, false, {}, false, {
        'Filters': `ShouldValidateImportedData==false,Active==true,Name@=*${searchTerm}`,
        'PageSize': 10,
      })
      .then((response) => {
        callback(response.json.results);
      });
  }

  onMultiSelectChange(selectedClients, {action}) {
    let isClearAction = action === 'clear';

    this.setState((state) => {
      let clients = state.appointment.clients;
      let clientIds = clients.map(c => c.id);
      let originalClients = state.originalAppointment.clients;
      let selectedClientIds = selectedClients.map(c => c.id);
      let newClients = selectedClients.filter(c => !clientIds.includes(c.id));

      return {
        appointment: {
          ...state.appointment,
          clients: [...clients, ...newClients].map(c => {
              let originalClient = originalClients.find(c2 => c2.id === c.id) || {};
              let originalStatus = originalClient.status;
              let currentStatus = c.status || Status.Scheduled.name;
              var status = currentStatus;

              let toggled = !selectedClientIds.includes(c.id)
                && !(isClearAction && InactiveStatusses.includes(currentStatus));

              if(toggled) {
                if(ActiveStatusses.includes(currentStatus)) {
                  if(InactiveStatusses.includes(originalStatus)) {
                    status = originalStatus;
                  } else if (RescheduledStatusses.includes(originalStatus)) {
                    status = Status.RescheduledCancelled.name;
                  } else if(ActiveStatusses.includes(originalStatus)) {
                    status = Status.Cancelled.name;
                  } else {
                    return null;
                  }
                } else {
                  if(ActiveStatusses.includes(originalStatus)) {
                    status = originalStatus;
                  } else if (RescheduledStatusses.includes(originalStatus)) {
                    status = Status.Rescheduled.name;
                  } else {
                    status = Status.Scheduled.name;
                  }
                }
              }

              return {
                ...c,
                status: status
              }
            }).filter(c => !!c)
        },
      };
    });
  }

  showPopup(key) {
    const self = this;

    return new Promise((resolve, reject) => {
      let fullKey = `${key}Popup`;
      let hidePopup = () => self.setStateAsync({
        [fullKey]: null
      });
  
      return self.setStateAsync(state => ({
        [fullKey]: {
          onComplete: (...args) => hidePopup().then(() => resolve(args)),
          onDismiss: () => hidePopup().then(() => reject()),
        },
      })) 
    });
  }

  onToggle(key) {
    this.setState(state => ({
      [key]: !state[key]
    }));
  }

  productIdDidUpdate(prevProductId, productId) {
    this.recalculateEndTime();
  }

  startTimeDidUpdate(prevStartTime, startTime) {
    this.recalculateEndTime();
  }

  recalculateEndTime() {
    this.setState((state) => {
      let productId = state.appointment.productId;
      let product = state.products.find((p) => p.id === productId);
      let startTime = moment(state.appointment.startTime, ["HH:mm"]);
      let endTime = startTime.clone().add(product.duration, "minutes");

      return {
        appointment: {
          ...state.appointment,
          endTime: endTime.format("HH:mm"),
        },
      };
    });
  }

  onSubmit() {
    if (this.props.appointment) {
      this.updateAppointment();
    } else {
      this.createAppointment();
    }
  }

  createAppointment() {
    let appointment = this.state.appointment;
    let activeClients = this.getActiveClients();
    let date = appointment.date;
    let startTime = appointment.startTime;
    let endTime = appointment.endTime;
    
    let clients = activeClients.map(c => ({
      clientId: c.id, 
      status: this.state.rescheduleAppointment
        ? Status.Rescheduled.name
        : c.status
    }));

    let rule = appointment.recurrenceRule
      ? appointment.recurrenceRule.rule
      : "";
    let product = this.state.products.find(
      (p) => p.id === appointment.productId
    );
    let sendEmailConfirmation = this.state.sendEmailConfirmation;

    let newAppointment = {
      ...this.state.appointment,
      start: moment(date + " " + startTime).toISOString(),
      end: moment(date + " " + endTime).toISOString(),
      clients: clients,
      recurrenceRule: rule,
      sendEmailConfirmation: sendEmailConfirmation
    };

    if (clients.length === 0) {
      this.context.addNotification("warning", "Zijn er klanten geselecteerd?");
    } else if (product.trainingType === "SOLO" && clients.length !== 1) {
      this.context.addNotification("warning", "Er zijn te veel deelnemers geselecteerd voor dit product.");
    } else if (endTime < startTime) {
      this.context.addNotification("warning", "Het is niet mogelijk om een eindtijd te hebben eerder dan de starttijd");
    } else {
      this.setStateAsync(({
        isLoading: true,
      }))
      .then(() => {
        new ApiClient(this.context)
        .call("POST", "/v1/Appointment", newAppointment)
        .then((result) => {
          if (result.ok) {
            this.setStateAsync(({
              isLoading: false,
            }))
            .then(() => {
              this.props.onDismiss();
              this.props.refreshList();
              this.context.addNotification('success', 'Afspraak is ingepland');
            });
          } else {
            if(result.json.errors.Clients) {
              result.json.errors.Clients.map(e => this.context.addNotification('error',e));
            } else if(result.json.errors.RecurrenceRule) {
              result.json.errors.RecurrenceRule.map(e => this.context.addNotification('error', e));
            }
            
            this.setState({isLoading: false});
          }
        });
      });
    }
  }

  cancelAppointment([status, type]) {
    let appointment = this.state.appointment;
    const date = appointment.date;
    const startTime = appointment.startTime;
    const endTime = appointment.endTime;
    const rule = appointment.recurrenceRule
      ? appointment.recurrenceRule.rule
      : null;

    const statusClients = status === Status.Deleted.name 
      ? Status.Deleted.name 
      : status;
    const mappedClients = appointment.clients.map(c => ({
      clientId: c.id,
      status: statusClients
    }));

    let updatedAppointment = {
      ...appointment,
      clients: mappedClients,
      start: moment(date + " " + startTime).toISOString(),
      end: moment(date + " " + endTime).toISOString(),
      recurrenceRule: rule,
      appointmentUpdateType: type,
    };

    return this.setStateAsync({
      isLoading: true
    })
    .then(() => new ApiClient(this.context)
      .call('PUT', '/v1/Appointment/' + updatedAppointment.id, updatedAppointment))
    .then(result => {
      if (result.ok) {
        return this.setStateAsync({
          isLoading: false,
        })
        .then(() => {
          this.props.onDismiss();
          this.props.refreshList();
          this.context.addNotification('success', status === Status.Deleted.name 
            ? 'Afspraak is permanent geannuleerd'
            : 'Afspraak is geannuleerd');
        });
      } else {
        this.context.addNotification('error', 'Afspraak annuleren is niet gelukt');
        this.props.onDismiss();
      }
    });
  }

  updateRecurrence([updatedRule]) {
    return this.setStateAsync((state) => ({
      appointment: {
        ...state.appointment,
        recurrenceRule: {
          ...state.appointment.recurrenceRule,
          rule: updatedRule.rule,
        },
      },
    }));
  }

  getAddedClients(state) {
    state = state || this.state;

    let clients = state.appointment.clients;
    let originalClients = state.originalAppointment.clients;

    return clients.filter((c1) => {
      let c2 = originalClients.find(c3 => c3.id === c1.id);
      let exists = !!c2;
      let alreadyAdded = exists && ActiveStatusses.includes(c2.status);
      let added = !alreadyAdded && ActiveStatusses.includes(c1.status);

      return added;
    });
  }

  getActiveClients(state) {
    state = state || this.state;
    return state.appointment.clients.filter(c => ActiveStatusses.includes(c.status));
  }

  getRemovedClients(state) {
    state = state || this.state;

    let clients = state.appointment.clients;
    let originalClients = state.originalAppointment.clients;

    return clients.filter((c1) => {
      let c2 = originalClients.find(c3 => c3.id === c1.id);
      let exists = !!c2;
      let alreadyRemoved = !exists || InactiveStatusses.includes(c2.status);
      let removed = !alreadyRemoved && InactiveStatusses.includes(c1.status);

      return removed;
    });
  }

  promptClientCancelTypes() {
    let removedClients = this.getRemovedClients();
    if(removedClients.length === 0) {
      return Promise.resolve();
    }
    
    return this.showPopup('cancelClients')
      .then(([clientsToRemove]) => {
        return this.setStateAsync(state => ({
          appointment: {
            ...state.appointment,
            clients: state.appointment.clients.map(client => ({
              ...client,
              status: clientsToRemove
                .filter(c => c.id === client.id)
                .map(c => c.status)[0] || client.status
            })),
          },
        }));
    })
  }

  promptUpdateAppointmentType() {
    if(!this.state.originalAppointment.recurrenceRule) {
      var type = Type.All.name;
      if(!this.state.appointment.recurrenceRule) {
        type = Type.Single.name;
      }

      return this.setStateAsync(state => ({
        appointment: {
          ...state.appointment,
          appointmentUpdateType: type,
        }
      }));
    }
    
    return this.showPopup('appointmentUpdateType')
      .then(([type]) => {
        return this.setStateAsync(state => ({
          appointment: {
            ...state.appointment,
            appointmentUpdateType: type,
          },
        }));
      });
  }

  updateAppointmentApiCall() {
    let appointment = this.state.appointment;
    let date = appointment.date;
    let startTime = appointment.startTime;
    let endTime = appointment.endTime;
    
    let activeClients = this.getActiveClients();
    let addedClients = this.getAddedClients();
    let originalClients = this.state.originalAppointment.clients;
    let originalClientIds = originalClients.map(c => c.id);
    let addedClientIds = addedClients.map(c => c.id);
    let clients = appointment.clients
      .filter(client => ActiveStatusses.includes(client.status) || originalClientIds.includes(client.id))
      .map(client => {
        var status = client.status;

        if(this.state.rescheduleAppointment && addedClientIds.includes(client.id)) {
          status = Status.Rescheduled.name;
        } 
        
        return {
          clientId: client.id,
          status: status
        };
      });

    let rule = appointment.recurrenceRule
      ? appointment.recurrenceRule.rule
      : "";
    
    let product = this.state.products
      .find(p => p.id === appointment.productId);
    let sendEmailConfirmation = this.state.sendEmailConfirmation;

    let updatedAppointment = {
      start: moment(date + " " + startTime).toISOString(),
      end: moment(date + " " + endTime).toISOString(),
      recurrenceRule: rule,
      clients: clients,
      appointmentUpdateType: appointment.appointmentUpdateType,
      sendEmailConfirmation: sendEmailConfirmation,
      gymId: appointment.gymId,
      productId: appointment.productId,
      trainerId: appointment.trainerId,
      note: appointment.note
    };

    if (activeClients.length === 0) {
      this.context.addNotification("warning", "Zijn er klanten geselecteerd?");
    } else if (product.trainingType === "SOLO" && activeClients.length !== 1) {
      this.context.addNotification("warning", "Er zijn te veel deelnemers geselecteerd voor dit product.");
    } else if (endTime < startTime) {
      this.context.addNotification("warning", "Het is niet mogelijk om een eindtijd te hebben eerder dan de starttijd");
    } else {
      this.setStateAsync(({
        isLoading: true,
      })).then(() => {
        new ApiClient(this.context)
          .call("PUT", "/v1/Appointment/" + appointment.id, updatedAppointment)
          .then((result) => {
            if (result.ok) {
              this.setStateAsync({
                isLoading: false,
              }).then(() => {
                this.props.onDismiss();
                this.props.refreshList();
                this.context.addNotification("success", "Afspraak is gewijzigd");
              });
            } else {
              if(result.json.errors.Clients) {
                result.json.errors.Clients.map(e => this.context.addNotification('error', e));
              } else if(result.json.errors.RecurrenceRule) {
                result.json.errors.RecurrenceRule.map(e => this.context.addNotification('error', e));
              }

              this.setState({
                isLoading: false
              });
            }
          }); 
      })
    }
  }

  updateAppointment() {
    this.promptUpdateAppointmentType()
      .then(this.promptClientCancelTypes.bind(this))
      .then(this.updateAppointmentApiCall.bind(this));
  }

  renderText() {
    if (this.props.appointment.recurrenceRule !== null 
      && this.props.appointment.recurrenceRule.rule !== null) {
      let rrule = RRule.parseString(this.props.appointment.recurrenceRule.rule);

      let intervalMoreThanOne = rrule.interval > 1;

      let string = intervalMoreThanOne
        ? `Keert terug elke ${rrule.interval}`
        : `Keert terug`;
      let s = '';
      let u = '';

      switch (rrule.freq) {
        case RRule.WEEKLY:
          s = intervalMoreThanOne
            ? `weken${
                rrule.byweekday
                  ? ` op ${this.renderRecurringText(rrule.byweekday)}.`
                  : '.'
              }`
            : `elke week${
                rrule.byweekday
                  ? ` op ${this.renderRecurringText(rrule.byweekday)}.`
                  : '.'
              }`;
          break;
        case RRule.DAILY:
          s = intervalMoreThanOne ? `dagen.` : `elke dag.`;
          break;
        case RRule.MONTHLY:
          s = intervalMoreThanOne ? `maanden.` : `elke maand.`;
          break;
        case RRule.YEARLY:
          s = intervalMoreThanOne ? `jaren.` : `elk jaar.`;
          break;
        default:
          break;
      }

      if (rrule.until !== undefined) {
        u = `Tot ${moment(rrule.until).format('dddd DD MMMM YYYY')}.`;
      }
      if (rrule.count !== undefined) {
        u = `Voor ${rrule.count} keer.`;
      }
      return <span>{`${string} ${s} ${u}`}</span>;
    } else {
      return <span></span>;
    }
  }

  renderRecurringText(byweekday) {
    byweekday = byweekday.map(weekday => weekday.weekday);
    let days = [
      {id: 0, label: 'maandag'},
      {id: 1, label: 'dinsdag'},
      {id: 2, label: 'woensdag'},
      {id: 3, label: 'donderdag'},
      {id: 4, label: 'vrijdag'},
      {id: 5, label: 'zaterdag'},
      {id: 6, label: 'zondag'},
    ];

    let filteredDays = days
      .filter(day => byweekday.includes(day.id))
      .map(day => day.label);

    if (filteredDays.length === 1) {
      return filteredDays[0];
    } else if (filteredDays.length === 2) {
      return filteredDays.join(` en `);
    } else {
      return `${filteredDays.slice(0, -1).join(', ')} en ${
        filteredDays[filteredDays.length - 1]
      }`;
    }
  }
  
  renderRecurrenceButton() { 
    let recurrenceRule = this.props.appointment && this.props.appointment.recurrenceRule;
    if(recurrenceRule) {
      return (
        <div className="agenda-popupFormRow">
          <p className="editRecurrence-text">
            {this.renderText()}
            <span
              className="editRecurrence-edit"
              onClick={() => this.showPopup('recurrence').then(this.updateRecurrence.bind(this))}
            >
              Terugkeerpatroon bewerken
            </span>
          </p>
        </div>
      )
    } else {
      return (
        <div className="agenda-popupFormRow">
          <div className="agenda-recurrence">
            <button
              onClick={() => this.showPopup('recurrence').then(this.updateRecurrence.bind(this))}
              className="popup-popupButton"
            >
              Terugkeerpatroon
            </button>
          </div>
        </div>
      )
    }
  }

  mapClientStatusColor(status) {
    var color;

    if(status === Status.Cancelled.name) {
      color = 'var(--color-red-500)';
    } else if (status === Status.CancelledFree.name) {
      color = 'var(--color-green-500)';
    } else if(status === Status.Deleted.name) {
      color = 'var(--color-white)';
    } else if(RescheduledStatusses.includes(status)) {
      color = 'var(--color-blue-500)';
    } else {
      color = 'var(--color-gray-500)';
    }

    return color;
  }

  render() {
    let appointment = this.state.appointment;
    let gymId = appointment.gymId;
    let trainers = this.state.trainers
      .map((t) => ({
        id: t.id,
        gymId: t.gym.id,
        value: t.id,
        label: t.fitUser.firstName + " " + t.fitUser.lastName,
      }))
      .filter((trainer) => trainer.gymId.includes(gymId));
    let product =
      this.state.products.find(
        (p) => p.id === appointment.productId
      ) || {};
    let isSolo = product.trainingType === "SOLO";
    let activeClients = this.getActiveClients();
    let clientAmount = activeClients.length;
    let canSelectMore = !(isSolo && clientAmount > 0);

    let clientsAdded = this.getAddedClients();
    let clientsRemoved = this.getRemovedClients();

    return (
      <div className="agenda-popup">
        <PopupOverlay
          isLoading={this.state.isLoading}
          actions={[
            {
              label: this.props.appointment ? "Afspraak annuleren" : "",
              handler: this.props.appointment && (() => this.showPopup('cancelAppointment').then(this.cancelAppointment.bind(this))),
            },
            {
              label: this.props.appointment
                ? "Afspraak bewerken"
                : "Afspraak inplannen",
              handler: this.onSubmit.bind(this),
              submit: true,
            },
          ]}
          title={
            this.props.appointment ? "Afspraak bewerken" : "Afspraak inplannen"
          }
          onDismiss={this.props.onDismiss}
        >
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && 
            <div className="agenda-popupFormContainer">
              <div className="agenda-popupFormRow">
                <InputWithLabel
                  select={true}
                  title="Trainer"
                  value={this.state.appointment.trainerId}
                  name="trainerId"
                  options={trainers}
                  onSelect={this.onSelectChange.bind(this, "trainerId")}
                />
                <InputWithLabel
                  select={true}
                  title="Locatie"
                  name="gymId"
                  value={this.state.appointment.gymId}
                  options={this.state.gyms}
                  onSelect={this.onSelectChange.bind(this, "gymId")}
                />
              </div>
              <div className="agenda-popupFormRow">
                <div className="agenda-multiSelect">
                  <InputLabel content="Klant(en)" />
                  <SelectAsync
                    id="selectClients"
                    closeMenuOnSelect={false}
                    components={{
                      MultiValueRemove: (props) => <MultiValueRemove {...props}>{
                          ActiveStatusses.includes(props.data.status) 
                            ? <X size={14} />
                            : <Plus size={14} />
                        }</MultiValueRemove>
                    }}
                    placeholder="Maak een keuze"
                    cacheOptions
                    isMulti={true}
                    isSearchable={true}
                    getOptionLabel={client => client.fitUser
                      ? `${client.fitUser.firstName} ${client.fitUser.lastName}`
                      : `${client.firstName} ${client.lastName}`}
                    getOptionValue={client => client.id}
                    loadOptions={this.loadMultiSelectOptions.bind(this, canSelectMore)}
                    value={appointment.clients}
                    defaultValue={appointment.clients}
                    noOptionsMessage={() =>
                      canSelectMore
                        ? "Geen opties"
                        : "Er mogen niet meer deelnemers geselecteerd worden voor dit product"
                    }
                    onChange={this.onMultiSelectChange.bind(this)}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        color: 'var(--color-blue-900)',
                        fontSize: '14px',
                        margin: '0',
                      }),                   
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: '0 10px',
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#7db4e133',
                        border: 'none',
                        color: '#000',
                        minHeight: '40px',
                        marginTop: '10px',
                      }),
                      multiValue: (provided, option) => ({
                        ...provided,
                        textDecoration: InactiveStatusses.includes(option.data.status) || (option.data.status === Status.Deleted.name)
                            ? 'line-through'
                            : 'none',
                        backgroundColor: this.mapClientStatusColor(option.data.status),
                      }),
                      multiValueRemove: (provided, option) => ({
                        ...provided,
                        ':hover': {
                          backgroundColor: ActiveStatusses.includes(option.data.status) 
                            ? '#e27e7e' 
                            : '#8de27e',
                          color: ActiveStatusses.includes(option.data.status) 
                            ? '#7d1c1c' 
                            : '#2b7d1c',
                        }
                      })
                    }}
                  />
                </div>
              </div>
              <div className="agenda-popupFormRow">
                <div className="agenda-products">
                  <InputWithLabel
                    select={true}
                    title="Dienst"
                    value={this.state.appointment.productId}
                    name="productId"
                    options={this.state.products}
                    onSelect={this.onSelectChange.bind(this, "productId")}
                    disabled={!!this.props.appointment}
                  />
                </div>
              </div>
              <div className="agenda-popupFormRow">
                <div className="agendaNote">
                  <label>Notitie</label>
                  <textarea 
                    value={this.state.appointment.note} 
                    name="note"
                    placeholder="Optionele opmerking"
                    maxLength='500'
                    onChange={this.onTextAreaChange.bind(this)}
                  />
                </div>
              </div>
              <div className="agenda-popupFormRow">
                <div className="agendaDateRow">
                  <div className="agendaDate-date">
                    <InputWithLabel
                      date={true}
                      title="Datum"
                      value={this.state.appointment.date}
                      name="date"
                      onInputChange={this.onInputChange.bind(this, "date")}
                    />
                  </div>
                  <div className="agendaDate-time">
                    <div className="agendaDate-timeItem">
                      <InputLabel content="van" />
                      <TimeInput
                        value={this.state.appointment.startTime}
                        onInputChange={this.onInputChange.bind(this, "startTime")}
                      />
                    </div>
                    <div className="agendaDate-timeItem">
                      <InputLabel content="tot" />
                      <TimeInput
                        value={this.state.appointment.endTime}
                        onInputChange={this.onInputChange.bind(this, "endTime")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.renderRecurrenceButton()}
              <div className="agenda-popupFormRow">
                <div className="agenda-sendEmailConfirmation">
                  <Checkbox
                    key='sendEmailConfirmation'
                    id="sendEmailConfirmation"
                    label="E-mail sturen aan klant ter bevestiging"
                    val={this.state.sendEmailConfirmation}
                    onToggle={this.onToggle.bind(this, 'sendEmailConfirmation')}
                  />
                </div>
              </div>
              {clientsAdded.length > 0 && (
                <div className="agenda-popupFormRow">
                  <div className="agenda-sendEmailConfirmation">
                    <Checkbox
                      key='rescheduleAppointment'
                      id='rescheduleAppointment'
                      label='Inhaalafspraak voor toegevoegde deelnemers'
                      val={this.state.rescheduleAppointment}
                      onToggle={this.onToggle.bind(this, 'rescheduleAppointment')}
                    />
                  </div>
                </div>
              )}
            </div>
          }

          {this.state.cancelClientsPopup && (
            <RemoveClientsPopup
              cancellations={clientsRemoved}
              {...this.state.cancelClientsPopup}
            />
          )}

          {this.state.appointmentUpdateTypePopup && (
            <AppointmentTypePopup
              {...this.state.appointmentUpdateTypePopup}
            />
          )}
          
          {this.state.recurrencePopup && (
            <RecurrencePopup
              recurrenceRule={this.state.appointment.recurrenceRule}
              {...this.state.recurrencePopup}
            />
          )}

          {this.state.cancelAppointmentPopup && (
            <CancelAppointmentPopup
              recurrence={this.state.appointment.recurrenceRule}
              {...this.state.cancelAppointmentPopup}
            />
          )}
        </PopupOverlay>
    </div>
    );
  }
}

export default AgendaPopup;
