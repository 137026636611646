import React, { Component } from 'react';
import './date-input.css';

class DateInput extends Component {
  constructor(props) {
    super(props)
  
    this.state = {}
  }
  
  onInputChange(e) {
    e.preventDefault();
    let val = e.target.value;
    this.props.onInputChange(val);
  }

  render() {
    return (
      <input
        className='dateInput'
        type='date'
        name={this.props.name}
        value={this.props.value}
        onChange={this.onInputChange.bind(this)}
        onBlur={this.props.onBlur}
      />
    );
  }
}

export default DateInput;
