import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../../app/app-context";
import Topbar from "../../../app/topbar/topbar";
import Sidebar from "../../../app/sidebar/sidebar";
import Table from "../../../app/table/table";
import ApiClient from "../../../api-client/api-client";
import EditClientPopup from "./edit-client-popup";
import EditButton from "../../../app/edit-button/edit-button";
import moment from "moment-timezone";
import "./check-imported-clients.css";

class CheckImportedClients extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      gyms: [],
      trainers: [],
      clients: [],
      selectedClient: {},
      showEditClient: false,
    };
  }

  componentDidMount() {
    this.getClients();
    this.getGyms();
    this.getTrainers();
    this.getProducts();
  }

  getGyms() {
    new ApiClient(this.context).call("GET", "/v1/Gym/").then((response) => {
      let data = response.json;

      this.setState({
        gyms: data.results,
      });
    });
  }

  getTrainers() {
    new ApiClient(this.context).call("GET", "/v1/Trainer").then((response) => {
      let data = response.json;

      this.setState({
        trainers: data.results,
      });
    });
  }

  getProducts() {
    new ApiClient(this.context).call("GET", "/v1/Product").then((response) => {
      let data = response.json;

      this.setState({
        products: data.results,
      });
    });
  }

  getClients() {
    new ApiClient(this.context)
      .call("GET", "/v1/Client", null, false, {}, false, {
        Filters: "ShouldValidateImportedData==true",
      })
      .then((response) => {
        let data = response.json;

        const clients = data.results.map((client) => ({
          ...client,
          fitUser: {
            ...client.fitUser,
            birthdate: moment(client.fitUser.birthdate).format("YYYY-MM-DD"),
          },
        }));

        this.setState((state) => ({
          clients: clients,
        }));
      });
  }

  toggleEditClient(client) {
    this.setState((state) => ({
      showEditClient: !state.showEditClient,
      selectedClient: client,
    }));
  }

  mapDataToTable() {
    return this.state.clients.map((client) => ({
      key: client.id,
      name: client.fitUser.firstName + " " + client.fitUser.lastName,
      clientStatus: client.active === true ? "Actief" : "Inactief",
      gym: "-",
      trainer: "-",
      phoneNumber:
        client.fitUser.phoneNumber === "" || null
          ? "-"
          : client.fitUser.phoneNumber,
      email: client.fitUser.email,
      progression: "-",
      lastFourWeeks: "-",
      edit: <EditButton onClick={this.toggleEditClient.bind(this, client)} />,
    }));
  }

  render() {
    return (
      <div className="checkImportedClientsPage">
        {!this.context.user && <Redirect to="/login" />}
        <Sidebar />

        <div className="checkImportedClientsPage-container">
          <Topbar title="Klanten check" />

          <div className="checkImportedClientsPage-mainContent">
            {this.state.showEditClient && (
              <EditClientPopup
                gyms={this.state.gyms}
                trainers={this.state.trainers}
                client={this.state.selectedClient}
                refreshList={this.getClients.bind(this)}
                onDismiss={this.toggleEditClient.bind(this)}
              />
            )}

            <Table
              headers={[
                {
                  title: "Naam",
                  property: "name",
                },
                {
                  title: "Klant status",
                  property: "clientStatus",
                },
                {
                  title: "Laatste 4 wk",
                  property: "lastFourWeeks",
                },
                {
                  title: "Progressie",
                  property: "progression",
                },
                {
                  title: "Trainer",
                  property: "trainer",
                },
                {
                  title: "Gym",
                  property: "gym",
                },
                {
                  title: "E-mail",
                  property: "email",
                },
                {
                  title: "Telefoon",
                  property: "phoneNumber",
                },
                { property: "edit" },
              ]}
              data={this.mapDataToTable()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CheckImportedClients;
