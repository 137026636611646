import React, { Component } from 'react';

class Euro extends Component {
  render() {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.3066 18.8887C17.2695 18.6289 17.0098 18.4805 16.7871 18.5547C16.416 18.6289 15.8223 18.7402 15.1543 18.7402C12.1484 18.7402 9.88477 16.9961 9.17969 14.25H14.9316C15.1543 14.25 15.3398 14.1016 15.377 13.916L15.4141 13.6191C15.4883 13.3223 15.2656 13.0625 15.0059 13.0625H8.91992C8.8457 12.3203 8.8457 11.4668 8.95703 10.6875H15.5625C15.7852 10.6875 15.9707 10.5391 16.0078 10.3535L16.082 10.0566C16.1191 9.75977 15.8965 9.5 15.6367 9.5H9.2168C9.95898 6.90234 12.1855 5.04688 15.1172 5.04688C15.6738 5.04688 16.1934 5.12109 16.5273 5.19531C16.75 5.23242 16.9727 5.08398 17.0469 4.86133L17.1953 4.30469C17.2324 4.04492 17.084 3.78516 16.8242 3.74805C16.4531 3.67383 15.8594 3.5625 15.1543 3.5625C11.3691 3.5625 8.40039 6.12305 7.50977 9.5H6.50781C6.24805 9.5 6.0625 9.72266 6.0625 9.94531V10.2422C6.0625 10.502 6.24805 10.6875 6.50781 10.6875H7.28711C7.21289 11.3555 7.25 12.543 7.28711 13.0625H6.50781C6.24805 13.0625 6.0625 13.2852 6.0625 13.5078V13.8047C6.0625 14.0645 6.24805 14.25 6.50781 14.25H7.47266C8.25195 17.8496 11.2207 20.1875 15.1543 20.1875C15.9707 20.1875 16.6758 20.0762 17.1211 19.9648C17.3438 19.9277 17.4922 19.668 17.4551 19.4453L17.3066 18.8887Z'
          fill='#2B6A93'
        />
      </svg>
    );
  }
}

export default Euro;
